import { GddModelDefine } from '../gdd'

export const gddGrapeBerryMoth: GddModelDefine = {
  lowerThreshold: 47,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddGrapeBerryMoth',
  name: 'Grape Berry Moth',
  fullName: 'Grape Berry Moth (Paralobesia viteana) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Wild Grape Bloom',
      range: {
        min: 0,
        max: 810,
      },
      infos: [
        {
          name: 'Flowers',
          value: 'Approximately 50% of the flowers are open on approximately 50% of the wild grape clusters.',
        },
      ],
    },
    {
      name: 'Second Generation Egg-Laying',
      range: {
        min: 811,
        max: 1620,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are active and have begun laying eggs.',
        },
      ],
    },
    {
      name: 'Third Generation Egg-Laying',
      range: {
        min: 1621,
        max: 2430,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are active and have begun laying eggs.',
        },
      ],
    },

    {
      name: 'Fourth Generation Egg-Laying',
      range: {
        min: 2431,
        max: 2800,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are active and have begun laying eggs.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Grape Berry Moth Degree Day Model is used to predict development of life-cycle stages of Grape Berry Moth (Paralobesia viteana), in order to improve pest management decisions',
    'For best results, a biofix date should be set at approximately 50% of the flowers are open on approximately 50% of the wild grape clusters. The growing degree day model can then be used to predict the following generations.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-grape-berry-moth-growing-degree-day-model/',
  citations: [
    'Isaacs, Rufus. Mason, Keith. Michigan State University Enviroweather Grape Berry Moth Model. 2018.',
  ],
}
