import { GddModelDefine } from '../gdd'

export const gddRoseRoyalty: GddModelDefine = {
  lowerThreshold: 41.4,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddRoseRoyalty',
  name: 'Rose (Royalty) Hardening',
  fullName: 'Rose (Royalty) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Cutting',
      range: {
        min: 0,
        max: 362,
      },
      infos: [
        {
          name: 'Bud-Break',
          value: 'Bud Break has not yet occurred.',
        },
      ],
    },
    {
      name: 'Bud-Break',
      range: {
        min: 363,
        max: 501,
      },
      infos: [
        {
          name: 'Bud-Break',
          value: 'Bud-Break has occurred.',
        },
        {
          name: 'Leaves',
          value: 'Leaves have not developed.',
        },
      ],
    },
    {
      name: 'Leaf 1',
      range: {
        min: 502,
        max: 814,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'First leaf has unfurled.',
        },
        {
          name: 'Flower Bud',
          value: 'Flower buds not yet visible.',
        },
      ],
    },
    {
      name: 'Visible FLower Bud',
      range: {
        min: 815,
        max: 933,
      },
      infos: [
        {
          name: 'Flower Bud',
          value: 'First flower bud is visible.',
        },
        {
          name: 'Leaves',
          value: 'Leaves are developing.',
        },
      ],
    },
    {
      name: 'Last Leaf',
      range: {
        min: 934,
        max: 1297,
      },
      infos: [
        {
          name: 'Flower Bud',
          value: 'Flower buds are visible.',
        },
        {
          name: 'Leaves',
          value: 'Last leaf has unfurled',
        },
      ],
    },
    {
      name: 'Harvest',
      range: {
        min: 1298,
        max: 1500,
      },
      infos: [
        {
          name: 'Flowering Shoot',
          value: 'Flowering shoot is ready for harvest.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Rose Flowering Degree Day Model is used to predict harvest of Rose flowering shoots, based on the weather in a given season.',
    'For best results, a biofix date should be set to date of cutting. The growing degree day model can then be used to predict harvest timing of flowering shoots.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-rose-growing-degree-day-model/',
  citations: [
    `Pasian, C.C., and J.H. Lieth. 1994. Prediction of flowering rose shoot development based on air temperature and thermal units. Scientia Horticulturae 59:131-145.`,
    `Pasian, C.C., and J.H. Lieth. 1996. Prediction of rose shoot development: model validation for the cultivar 'Cara Mia' and extension to the cultivars 'Royalty' and 'Sonia'. Scientia Horticulturae 66:117-124.`,
  ],
}
