import { GddModelDefine } from '../gdd'

export const gddGreenPeachAphid: GddModelDefine = {
  lowerThreshold: 39.2,
  upperThreshold: 86,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddGreenPeachAphid',
  name: 'Green Peach Aphid',
  fullName: 'Green Peach Aphid (Myzus persicae) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'First Generation Nymphs',
    range: {
      min: 0,
      max: 234,
    },
    infos: [{
      name: 'Nymphs',
      value: `First generation nymphs observed.`,
    }],
  }, {
    name: 'Second Generation Nymphs',
    range: {
      min: 235,
      max: 468,
    },
    infos: [{
      name: 'Nymphs',
      value: `Second generation nymphs predicted.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Green Peach Aphid Growing Degree Day Model is used to predict life-stage developments of the Green Peach Aphid (Myzus persicae) in order to improve pest management decisions.`,
  ],
  citations: [
    `Whalon, M. E., and Z. Smilowitz. 1979. Temperature-dependent model for predicting field populations of green peach aphid, Myzus persicae (Homoptera: Aphididae). Can. Ent. 111: 1025-1032.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-green-peach-aphid-growing-degree-day-model',
}
