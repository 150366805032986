import { GddModelDefine } from '../gdd'

export const gddPotatoCapsid: GddModelDefine = {
  lowerThreshold: 43.5,
  upperThreshold: 93.3,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddPotatoCapsid',
  name: 'Potato Capsid',
  fullName: 'Potato Capsid (Calocoris norvegicus) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: '1st Nymphal Instar',
      range: {
        min: 0,
        max: 256,
      },
      infos: [
        {
          name: 'Nymphs',
          value: '1st Nymphal Instar stage',
        },
      ],
    },
    {
      name: '4th Nymphal Instar',
      range: {
        min: 257,
        max: 362,
      },
      infos: [
        {
          name: 'Nymphs',
          value: '4th Nymphal Instar stage',
        },
      ],
    },
    {
      name: '5th Nymphal Instar',
      range: {
        min: 363,
        max: 478,
      },
      infos: [
        {
          name: 'Nymphs',
          value: '5th Nymphal Instar stage',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 479,
        max: 600,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Potato Capsid Degree Day Model is used to predict development of life-cycle stages of the Potato Capsid (Calocoris norvegicus), in order to improve pest management decisions',
    'For best results, a biofix date should be set when first instar nymphs are observed. The growing degree day model can then be used to predict development of later (4th and 5th) nymphal stages and emergence of next generation of adults.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-potato-capsid-growing-degree-day-model/',
  citations: [
    'Purcell, M., and S. C. Welter. 1990. A degree-day model for development of Calocoris norvegicus (Hemiptera: Miridae) and timing of management strategies.',
  ],
}
