import { GddModelDefine } from '../gdd'

export const gddBerthaArmyworm: GddModelDefine = {
  lowerThreshold: 7,
  upperThreshold: 34,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddBerthaArmyworm',
  name: 'Bertha Armyworm',
  fullName: 'Bertha Armyworm (Mamestra configurata) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Adults present.',
      range: {
        min: 0,
        max: 175,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not been laid yet.',
        },
        {
          name: 'Adults',
          value: 'Adults have been observed or caught in the field.',
        },
      ],
    },
    {
      name: 'First Egg Laying',
      range: {
        min: 176,
        max: 325,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have begun to be laid.',
        },
      ],
    },
    {
      name: 'First Egg Hatch',
      range: {
        min: 326,
        max: 560,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have begun to hatch.',
        },
        {
          name: 'Larvae',
          value: 'Larvae have emerged in early instars.',
        },
      ],
    },
    {
      name: '4th Instar Larvae',
      range: {
        min: 561,
        max: 630,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are in 4th instar stage.',
        },
      ],
    },
    {
      name: '5th Instar Larvae',
      range: {
        min: 631,
        max: 725,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are in 5th instar stage.',
        },
      ],
    },
    {
      name: '6th Instar Larvae',
      range: {
        min: 726,
        max: 965,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are in 6th instar stage.',
        },
      ],
    },
    {
      name: 'Pupation',
      range: {
        min: 966,
        max: 1600,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have begun pupation.',
        },
        {
          name: 'Adults',
          value: 'Adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'Adult Emergence',
      range: {
        min: 1601,
        max: 1800,
      },
      infos: [

        {
          name: 'Adults',
          value: 'Next generation adults have emerged.',
        },
      ],
    },
    {
      name: 'Egg Laying',
      range: {
        min: 1800,
        max: 2000,
      },
      infos: [

        {
          name: 'Adults',
          value: 'Next generaion adults have emerged and have begun to lay eggs.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Bertha Armyworm Degree Day Model is used to predict development of life-cycle stages of the Bertha Armyworm (Mamestra configurata), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adults are first observed. The growing degree day model can then be used to predict emergence of next generation of adults.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-berth-armyworm-growing-degree-day-model/',
  citations: [
    `Bailey, C. G. 1976.Temperature effects on non-diapause development in Mamestra configurata(Lepidoptera: Noctuidae). Can. Ent. 108: 1339-1344.`,
  ],
}
