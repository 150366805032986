import { GddModelDefine } from '../gdd'

export const gddBoxwoodLeafminer: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddBoxwoodLeafminer',
  name: 'Boxwood Leafminer',
  fullName: 'Boxwood Leafminer (Monarthropalpus buxi) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Emergence',
      range: {
        min: 0,
        max: 350,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Overwintered pupae are in leaf mines and adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence from Overwintered Pupae',
      range: {
        min: 351,
        max: 600,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from overwintered pupae have begun to emerge.',
        },
      ],
    },
    {
      name: 'End Adult Emergence from Overwintered Pupae',
      range: {
        min: 601,
        max: 1200,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae have all emerged.',
        },
      ],
    },
    {
      name: 'First Larvae Emergence',
      range: {
        min: 1201,
        max: 2400,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have begun to emerge and may be targeted for treatment.',
        },
      ],
    },
    {
      name: 'End Next Generation Larvae Emergence',
      range: {
        min: 2401,
        max: 2500,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Next generation larvae have all emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Boxwood Leafminer Degree Day Model is used to predict development of life-cycle stages of Boxwood Leafminer (Monarthropalpus buxi), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults from overwintered pupae and first generation larvae. Larvae can be targeted for treatment with insecticides.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-boxwood-leafminer-growing-degree-day-model/',
  citations: [
    'Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.',
    'Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.',
  ],
}
