import { GddModelDefine } from '../gdd'

export const gddOliveScale: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 50,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddOliveScale',
  name: 'Olive Scale',
  fullName: 'Olive Scale (Parlatoria oleae) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Overwintered female emergence',
      range: {
        min: 0,
        max: 40,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered females have begun emergence but are not yet in oviposition.',
        },
      ],
    },
    {
      name: 'Oviposition of overwintered females',
      range: {
        min: 41,
        max: 183,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered females are in oviposition.',
        },
      ],
    },
    {
      name: '50% Oviposition',
      range: {
        min: 183,
        max: 440,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered females are in oviposition.',
        },
      ],
    },
    {
      name: 'Onset of spring generation egg hatch',
      range: {
        min: 441,
        max: 715,
      },
      infos: [
        {
          name: 'Crawlers',
          value: 'Egg hatch has begun and crawlers have begun to emerge.',
        },
      ],
    },
    {
      name: '50% egg hatch of spring generation',
      range: {
        min: 716,
        max: 816,
      },
      infos: [
        {
          name: 'Crawlers',
          value: '50% of Egg hatch has occurred and crawlers should be targeted for treatment.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Olive Scale Degree Day Model is used to predict development of life-cycle stages of the Olive Scale (Parlatoria oleae), in order to improve pest management decisions',
    'For best results, a biofix date should be set as January 1st for the Northern Hemisphere to predict overwintering emergence. The growing degree day model can then be used to predict emergence of next generation of crawlers. For best results, the biofix should be adjusted when each stage has been met, for examples when overwintered females are first observed.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-olive-scale-growing-degree-day-model/',
  citations: [
    `Pinhassi, N., D. Nestel, and D. Rosen. 1996. Oviposition and emergence of olive scale (Homoptera: Diaspididae) crawlers: regional degree-day forecasting model. Environ. Entomol. 25:1-6.`,
  ],
}
