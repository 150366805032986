import { GddModelDefine } from '../gdd'

export const gddLygusBugGreenBeans: GddModelDefine = {
  lowerThreshold: 46.4,
  upperThreshold: 104,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddLygusBugGreenBeans',
  name: 'Lygus Bug (Green Beans)',
  fullName: 'Lygus Bug (Lygus hesperus) on Green Beans Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 259,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not yet begun to hatch.',
        },
      ],
    },
    {
      name: 'Nymphs',
      range: {
        min: 260,
        max: 767,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs have emerged from eggs.',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 768,
        max: 1000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Lygus Bug Degree Day Model is used to predict development of life-cycle stages of the Lygus Bug (Lygus hesperus), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adult bugs are first observed. The growing degree day model can then be used to predict emergence of next generation of nymphs, which can be targeted for insecticide treatment.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-lygus-bug-growing-degree-day-model/',
  citations: [
    'Champlain, R. A., and G. D. Butler, Jr. 1967. Temperature effects on development of the egg and nymphal stages of Lygus hesperus (Hemiptera: Miridae). Ann. Ent. Soc. Amer. 60: 519-521.',
  ],
}
