import { GddModelDefine } from '../gdd'

export const gddVineMealybug: GddModelDefine = {
  lowerThreshold: 16.6,
  upperThreshold: 35.6,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddVineMealybug',
  name: 'Vine Mealybug',
  fullName: 'Vine Mealybug (Planococcus ficus) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [{
    name: 'Beginning of Season',
    range: {
      min: 0,
      max: 235,
    },
    infos: [{
      name: 'Mealybugs',
      value: `Mealybugs are not yet present in large numbers.`,
    }],
  }, {
    name: 'First Generation Mealybugs',
    range: {
      min: 236,
      max: 470,
    },
    infos: [{
      name: 'Mealybugs',
      value: `Mealybugs will have likely started feeding and should be monitored for.`,
    }],
  }, {
    name: 'Second Generation Mealybugs',
    range: {
      min: 471,
      max: 705,
    },
    infos: [{
      name: 'Mealybugs',
      value: `Second generation Mealybugs will have likely emerged and should be monitored for.`,
    }],
  }, {
    name: 'Third Generation Mealybugs',
    range: {
      min: 706,
      max: 940,
    },
    infos: [{
      name: 'Mealybugs',
      value: `Third generation Mealybugs will have likely emerged and should be monitored for.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Vine Mealybug Growing Degree Day Model is used to predict life-stage developments of the Vine Mealybug (Planococcus ficus) in order to improve pest management decisions.`,
    `The biofix should be set to the beginning of the growing season or budbreak in order to predict when mealybugs should first be scouted. For best results, the biofix should be re-adjusted after Mealybugs are consistently observed in the field in order to better predict the timing of subsequent generations.`,
  ],
  citations: [
    `Walton, Vaughn & Pringle, K.L.. (2005). Developmental biology of vine mealybug, Planococcus ficus (Signoret) (Homoptera: Pseudococcidae), and its parasitoid Coccidoxenoides perminutus (Timberlake) (Hymenoptera: Encyrtidae). African Entomology. 13. 143-147.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-vine-mealybug-growing-degree-day-model',
}
