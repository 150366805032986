import {
  tomCastModelCommon,
  TomCastModelDefine,
} from './tom-cast'

export const tomCastTomatoEarlyBlight: TomCastModelDefine = {
  ...tomCastModelCommon,
  type: 'tomCastTomatoEarlyBlight',
  name: 'TomCast Tomato Early Blight',
  fullName: 'TomCast Tomato Early Blight Model',
  stages: [{
    name: 'Treatment Not Recommended',
    range: {
      min: 0,
      max: 14,
    },
    infos: [{
      name: 'Tomato Early Blight',
      value: `Risk of infection is low and treatment is not yet recommended.`,
    }],
  }, {
    name: 'Treatment Recommended',
    range: {
      min: 15,
    },
    infos: [{
      name: 'Tomato Early Blight',
      value: `Risk of infection is high and preventative treatment is recommended.`,
    }],
  }],
  notes: [
    'Plant disease risk models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'Tomato Early Blight is caused by the fungus Alternaria solani, and it can be very difficult to control once the disease has been established. Preventative spray treatments are recommend to prevent infection.',
    'The Tomato Early Blight risk model, also known as Tomcast, is used to determine interval length between spray treatments. The model calculates daily DSV (Disease Severity Value) based on temperature and leaf wetness duration. When 15-20 DSVs have accumulated, treatment is recommended.',
    'Model should be first used immediately following planting. First spray should be after 15-20 DSVs have accumulated, ( or 25 DSV if planted before May 20), or June 15 if DSV threshold has not been met (for northern hemisphere).',
    'The model should be reset after each spray, in order to time the subsequent treatment.',
  ],
  citations: [
    'Pitblado, R. E. 1992. The development and implementation of TOM-CAST: A weather-timed fungicide spray program for field tomatoes. Ministry of Agriculture and Food, Ontario, Canada.',
    'Madden L., Pennypacker, S. P., and McNab, A. A. 1978. FAST, a forecast system for Alternaria solani on tomato. Phytopathology 68:1354-1358.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-tomato-early-blight-risk-model/',
}
