import { GddModelDefine } from '../gdd'

export const gddNorthernCornRootworm: GddModelDefine = {
  lowerThreshold: 11.1,
  upperThreshold: 50,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddNorthernCornRootworm',
  name: 'Northern Corn Rootworm',
  fullName: 'Northern Corn Rootworm (Diabrotica barberi) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 296,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not yet begun to hatch.',
        },
      ],
    },
    {
      name: 'Median Egg Hatch',
      range: {
        min: 297,
        max: 600,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have begun to emerge from eggs.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Northern Corn Rootworm Degree Day Model is used to predict development of life-cycle stages of the Norther Corn Rootworm (Diabrotica barberi), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adults are first observed. The growing degree day model can then be used to predict emergence of next generation of adults.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-northern-corn-rootworm-growing-degree-day-model/',
  citations: [
    `Apple, J. W., E. T. Walgenbach and W. J. Knee. 1971. Thermal requirements for Northern Corn Rootworm egg hatch. J. Econ. Entomol. 64: 853-856.`,
  ],
}
