import { GddModelDefine } from '../gdd'

export const gddOrientalFruitFly: GddModelDefine = {
  lowerThreshold: 12.8,
  upperThreshold: 36.4,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddOrientalFruitFly',
  name: 'Oriental Fruit Fly',
  fullName: 'Oriental Fruit Fly (Dacus dorsalis) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 19,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not yet begun to hatch.',
        },
      ],
    },
    {
      name: 'Larvae',
      range: {
        min: 20,
        max: 40,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have emerged from eggs.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Oriental Fruit Fly Degree Day Model is used to predict development of life-cycle stages of the Oriental Fruit Fly, in order to improve pest management decisions',
    'For best results, a biofix date should be set when adult flies are first observed. The growing degree day model can then be used to predict emergence of next generation of adults.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-oriental-fruit-fly-growing-degree-day-model/',
  citations: [
    `Messenger, P. S. and N. E. Flitters. 1958. Effect of constant temperature environments on the egg stage of three species of Hawaiian fruit flies. Ann. Ent. Soc. Amer. 51: 109-119.`,
  ],
}
