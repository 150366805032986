import { GddModelDefine } from '../gdd'

export const gddLightBrownAppleMoth: GddModelDefine = {
  lowerThreshold: 45,
  upperThreshold: 88,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddLightBrownAppleMoth',
  name: 'Light Brown Apple Moth',
  fullName: 'Light Brown Apple Moth (Epiphyas postvittana) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Overwintering Moth Emergence',
    range: {
      min: 0,
      max: 495,
    },
    infos: [{
      name: 'Adults',
      value: `Over-wintered adult moths begin emergence.`,
    }],
  }, {
    name: 'Adult Moth Flight',
    range: {
      min: 496,
      max: 656,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of over-wintered adult moths in flight.`,
    }],
  }, {
    name: 'Adult Egg Laying',
    range: {
      min: 657,
      max: 884,
    },
    infos: [{
      name: 'Adults',
      value: `Over-wintered adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Peak of first generation egg laying.`,
    }],
  }, {
    name: 'First Generation Egg Hatch',
    range: {
      min: 885,
      max: 1250,
    },
    infos: [{
      name: 'Adults',
      value: `Over-wintered adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Peak of first generation egg hatch.`,
    }],
  }, {
    name: 'First Generation Larvae',
    range: {
      min: 1251,
      max: 1894,
    },
    infos: [{
      name: 'Adults',
      value: `Over-wintered adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `First generation egg hatch.`,
    }, {
      name: 'Larvae',
      value: `Peak of first generation larvae.`,
    }],
  }, {
    name: 'First Generation Adult Moth Flight',
    range: {
      min: 1895,
      max: 2430,
    },
    infos: [{
      name: 'Adults',
      value: `First generation adult moths in flight.`,
    }],
  }, {
    name: 'Second Generation Egg Hatch',
    range: {
      min: 2431,
      max: 2875,
    },
    infos: [{
      name: 'Adults',
      value: `First generation adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Peak of second generation egg hatch.`,
    }],
  }, {
    name: 'Second Generation Larvae',
    range: {
      min: 2876,
      max: 3288,
    },
    infos: [{
      name: 'Adults',
      value: `First generation adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Second generation egg hatch.`,
    }, {
      name: 'Larvae',
      value: `Peak of third generation larvae.`,
    }],
  }, {
    name: 'Second Generation Adult Moth Flight',
    range: {
      min: 3289,
      max: 4134,
    },
    infos: [{
      name: 'Adults',
      value: `Second generation adult moths in flight.`,
    }],
  }, {
    name: 'Third Generation Egg Hatch',
    range: {
      min: 4135,
      max: 4578,
    },
    infos: [{
      name: 'Adults',
      value: `Second generation adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Peak of third generation egg hatch.`,
    }],
  }, {
    name: 'Third Generation Larvae',
    range: {
      min: 4579,
      max: 4683,
    },
    infos: [{
      name: 'Adults',
      value: `Second generation adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Third generation egg hatch.`,
    }, {
      name: 'Larvae',
      value: `Peak of third generation larvae.`,
    }],
  }, {
    name: 'Third Generation Adult Moth Flight',
    range: {
      min: 4684,
    },
    infos: [{
      name: 'Adults',
      value: `Third generation adult moths in flight.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Light Brown Apple Moth Growing Degree Day Model is used to predict life-stage developments of the Light Brown Apple Moth (Epiphyas postvittana) in order to improve pest management decisions.`,
    `For best results, pheromone traps should be placed early in the season.The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Light Brown Apple Moth, Epiphyas postvittana (Walker), Phenological Model Analysis by Len Coop, Integrated Plant Protection Center, Oregon State University, Jun 18, 2012`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-light-brown-apple-moth-growing-degree-day-model/',
}
