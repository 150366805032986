import { GddModelDefine } from '../gdd'

export const gddWheatWinter: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddWheatWinter',
  name: 'Winter Wheat',
  fullName: 'Karow Winter Wheat Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Seed Planted',
      range: {
        min: 0,
        max: 144,
      },
      infos: [
        {
          name: 'Coleoptyle',
          value: 'Coleoptyle has not broken through soil.',
        },
      ],
    },
    {
      name: 'Germination: Coleoptyle has broken through soil.',
      range: {
        min: 145,
        max: 324,
      },
      infos: [
        {
          name: 'Coleoptyle',
          value: 'Coleoptyle has broken through the soil.',
        },
      ],
    },
    {
      name: 'Planting Emergence Complete.',
      range: {
        min: 325,
        max: 504,
      },
      infos: [
        {
          name: 'Coleoptyle',
          value: 'Coleoptyle has broken through the soil.',
        },
      ],
    },
    {
      name: 'First leaf unfolds.',
      range: {
        min: 505,
        max: 684,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'First leaf has unfolded.',
        },
      ],
    },
    {
      name: 'Second leaf unfolds.',
      range: {
        min: 685,
        max: 864,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Second leaf has unfolded.',
        },
      ],
    },
    {
      name: '3 Leaves, Coleoptyle Tiller',
      range: {
        min: 865,
        max: 1044,
      },
      infos: [
        {
          name: 'Tiller',
          value: 'Coleoptyly tiller (TO) has become visible.',
        },
        {
          name: 'Leaves',
          value: 'Third leaf has unfolded',
        },
      ],
    },
    {
      name: '4 Leaves, 1st Node Tiller',
      range: {
        min: 1045,
        max: 1224,
      },
      infos: [
        {
          name: 'Tiller',
          value: 'First Node tiller (T1) has become visible.',
        },
        {
          name: 'Leaves',
          value: 'Fourth leaf has unfolded',
        },
      ],
    },
    {
      name: '5 Leaves, 2nd Node Tiller',
      range: {
        min: 1225,
        max: 1404,
      },
      infos: [
        {
          name: 'Tiller',
          value: 'Second Node tiller (T2) has become visible.',
        },
        {
          name: 'Leaves',
          value: 'Fifth leaf has unfolded',
        },
      ],
    },
    {
      name: '6 Leaves, 3rd Node Tiller',
      range: {
        min: 1405,
        max: 1584,
      },
      infos: [
        {
          name: 'Tiller',
          value: 'Third Node tiller (T3) has become visible.',
        },
        {
          name: 'Leaves',
          value: 'Sixth leaf has unfolded',
        },
      ],
    },
    {
      name: '7 Leaves, 4th Node Tiller',
      range: {
        min: 1585,
        max: 1700,
      },
      infos: [
        {
          name: 'Tiller',
          value: 'Fourth Node tiller (T4) has become visible.',
        },
        {
          name: 'Leaves',
          value: 'Seventh leaf has unfolded',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Winter Wheat Degree Day Model is used to predict harvest time and other growth stages of Winter Wheat based on the weather in a given season.',
    'For best results, a biofix date should be set at planting. The growing degree day model can then be used to predict leaf and node development. For best results the biofix should be reset when later stages have been observed, in order to more accurately predict the next stage.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-wheat-winter-growing-degree-day-model/',
  citations: [
    `Karow et al. Winter Wheat growing degree day model. 1993`,
  ],
}
