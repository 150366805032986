import { GddModelDefine } from '../gdd'

export const gddCeleryLooper: GddModelDefine = {
  lowerThreshold: 47,
  upperThreshold: 90.7,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddCeleryLooper',
  name: 'Celery Looper',
  fullName: 'Celery Looper (Syngrapha falcifera) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Eggs',
    range: {
      min: 0,
      max: 679,
    },
    infos: [{
      name: 'Adults',
      value: `Adult moths may be present.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }, {
    name: 'First Generation Adults',
    range: {
      min: 680,
      max: 1358,
    },
    infos: [{
      name: 'Adults',
      value: `Adult moths are present.`,
    }, {
      name: 'Eggs',
      value: `Next generation egg laying has begun.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Celery Looper Growing Degree Day Model is used to predict life-stage developments of the Celery Looper (Syngrapha falcifera) in order to improve pest management decisions.`,
    `For best results, traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Peterson, R. K. D., L. G. Higley, and W. C. Bailey. 1988. Phenology of the adult celery looper, Syngrapha falcifera (Lepidoptera: Noctuidae), in Iowa: evidence for migration. Envir. Entomol. 17: 679-684.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-celery-looper-growing-degree-day-model',
}
