import { gddCarrotWeevil } from './gdd-carrot-weevil'

export const gddCarrotWeevilLTexanus = gddCarrotWeevil.variety('L. texanus', {
  lowerThreshold: 13.3,
  upperThreshold: 30.0,
  cutoffMethod: 'horizontal',

  type: 'gddCarrotWeevilLTexanus',
  measurementUnits: 'metric',
  stages: [{
    name: 'Adult / Eggs',
    range: {
      min: 0,
      max: 61,
    },
    infos: [{
      name: 'Adults',
      value: `Adults are active.`,
    }, {
      name: 'Eggs',
      value: `Adult have begun to lay eggs.`,
    }],
  }, {
    name: 'Larvae',
    range: {
      min: 62,
      max: 270,
    },
    infos: [{
      name: 'Larvae',
      value: `Eggs have begun to hatch and larvae have emerged.`,
    }],
  }, {
    name: 'Prepupae',
    range: {
      min: 271,
      max: 340,
    },
    infos: [{
      name: 'Prepupae',
      value: `Larvae have developed into the prepupae stage.`,
    }],
  }, {
    name: 'Pupae',
    range: {
      min: 341,
      max: 417,
    },
    infos: [{
      name: 'Pupae',
      value: `Prepupae have developed into the pupae stage.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Carrot Weevil (Listronotus texanus) Growing Degree Day Model is used to predict life-stage developments of the Carrot Weevil (Listronotus texanus) in order to improve pest management decisions.`,
    `For best results, sweep nets should be used early in the season to monitor for insect presence. The biofix should be adjusted to represent the first date that adult weevils have been caught consistently. The growing degree day model can then be used to predict emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Woodson, W. D., and J. V. Eldelson. 1988. Developmental rate as a function of temperature in a carrot weevil, Listronotus texanus (Coleoptera: Curculionidae). Ann. Entomol. Soc. Am. 81: 252-254.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-carrot-weevil-growing-degree-day-model',
})
