import { GddModelDefine } from '../gdd'

export const gddLygusBugAlfalfa: GddModelDefine = {
  lowerThreshold: 52,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddLygusBugAlfalfa',
  name: 'Lygus Bug (Alfalfa)',
  fullName: 'Lygus Bug (Lygus hesperus) on Alfalfa Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 275,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not yet begun to hatch.',
        },
      ],
    },
    {
      name: 'Nymphs',
      range: {
        min: 276,
        max: 400,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs have emerged from eggs.Optimum time for treatment',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Lygus Bug Degree Day Model is used to predict development of life-cycle stages of the Lygus Bug (Lygus hesperus), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adult bugs are first observed. The growing degree day model can then be used to predict emergence of next generation of nymphs, which can be targeted for insecticide treatment.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-lygus-bug-growing-degree-day-model/',
  citations: [
    'Ben C. Simko, OSU, Agricultural Extension Agent, and Dani Jo Kriegh, Student Assistant, Lygus Summit, 28 November 2000. Visalia, CA, University of CA Division of Agriculture and Natural Resources.',
  ],
}
