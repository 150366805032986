import { gddAlfalfaEarlySummer } from './a/gdd-alfalfa-early-summer'
import { gddAlfalfaLateSummer } from './a/gdd-alfalfa-late-summer'
import { gddAlfalfaSpring } from './a/gdd-alfalfa-spring'
import { gddAlfalfaWeevil } from './a/gdd-alfalfa-weevil'
import { gddAlmondNavelOrangewormMummy } from './a/gdd-almond-navel-orangeworm/gdd-almond-navel-orangeworm-mummy'
import { gddAlmondNavelOrangewormNewCrop } from './a/gdd-almond-navel-orangeworm/gdd-almond-navel-orangeworm-new-crop'
import { gddAmericanPlumBorer } from './a/gdd-american-plum-borer'
import { gddAmericanSerpentineLeafminerCelery } from './a/gdd-american-serpentine-leafminer-celery'
import { gddAmericanSerpentineLeafminerChrysanthemums } from './a/gdd-american-serpentine-leafminer-chrysanthemums'
import { gddAnagrusEpos } from './a/gdd-anagrus-epos'
import { gddAphytisMelinus } from './a/gdd-aphytis-melinus'
import { gddAppleMaggotEmergence } from './a/gdd-apple-maggot-emergence'
import { gddAppleScab } from './a/gdd-apple-scab'
import { gddArborvitaeLeafminer } from './a/gdd-arborvitae-leafminer'
import { gddArmyworm } from './a/gdd-armyworm'
import { gddArtichokePlumeMoth } from './a/gdd-artichoke-plume-moth'
import { gddAsianCitrusPsyllid } from './a/gdd-asian-citrus-psyllid'
import { gddAsianLonghornedBeetleEmergence } from './a/gdd-asian-longhorned-beetle-emergence'
import { gddAsiaticRiceBorer } from './a/gdd-asiatic-rice-borer'
import { gddAsparagusBeetle } from './a/gdd-asparagus-beetle'
import { gddAzaleaLeafminer } from './a/gdd-azalea-leafminer'
import { gddAzaleaWhitefly } from './a/gdd-azalea-whitefly'
import { gddBagworm } from './b/gdd-bagworm'
import { gddBalsamGallMidge } from './b/gdd-balsam-gall-midge'
import { gddBalsamTwigAphid } from './b/gdd-balsam-twig-aphid'
import { gddBandedAshClearwingBorer } from './b/gdd-banded-ash-clearwing-borer'
import { gddBarley } from './b/gdd-barley'
import { gddBathyplectesCurculionis } from './b/gdd-bathyplectes-curculionis'
import { gddBean5630 } from './b/gdd-bean-5630'
import { gddBeanBlackTurtleSoup } from './b/gdd-bean-black-turtle-soup'
import { gddBeanCommodore } from './b/gdd-bean-commodore'
import { gddBeanDarkRedKidney } from './b/gdd-bean-dark-red-kidney'
import { gddBeanLinden } from './b/gdd-bean-linden'
import { gddBeanPI165426 } from './b/gdd-bean-pi165426'
import { gddBeanProvider } from './b/gdd-bean-provider'
import { gddBeanSahara } from './b/gdd-bean-sahara'
import { gddBeanSal } from './b/gdd-bean-sal'
import { gddBeanSeedsaja } from './b/gdd-bean-seedsaja'
import { gddBeanSutter } from './b/gdd-bean-sutter'
import { gddBeechScale } from './b/gdd-beech-scale'
import { gddBeetArmyworm } from './b/gdd-beet-armyworm'
import { gddBeetLeafhopper } from './b/gdd-beet-leafhopper'
import { gddBerthaArmyworm } from './b/gdd-bertha-armyworm'
import { gddBirchLeafminer } from './b/gdd-birch-leafminer'
import { gddBirchSkeletonizer } from './b/gdd-birch-skeletonizer'
import { gddBlackCutworm } from './b/gdd-black-cutworm'
import { gddBlackNightshade } from './b/gdd-black-nightshade'
import { gddBlackPineleafScale } from './b/gdd-black-pineleaf-scale'
import { gddBlackVineWeevil } from './b/gdd-black-vine-weevil'
import { gddBlackberryLeafhopper } from './b/gdd-blackberry-leafhopper'
import { gddBlueAlfalfaAphidAlate } from './b/gdd-blue-alfalfa-aphid/gdd-blue-alfalfa-aphid-alate'
import { gddBlueAlfalfaAphidApterous } from './b/gdd-blue-alfalfa-aphid/gdd-blue-alfalfa-aphid-apterous'
import { gddBluegrass } from './b/gdd-bluegrass'
import { gddBoxwoodLeafminer } from './b/gdd-boxwood-leafminer'
import { gddBoxwoodMite } from './b/gdd-boxwood-mite'
import { gddBoxwoodPsyllid } from './b/gdd-boxwood-psyllid'
import { gddBroccoliArcadia } from './b/gdd-broccoli-arcadia'
import { gddBroccoliEmeraldPride } from './b/gdd-broccoli-emerald-pride'
import { gddBroccoliGreenMagic } from './b/gdd-broccoli-green-magic'
import { gddBroccoliImperial } from './b/gdd-broccoli-imperial'
import { gddBronzeBirchBorer } from './b/gdd-bronze-birch-borer'
import { gddBrownMarmoratedStinkBug } from './b/gdd-brown-marmorated-stink-bug'
import { gddCabbageAphid } from './c/gdd-cabbage-aphid'
import { gddCabbageLooper } from './c/gdd-cabbage-looper'
import { gddCabbageMaggotEmergence } from './c/gdd-cabbage-maggot-emergence'
import { gddCanarySeed } from './c/gdd-canary-seed'
import { gddCankerworm } from './c/gdd-cankerworm'
import { gddCanolaBNapus } from './c/gdd-canola-b-napus'
import { gddCanolaBRapa } from './c/gdd-canolda-b-rapa'
import { gddCarrotWeevilLOregonensis } from './c/gdd-carrot-weevil/gdd-carrot-weevil-l-oregonensis'
import { gddCarrotWeevilLTexanus } from './c/gdd-carrot-weevil/gdd-carrot-weevil-l-texanus'
import { gddCeleryLooper } from './c/gdd-celery-looper'
import { gddCerealLeafBeetle } from './c/gdd-cereal-leaf-beetle'
import { gddChickpeaDesi } from './c/gdd-chickpea-desi'
import { gddCitricolaScaleEmergence } from './c/gdd-citricola-scale-emergence'
import { gddCitrusFlowering } from './c/gdd-citrus-flowering'
import { gddCitrusRedMite } from './c/gdd-citrus-red-mite'
import { gddCitrusThrips } from './c/gdd-citrus-thrips'
import { gddCodlingMoth } from './c/gdd-codling-moth'
import { gddConvergentLadyBeetle } from './c/gdd-convergent-lady-beetle'
import { gddCooleySpruceGallAdelgid } from './c/gdd-cooley-spruce-gall-adelgid'
import { gddCornEarworm } from './c/gdd-corn-earworm'
import { gddCornLeafAphidAlate } from './c/gdd-corn-leaf-aphid/gdd-corn-leaf-aphid-alate'
import { gddCornLeafAphidApterous } from './c/gdd-corn-leaf-aphid/gdd-corn-leaf-aphid-apterous'
import { gddCottonBollworm } from './c/gdd-cotton-bollworm'
import { gddCottonyMapleLeafScale } from './c/gdd-cottony-maple-leaf-scale'
import { gddCottonyMapleScale } from './c/gdd-cottony-maple-scale'
import { gddCottonyTaxusScale } from './c/gdd-cottony-taxus-scale'
import { gddCranberry } from './c/gdd-cranberry'
import { gddCranberryFruitworm } from './c/gdd-cranberry-fruitworm'
import { gddCruciferFleaBeetle } from './c/gdd-crucifer-flea-beetle'
import { gddCubanLaurelThrips } from './c/gdd-cuban-laurel-thrips'
import { gddCucumberCobra } from './c/gdd-cucumber-cobra'
import { gddCucumberDasherII } from './c/gdd-cucumber-dasher-ii'
import { gddCucumberExtreme } from './c/gdd-cucumber-extreme'
import { gddCucumberMarketmore } from './c/gdd-cucumber-marketmore'
import { gddCucumberSupremo } from './c/gdd-cucumber-supremo'
import { gddCucumberZapata } from './c/gdd-cucumber-zapata'
import { gddDiambondbackMoth } from './d/gdd-diambondback-moth'
import { gddDiorhabdaCarinulata } from './d/gdd-diorhabda-carinulata'
import { gddDogwoodBorer } from './d/gdd-dogwood-borer'
import { gddDogwoodSawfly } from './d/gdd-dogwood-sawfly'
import { gddDouglasFirNeedleMidge } from './d/gdd-douglas-fir-needle-midge'
import { gddDownyBrome } from './d/gdd-downy-brome'
import { gddEasternPineShootBorer } from './e/gdd-eastern-pine-shoot-borer'
import { gddEasternSpruceGallAdelgid } from './e/gdd-eastern-spruce-gall-adelgid'
import { gddEasternTentCaterpillar } from './e/gdd-eastern-tent-caterpillar'
import { gddEgyptianAlfalfaWeevil } from './e/gdd-egyptian-alfalfa-weevil'
import { gddEgyptianCottonWorm } from './e/gdd-egyptian-cotton-worm'
import { gddElmBarkBeetle } from './e/gdd-elm-bark-beetle'
import { gddElmCasebearer } from './e/gdd-elm-casebearer'
import { gddElmLeafAphid } from './e/gdd-elm-leaf-aphid'
import { gddElmLeafBeetle } from './e/gdd-elm-leaf-beetle'
import { gddElmLeafminer } from './e/gdd-elm-leafminer'
import { gddElongateHemlockScale } from './e/gdd-elongate-hemlock-scale'
import { gddEmeraldAshBorerEmergence } from './e/gdd-emerald-ash-borer-emergence'
import { gddEncarsiaFormosa } from './e/gdd-encarsia-formosa'
import { gddEnglishGrainAphidAlate } from './e/gdd-english-grain-aphid/gdd-english-grain-aphid-alate'
import { gddEnglishGrainAphidApterous } from './e/gdd-english-grain-aphid/gdd-english-grain-aphid-apterous'
import { gddEuonymusScale } from './e/gdd-euonymus-scale'
import { gddEuropeanElmScale } from './e/gdd-european-elm-scale'
import { gddEuropeanFruitLecaniumScale } from './e/gdd-european-fruit-lecanium-scale'
import { gddEuropeanGrapevineMoth } from './e/gdd-european-grapevine-moth'
import { gddEuropeanPineSawfly } from './e/gdd-european-pine-sawfly'
import { gddEuropeanPineShootMoth } from './e/gdd-european-pine-shoot-moth'
import { gddEuropeanRedMite } from './e/gdd-european-red-mite'
import { gddFallWebworm } from './f/gdd-fall-webworm'
import { gddFalseCodlingMoth } from './f/gdd-false-codling-moth'
import { gddFendersBlueButterfly } from './f/gdd-fenders-blue-butterfly'
import { gddFilbertworm } from './f/gdd-filbertworm'
import { gddFlax } from './f/gdd-flax'
import { gddFletcherScale } from './f/gdd-fletcher-scale'
import { gddForestTentCaterpillar } from './f/gdd-forest-tent-caterpillar'
import { gddFruittreeLeafroller } from './f/gdd-fruittree-leafroller'
import { gddFullerRoseBeetle } from './f/gdd-fuller-rose-beetle'
import { gddGalerucellaCalmariensis } from './g/gdd-galerucella-calmariensis'
import { gddGoldenOakScale } from './g/gdd-golden-oak-scale'
import { gddGrapeBerryMoth } from './g/gdd-grape-berry-moth'
import { gddGrapeAverageAllVarietals } from './g/gdd-grape/gdd-grape-average-all-varietals'
import { gddGrapeAverageRed } from './g/gdd-grape/gdd-grape-average-red'
import { gddGrapeAverageWhite } from './g/gdd-grape/gdd-grape-average-white'
import { gddGrapeCabernetFranc } from './g/gdd-grape/gdd-grape-cabernet-franc'
import { gddGrapeCabernetSauvignon } from './g/gdd-grape/gdd-grape-cabernet-sauvignon'
import { gddGrapeChardonnay } from './g/gdd-grape/gdd-grape-chardonnay'
import { gddGrapeCheninBlanc } from './g/gdd-grape/gdd-grape-chenin-blanc'
import { gddGrapeGewurztraminer } from './g/gdd-grape/gdd-grape-gewurztraminer'
import { gddGrapeLemberger } from './g/gdd-grape/gdd-grape-lemberger'
import { gddGrapeMalbec } from './g/gdd-grape/gdd-grape-malbec'
import { gddGrapeMerlot } from './g/gdd-grape/gdd-grape-merlot'
import { gddGrapeMeunier } from './g/gdd-grape/gdd-grape-meunier'
import { gddGrapeMuscatBlanc } from './g/gdd-grape/gdd-grape-muscat-blanc'
import { gddGrapePinotGris } from './g/gdd-grape/gdd-grape-pinot-gris'
import { gddGrapePinotNoir } from './g/gdd-grape/gdd-grape-pinot-noir'
import { gddGrapeRiesling } from './g/gdd-grape/gdd-grape-riesling'
import { gddGrapeSauvignonBlanc } from './g/gdd-grape/gdd-grape-sauvignon-blanc'
import { gddGrapeSemillon } from './g/gdd-grape/gdd-grape-semillon'
import { gddGrapeSyrah } from './g/gdd-grape/gdd-grape-syrah'
import { gddGrapeThompsonSeedless } from './g/gdd-grape/gdd-grape-thompson-seedless'
import { gddGrapeZinfandel } from './g/gdd-grape/gdd-grape-zinfandel'
import { gddGreaterPeachTreeBorer } from './g/gdd-greater-peach-tree-borer'
import { gddGreenPeachAphid } from './g/gdd-green-peach-aphid'
import { gddGreenhouseWhitefly } from './g/gdd-greenhouse-whitefly'
import { gddGreenstripedMapleworm } from './g/gdd-greenstriped-mapleworm'
import { gddGypsyMoth } from './g/gdd-gypsy-moth'
import { gddHairyNightshade } from './h/gdd-hairy-nightshade'
import { gddHoneydewMoth } from './h/gdd-honeydew-moth'
import { gddHoneylocustPlantBug } from './h/gdd-honeylocust-plant-bug'
import { gddHoneylocustSpiderMite } from './h/gdd-honeylocust-spider-mite'
import { gddHopVineBorerEmergence } from './h/gdd-hop-vine-borer-emergence'
import { gddHopsDownyMildew } from './h/gdd-hops-downy-mildew'
import { gddImportedCabbageworm } from './i/gdd-imported-cabbageworm'
import { gddImportedWillowLeafBeetle } from './i/gdd-imported-willow-leaf-beetle'
import { gddIndianmealMothAlmonds } from './i/gdd-indianmeal-moth-almonds'
import { gddIndianmealMothBran } from './i/gdd-indianmeal-moth-bran'
import { gddIndianmealMothPistachios } from './i/gdd-indianmeal-moth-pistachios'
import { gddIndianmealMothWalnuts } from './i/gdd-indianmeal-moth-walnuts'
import { gddIntroducedPineSawfly } from './i/gdd-introduced-pine-sawfly'
import { gddJackPineBudworm } from './j/gdd-jack-pine-budworm'
import { gddJackPineSawfly } from './j/gdd-jack-pine-sawfly'
import { gddJapaneseBeetleEmergence } from './j/gdd-japanese-beetle-emergence'
import { gddJapaneseFlowerThrips } from './j/gdd-japanese-flower-thrips'
import { gddJapaneseKnotweedPsyllid } from './j/gdd-japanese-knotweed-psyllid'
import { gddJapanesePineSawyerBeetle } from './j/gdd-japanese-pine-sawyer-beetle'
import { gddJohnsongrass } from './j/gdd-johnsongrass'
import { gddJuniperScale } from './j/gdd-juniper-scale'
import { gddLacanobiaFruitworm } from './l/gdd-lacanobia-fruitworm'
import { gddLambsQuarter } from './l/gdd-lambs-quarter'
import { gddLarchCasebearer } from './l/gdd-larch-casebearer'
import { gddLargeAspenTortrix } from './l/gdd-large-aspen-tortrix'
import { gddLentil } from './l/gdd-lentil'
import { gddLesserPeachTreeBorer } from './l/gdd-lesser-peach-tree-borer'
import { gddLightBrownAppleMoth } from './l/gdd-light-brown-apple-moth'
import { gddLilacBorerEmergence } from './l/gdd-lilac-borer-emergence'
import { gddLilacRootWeevil } from './l/gdd-lilac-root-weevil'
import { gddLocustBorer } from './l/gdd-locust-borer'
import { gddLygusBugAlfalfa } from './l/gdd-lygus-bug-alfalfa'
import { gddLygusBugGreenBeans } from './l/gdd-lygus-bug-green-beans'
import { gddLygusBugSafflower } from './l/gdd-lygus-bug-safflower'
import { gddLygusBugStrawberry } from './l/gdd-lygus-bug-strawberry'
import { gddMagnoliaScale } from './m/gdd-magnolia-scale'
import { gddMeadowSpittlebug } from './m/gdd-meadow-spittlebug'
import { gddMediterraneanFruitFly } from './m/gdd-mediterranean-fruit-fly'
import { gddMelonFly } from './m/gdd-melon-fly'
import { gddMetaseiulusOccidentalis } from './m/gdd-metaseiulus-occidentalis'
import { gddMexicanBeanBeetle } from './m/gdd-mexican-bean-beetle'
import { gddMimosaWebworm } from './m/gdd-mimosa-webworm'
import { gddMintFleaBeetle } from './m/gdd-mint-flea-beetle'
import { gddMintRootBorer } from './m/gdd-mint-root-borer'
import { gddMummyBerry } from './m/gdd-mummy-berry'
import { gddMustardBAlba } from './m/gdd-mustard-b-alba'
import { gddMustardBJuncea } from './m/gdd-mustard-b-juncea'
import { gddNantucketPineTipMoth } from './n/gdd-nantucket-pine-tip-moth'
import { gddNeoseiulusFallacis } from './n/gdd-neoseiulus-fallacis'
import { gddNorthernCornRootworm } from './n/gdd-northern-corn-rootworm'
import { gddNorthernPineWeevil } from './n/gdd-northern-pine-weevil'
import { gddOakAmbrosiaBeetle } from './o/gdd-oak-ambrosia-beetle'
import { gddOat } from './o/gdd-oat'
import { gddObliquebandedLeafroller } from './o/gdd-obliquebanded-leafroller'
import { gddObliquebandedLeafrollerHazelnut } from './o/gdd-obliquebanded-leafroller-hazelnut'
import { gddObscureScaleEmergence } from './o/gdd-obscure-scale-emergence'
import { gddOliveScale } from './o/gdd-olive-scale'
import { gddOmnivorousLeafroller } from './o/gdd-omnivorous-leafroller'
import { gddOmnivorousLeaftier } from './o/gdd-omnivorous-leaftier'
import { gddOnionMaggot } from './o/gdd-onion-maggot'
import { gddOnionThrips } from './o/gdd-onion-thrips'
import { gddOrangeTortrixMoth } from './o/gdd-orange-tortrix-moth'
import { gddOrientalFruitFly } from './o/gdd-oriental-fruit-fly'
import { gddOrientalFruitMoth } from './o/gdd-oriental-fruit-moth'
import { gddOystershellScale } from './o/gdd-oystershell-scale'
import { gddPacificSpiderMite } from './p/gdd-pacific-spider-mite'
import { gddPalesWeevil } from './p/gdd-pales-weevil'
import { gddPandemisLeafroller } from './p/gdd-pandemis-leafroller'
import { gddPeaAphidAlate } from './p/gdd-pea-aphid/gdd-pea-aphid-alate'
import { gddPeaAphidApterous } from './p/gdd-pea-aphid/gdd-pea-aphid-apterous'
import { gddPeaYellow } from './p/gdd-pea-yellow'
import { gddPeachTwigBorer } from './p/gdd-peach-twig-borer'
import { gddPeachtreeBorer } from './p/gdd-peachtree-borer'
import { gddPearPsylla } from './p/gdd-pear-psylla'
import { gddPearRustMite } from './p/gdd-pear-rust-mite'
import { gddPearScab } from './p/gdd-pear-scab'
import { gddPecanNutCasebearer } from './p/gdd-pecan-nut-casebearer'
import { gddPepperBellKing } from './p/gdd-pepper-bell-king'
import { gddPepperGatherersGold } from './p/gdd-pepper-gatherers-gold'
import { gddPepperKingArthur } from './p/gdd-pepper-king-arthur'
import { gddPepperStockyRedRoaster } from './p/gdd-pepper-stocky-red-roaster'
import { gddPineChafer } from './p/gdd-pine-chafer'
import { gddPineEngraver } from './p/gdd-pine-engraver'
import { gddPineNeedleMidge } from './p/gdd-pine-needle-midge'
import { gddPineNeedleScale } from './p/gdd-pine-needle-scale'
import { gddPineRootCollarWeevil } from './p/gdd-pine-root-collar-weevil'
import { gddPineShootBeetle } from './p/gdd-pine-shoot-beetle'
import { gddPineTortoiseScale } from './p/gdd-pine-tortoise-scale'
import { gddPineTreeLappetMoth } from './p/gdd-pine-tree-lappet-moth'
import { gddPineTubeMoth } from './p/gdd-pine-tube-moth'
import { gddPinkBollworm } from './p/gdd-pink-bollworm'
import { gddPistachioShellHardening } from './p/gdd-pistachio-shell-hardening'
import { gddPlumFruitMoth } from './p/gdd-plum-fruit-moth'
import { gddPotatoCapsid } from './p/gdd-potato-capsid'
import { gddPotatoLeafhopper } from './p/gdd-potato-leafhopper'
import { gddPotatoPsyllid } from './p/gdd-potato-psyllid'
import { gddPotatoTuberworm } from './p/gdd-potato-tuberworm'
import { gddRedheadedPineSawfly } from './r/gdd-redheaded-pine-sawfly'
import { gddRedrootPigweed } from './r/gdd-redroot-pigweed'
import { gddRoseCaraMia } from './r/gdd-rose-cara-mia'
import { gddRoseRoyalty } from './r/gdd-rose-royalty'
import { gddRoseSonia } from './r/gdd-rose-sonia'
import { gddRussianWheatAphidAlate } from './r/gdd-russian-wheat-aphid/gdd-russian-wheat-aphid-alate'
import { gddRussianWheatAphidApterous } from './r/gdd-russian-wheat-aphid/gdd-russian-wheat-aphid-apterous'
import { gddSafflower } from './s/gdd-safflower'
import { gddSanJoseScaleEmergence } from './s/gdd-san-jose-scale-emergence'
import { gddSeedcornMaggotEmergence } from './s/gdd-seedcorn-maggot-emergence'
import { gddSerpentineFruitFlyChicozapote } from './s/gdd-serpentine-fruit-fly-chicozapote'
import { gddSerpentineFruitFlyPeach } from './s/gdd-serpentine-fruit-fly-peach'
import { gddSevenspottedLadyBeetle } from './s/gdd-sevenspotted-lady-beetle'
import { gddSilverYMoth } from './s/gdd-silver-y-moth'
import { gddSmallBroomrape } from './s/gdd-small-broomrape'
import { gddSmoothCrabgrass } from './s/gdd-smooth-crabgrass'
import { gddSodWebworm } from './s/gdd-sod-webworm'
import { gddSourCherry } from './s/gdd-sour-cherry'
import { gddSparganothisMoth } from './s/gdd-sparganothis-moth'
import { gddSpodopteraLitura } from './s/gdd-spodoptera-litura'
import { gddSpottedTentiformLeafminer } from './s/gdd-spotted-tentiform-leafminer'
import { gddSpottedWingDrosophilia } from './s/gdd-spotted-wing-drosophilia'
import { gddSpruceBudScale } from './s/gdd-spruce-bud-scale'
import { gddSpruceBudworm } from './s/gdd-spruce-budworm'
import { gddSpruceNeedleminer } from './s/gdd-spruce-needleminer'
import { gddSpruceSpiderMite } from './s/gdd-spruce-spider-mite'
import { gddSquashBug } from './s/gdd-squash-bug'
import { gddStrawberryRootWeevilPeppermint } from './s/gdd-strawberry-root-weevil-peppermint'
import { gddStrawberryRootWeevilStrawberry } from './s/gdd-strawberry-root-weevil-strawberry'
import { gddStrawberrySpiderMite } from './s/gdd-strawberry-spider-mite'
import { gddStripedPineScale } from './s/gdd-striped-pine-scale'
import { gddSugarbeetRootMaggot } from './s/gdd-sugarbeet-root-maggot'
import { gddSunflower } from './s/gdd-sunflower'
import { gddSunflowerBeetleEmergence } from './s/gdd-sunflower-beetle-emergence'
import { gddSunflowerMoth } from './s/gdd-sunflower-moth'
import { gddSunflowerStemWeevil } from './s/gdd-sunflower-stem-weevil'
import { gddSunnPest } from './s/gdd-sunn-pest'
import { gddSweetCorn4001 } from './s/gdd-sweet-corn-4001'
import { gddSweetCornJubilee } from './s/gdd-sweet-corn-jubilee'
import { gddSweetCornKokanee } from './s/gdd-sweet-corn-kokanee'
import { gddSweetCornLusciousDS } from './s/gdd-sweet-corn-luscious-ds'
import { gddSweetCornLusciousTransplanted } from './s/gdd-sweet-corn-luscious-transplanted'
import { gddSweetCornSugarPearlTemptationDS } from './s/gdd-sweet-corn-sugar-pearl-temptation-ds'
import { gddSweetCornSugarPearlTemptationTransplanted } from './s/gdd-sweet-corn-sugar-pearl-temptation-transplanted'
import { gddSweetpotatoWhitefly } from './s/gdd-sweetpotato-whitefly'
import { gddTeparyBeanG40016 } from './t/gdd-tepary-bean-g40016'
import { gddTeparyBeanG40034 } from './t/gdd-tepary-bean-g40034'
import { gddTeparyBeanHoodWhite } from './t/gdd-tepary-bean-hood-white'
import { gddThreecorneredAlfalfaHopper } from './t/gdd-threecornered-alfalfa-hopper'
import { gddTobaccoBudworm } from './t/gdd-tobacco-budworm'
import { gddTobaccoBudwormEmergence } from './t/gdd-tobacco-budworm-emergence'
import { gddTomatoBigBeef } from './t/gdd-tomato-big-beef'
import { gddTomatoFruitBorer } from './t/gdd-tomato-fruit-borer'
import { gddTomatoFruitworm } from './t/gdd-tomato-fruitworm'
import { gddTomatoIndigoRose } from './t/gdd-tomato-indigo-rose'
import { gddTomatoLeafminer } from './t/gdd-tomato-leafminer'
import { gddTomatoMonica } from './t/gdd-tomato-monica'
import { gddTomatoNewGirl } from './t/gdd-tomato-new-girl'
import { gddTomatoPinworm } from './t/gdd-tomato-pinworm'
import { gddTransverseLadyBeetle } from './t/gdd-transverse-lady-beetle'
import { gddTurpentineBeetle } from './t/gdd-turpentine-beetle'
import { gddTwospottedLadyBeetle } from './t/gdd-twospotted-lady-beetle'
import { gddTwospottedSpiderMite } from './t/gdd-twospotted-spider-mite'
import { gddVariegatedCutworm } from './v/gdd-variegated-cutworm'
import { gddVariegatedCutwormPeppermint } from './v/gdd-variegated-cutworm-peppermint'
import { gddVegetableLeafminer } from './v/gdd-vegetable-leafminer'
import { gddVineMealybug } from './v/gdd-vine-mealybug'
import { gddVoriaRuralis } from './v/gdd-voria-ruralis'
import { gddWalnutCaterpillar } from './w/gdd-walnut-caterpillar'
import { gddWalnutHuskFly } from './w/gdd-walnut-husk-fly'
import { gddWesternBeanCutworm } from './w/gdd-western-bean-cutworm'
import { gddWesternCherryFruitFly } from './w/gdd-western-cherry-fruit-fly'
import { gddWesternFlowerThrips } from './w/gdd-western-flower-thrips'
import { gddWesternGrapeLeafhopper } from './w/gdd-western-grape-leafhopper'
import { gddWesternGrapeleafSkeletonizer } from './w/gdd-western-grapeleaf-skeletonizer'
import { gddWesternPineShootBorer } from './w/gdd-western-pine-shoot-borer'
import { gddWesternSpruceBudworm } from './w/gdd-western-spruce-budworm'
import { gddWesternTentCaterpillar } from './w/gdd-western-tent-catterpillar'
import { gddWheatHardRed } from './w/gdd-wheat-hard-red'
import { gddWheatSpring } from './w/gdd-wheat-spring'
import { gddWheatWinter } from './w/gdd-wheat-winter'
import { gddWhitePineWeevil } from './w/gdd-white-pine-weevil'
import { gddWildOats } from './w/gdd-wild-oats'
import { gddYellowNutsedge } from './y/gdd-yellow-nutsedge'
import { gddZimmermanPineMoth } from './z/gdd-zimmerman-pine-moth'

export * from './gdd'

export const gddModelDefines = {
  gddAlfalfaEarlySummer,
  gddAlfalfaLateSummer,
  gddAlfalfaSpring,
  gddAlfalfaWeevil,
  gddAlmondNavelOrangewormMummy,
  gddAlmondNavelOrangewormNewCrop,
  gddAmericanPlumBorer,
  gddAmericanSerpentineLeafminerCelery,
  gddAmericanSerpentineLeafminerChrysanthemums,
  gddAnagrusEpos,
  gddAphytisMelinus,
  gddAppleMaggotEmergence,
  gddAppleScab,
  gddArborvitaeLeafminer,
  gddArmyworm,
  gddArtichokePlumeMoth,
  gddAsianCitrusPsyllid,
  gddAsianLonghornedBeetleEmergence,
  gddAsiaticRiceBorer,
  gddAsparagusBeetle,
  gddAzaleaLeafminer,
  gddAzaleaWhitefly,
  gddBagworm,
  gddBalsamGallMidge,
  gddBalsamTwigAphid,
  gddBandedAshClearwingBorer,
  gddBarley,
  gddBathyplectesCurculionis,
  gddBean5630,
  gddBeanBlackTurtleSoup,
  gddBeanCommodore,
  gddBeanDarkRedKidney,
  gddBeanLinden,
  gddBeanPI165426,
  gddBeanProvider,
  gddBeanSahara,
  gddBeanSal,
  gddBeanSeedsaja,
  gddBeanSutter,
  gddBeechScale,
  gddBeetArmyworm,
  gddBeetLeafhopper,
  gddBerthaArmyworm,
  gddBirchLeafminer,
  gddBirchSkeletonizer,
  gddBlackberryLeafhopper,
  gddBlackCutworm,
  gddBlackNightshade,
  gddBlackPineleafScale,
  gddBlackVineWeevil,
  gddBlueAlfalfaAphidAlate,
  gddBlueAlfalfaAphidApterous,
  gddBluegrass,
  gddBoxwoodLeafminer,
  gddBoxwoodMite,
  gddBoxwoodPsyllid,
  gddBroccoliArcadia,
  gddBroccoliEmeraldPride,
  gddBroccoliGreenMagic,
  gddBroccoliImperial,
  gddBronzeBirchBorer,
  gddBrownMarmoratedStinkBug,
  gddCabbageAphid,
  gddCabbageLooper,
  gddCabbageMaggotEmergence,
  gddCanarySeed,
  gddCankerworm,
  gddCanolaBNapus,
  gddCanolaBRapa,
  gddCarrotWeevilLOregonensis,
  gddCarrotWeevilLTexanus,
  gddCeleryLooper,
  gddCerealLeafBeetle,
  gddChickpeaDesi,
  gddCitricolaScaleEmergence,
  gddCitrusFlowering,
  gddCitrusRedMite,
  gddCitrusThrips,
  gddCodlingMoth,
  gddConvergentLadyBeetle,
  gddCooleySpruceGallAdelgid,
  gddCornEarworm,
  gddCornLeafAphidAlate,
  gddCornLeafAphidApterous,
  gddCottonBollworm,
  gddCottonyMapleLeafScale,
  gddCottonyMapleScale,
  gddCottonyTaxusScale,
  gddCranberry,
  gddCranberryFruitworm,
  gddCruciferFleaBeetle,
  gddCubanLaurelThrips,
  gddCucumberCobra,
  gddCucumberDasherII,
  gddCucumberExtreme,
  gddCucumberMarketmore,
  gddCucumberSupremo,
  gddCucumberZapata,
  gddDiambondbackMoth,
  gddDiorhabdaCarinulata,
  gddDogwoodBorer,
  gddDogwoodSawfly,
  gddDouglasFirNeedleMidge,
  gddDownyBrome,
  gddEasternPineShootBorer,
  gddEasternSpruceGallAdelgid,
  gddEasternTentCaterpillar,
  gddEgyptianAlfalfaWeevil,
  gddEgyptianCottonWorm,
  gddElmBarkBeetle,
  gddElmCasebearer,
  gddElmLeafAphid,
  gddElmLeafBeetle,
  gddElmLeafminer,
  gddElongateHemlockScale,
  gddEmeraldAshBorerEmergence,
  gddEncarsiaFormosa,
  gddEnglishGrainAphidAlate,
  gddEnglishGrainAphidApterous,
  gddEuonymusScale,
  gddEuropeanElmScale,
  gddEuropeanFruitLecaniumScale,
  gddEuropeanGrapevineMoth,
  gddEuropeanPineSawfly,
  gddEuropeanPineShootMoth,
  gddEuropeanRedMite,
  gddFallWebworm,
  gddFalseCodlingMoth,
  gddFendersBlueButterfly,
  gddFilbertworm,
  gddFlax,
  gddFletcherScale,
  gddForestTentCaterpillar,
  gddFruittreeLeafroller,
  gddFullerRoseBeetle,
  gddGalerucellaCalmariensis,
  gddGoldenOakScale,
  gddGrapeAverageAllVarietals,
  gddGrapeAverageRed,
  gddGrapeAverageWhite,
  gddGrapeBerryMoth,
  gddGrapeCabernetFranc,
  gddGrapeCabernetSauvignon,
  gddGrapeChardonnay,
  gddGrapeCheninBlanc,
  gddGrapeGewurztraminer,
  gddGrapeLemberger,
  gddGrapeMalbec,
  gddGrapeMerlot,
  gddGrapeMeunier,
  gddGrapeMuscatBlanc,
  gddGrapePinotGris,
  gddGrapePinotNoir,
  gddGrapeRiesling,
  gddGrapeSauvignonBlanc,
  gddGrapeSemillon,
  gddGrapeSyrah,
  gddGrapeThompsonSeedless,
  gddGrapeZinfandel,
  gddGreaterPeachTreeBorer,
  gddGreenhouseWhitefly,
  gddGreenPeachAphid,
  gddGreenstripedMapleworm,
  gddGypsyMoth,
  gddHairyNightshade,
  gddHoneydewMoth,
  gddHoneylocustPlantBug,
  gddHoneylocustSpiderMite,
  gddHopsDownyMildew,
  gddHopVineBorerEmergence,
  gddImportedCabbageworm,
  gddImportedWillowLeafBeetle,
  gddIndianmealMothAlmonds,
  gddIndianmealMothBran,
  gddIndianmealMothPistachios,
  gddIndianmealMothWalnuts,
  gddIntroducedPineSawfly,
  gddJackPineBudworm,
  gddJackPineSawfly,
  gddJapaneseBeetleEmergence,
  gddJapaneseFlowerThrips,
  gddJapaneseKnotweedPsyllid,
  gddJapanesePineSawyerBeetle,
  gddJohnsongrass,
  gddJuniperScale,
  gddLacanobiaFruitworm,
  gddLambsQuarter,
  gddLarchCasebearer,
  gddLargeAspenTortrix,
  gddLentil,
  gddLesserPeachTreeBorer,
  gddLightBrownAppleMoth,
  gddLilacBorerEmergence,
  gddLilacRootWeevil,
  gddLocustBorer,
  gddLygusBugAlfalfa,
  gddLygusBugGreenBeans,
  gddLygusBugSafflower,
  gddLygusBugStrawberry,
  gddMagnoliaScale,
  gddMeadowSpittlebug,
  gddMediterraneanFruitFly,
  gddMelonFly,
  gddMetaseiulusOccidentalis,
  gddMexicanBeanBeetle,
  gddMimosaWebworm,
  gddMintFleaBeetle,
  gddMintRootBorer,
  gddMummyBerry,
  gddMustardBAlba,
  gddMustardBJuncea,
  gddNantucketPineTipMoth,
  gddNeoseiulusFallacis,
  gddNorthernCornRootworm,
  gddNorthernPineWeevil,
  gddOakAmbrosiaBeetle,
  gddOat,
  gddObliquebandedLeafroller,
  gddObliquebandedLeafrollerHazelnut,
  gddObscureScaleEmergence,
  gddOliveScale,
  gddOmnivorousLeafroller,
  gddOmnivorousLeaftier,
  gddOnionMaggot,
  gddOnionThrips,
  gddOrangeTortrixMoth,
  gddOrientalFruitFly,
  gddOrientalFruitMoth,
  gddOystershellScale,
  gddPacificSpiderMite,
  gddPalesWeevil,
  gddPandemisLeafroller,
  gddPeaAphidAlate,
  gddPeaAphidApterous,
  gddPeachtreeBorer,
  gddPeachTwigBorer,
  gddPearPsylla,
  gddPearRustMite,
  gddPearScab,
  gddPeaYellow,
  gddPecanNutCasebearer,
  gddPepperBellKing,
  gddPepperGatherersGold,
  gddPepperKingArthur,
  gddPepperStockyRedRoaster,
  gddPineChafer,
  gddPineEngraver,
  gddPineNeedleMidge,
  gddPineNeedleScale,
  gddPineRootCollarWeevil,
  gddPineShootBeetle,
  gddPineTortoiseScale,
  gddPineTreeLappetMoth,
  gddPineTubeMoth,
  gddPinkBollworm,
  gddPistachioShellHardening,
  gddPlumFruitMoth,
  gddPotatoCapsid,
  gddPotatoLeafhopper,
  gddPotatoPsyllid,
  gddPotatoTuberworm,
  gddRedheadedPineSawfly,
  gddRedrootPigweed,
  gddRoseCaraMia,
  gddRoseRoyalty,
  gddRoseSonia,
  gddRussianWheatAphidAlate,
  gddRussianWheatAphidApterous,
  gddSafflower,
  gddSanJoseScaleEmergence,
  gddSeedcornMaggotEmergence,
  gddSerpentineFruitFlyChicozapote,
  gddSerpentineFruitFlyPeach,
  gddSevenspottedLadyBeetle,
  gddSilverYMoth,
  gddSmallBroomrape,
  gddSmoothCrabgrass,
  gddSodWebworm,
  gddSourCherry,
  gddSparganothisMoth,
  gddSpodopteraLitura,
  gddSpottedTentiformLeafminer,
  gddSpottedWingDrosophilia,
  gddSpruceBudScale,
  gddSpruceBudworm,
  gddSpruceNeedleminer,
  gddSpruceSpiderMite,
  gddSquashBug,
  gddStrawberryRootWeevilPeppermint,
  gddStrawberryRootWeevilStrawberry,
  gddStrawberrySpiderMite,
  gddStripedPineScale,
  gddSugarbeetRootMaggot,
  gddSunflower,
  gddSunflowerBeetleEmergence,
  gddSunflowerMoth,
  gddSunflowerStemWeevil,
  gddSunnPest,
  gddSweetCorn4001,
  gddSweetCornJubilee,
  gddSweetCornKokanee,
  gddSweetCornLusciousDS,
  gddSweetCornLusciousTransplanted,
  gddSweetCornSugarPearlTemptationDS,
  gddSweetCornSugarPearlTemptationTransplanted,
  gddSweetpotatoWhitefly,
  gddTeparyBeanG40016,
  gddTeparyBeanG40034,
  gddTeparyBeanHoodWhite,
  gddThreecorneredAlfalfaHopper,
  gddTobaccoBudworm,
  gddTobaccoBudwormEmergence,
  gddTomatoBigBeef,
  gddTomatoFruitBorer,
  gddTomatoFruitworm,
  gddTomatoIndigoRose,
  gddTomatoLeafminer,
  gddTomatoMonica,
  gddTomatoNewGirl,
  gddTomatoPinworm,
  gddTransverseLadyBeetle,
  gddTurpentineBeetle,
  gddTwospottedLadyBeetle,
  gddTwospottedSpiderMite,
  gddVariegatedCutworm,
  gddVariegatedCutwormPeppermint,
  gddVegetableLeafminer,
  gddVineMealybug,
  gddVoriaRuralis,
  gddWalnutCaterpillar,
  gddWalnutHuskFly,
  gddWesternBeanCutworm,
  gddWesternCherryFruitFly,
  gddWesternFlowerThrips,
  gddWesternGrapeLeafhopper,
  gddWesternGrapeleafSkeletonizer,
  gddWesternPineShootBorer,
  gddWesternSpruceBudworm,
  gddWesternTentCaterpillar,
  gddWheatHardRed,
  gddWheatSpring,
  gddWheatWinter,
  gddWhitePineWeevil,
  gddWildOats,
  gddYellowNutsedge,
  gddZimmermanPineMoth,
} as const
