import { GddModelDefine } from '../gdd'

export const gddPinkBollworm: GddModelDefine = {
  lowerThreshold: 55,
  upperThreshold: 86,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddPinkBollworm',
  name: 'Pink Bollworm',
  fullName: 'Pink Bollworm (Pectinophora gossypiella) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence from Overwintering',
      range: {
        min: 0,
        max: 500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have not emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - Overwintering Generation',
      range: {
        min: 501,
        max: 1180,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from overwintered larvae have emerged.',
        },
      ],
    },
    {
      name: 'Peak Adult Emergence - Overwintering Generation',
      range: {
        min: 1181,
        max: 1300,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Peak adults from overwintered larvae have emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - First Generation',
      range: {
        min: 1301,
        max: 2100,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from first generation have emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - Second Generation',
      range: {
        min: 2101,
        max: 2200,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from second generation have emerged.',
        },
      ],
    },
    {
      name: 'End Adult Emergence - Overwintering Generation',
      range: {
        min: 2201,
        max: 2900,
      },
      infos: [
        {
          name: 'Adults',
          value: 'End of emergence of adults from overwintered larvae, other generations may be present.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - Third Generation',
      range: {
        min: 2901,
        max: 3700,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from third generation have emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - Fourth Generation',
      range: {
        min: 3701,
        max: 4500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from fourth generation have emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - Fifth Generation',
      range: {
        min: 4501,
        max: 5000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from fifth generation have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pink Bollworm Degree Day Model is used to predict development of life-cycle stages of Pink Bollworm (Pectinophora gossypiella), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults which can be targeted by insectide treatment.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-pink-bollworm-growing-degree-day-model/',
  citations: [
    'Pink Bollworm. IN: Integrated Pest Management for Cotton in the Western United States. University of California Statewide IPM Project. University of Arizona Statewide IPM Project. New Mexico State University. U.C. Div. Agr. Sci. Publ. #3305.',
  ],
}
