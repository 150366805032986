import { GddModelDefine } from '../gdd'

export const gddLambsQuarter: GddModelDefine = {
  lowerThreshold: 42,
  upperThreshold: 95,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddLambsQuarter',
  name: `Lamb's Quarter GDD`,
  fullName: `Lamb's Quarter (Chenopodium berlandieri) Growing Degree Day Model`,
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Cotyledon Present',
      range: {
        min: 0,
        max: 75,
      },
      infos: [
        {
          name: 'Cotyledon',
          value: 'Cotyledon present.',
        },
      ],
    },
    {
      name: '2 Leaves Present',
      range: {
        min: 76,
        max: 173,
      },
      infos: [
        {
          name: 'Leaves',
          value: '2 leaves present.',
        },
      ],
    },
    {
      name: '4 Leaves Present',
      range: {
        min: 174,
        max: 273,
      },
      infos: [
        {
          name: 'Leaves',
          value: '4 leaves present.',
        },
      ],
    },
    {
      name: '6 Leaves Present',
      range: {
        min: 274,
        max: 596,
      },
      infos: [
        {
          name: 'Leaves',
          value: '6 leaves present.',
        },
      ],
    },
    {
      name: 'First Inflorescence',
      range: {
        min: 597,
        max: 1360,
      },
      infos: [
        {
          name: 'Inflorescence',
          value: 'First inflorescence is present.',
        },
      ],
    },
    {
      name: 'Lower 95% Confidence Interval - First Viable Seed',
      range: {
        min: 1361,
        max: 1462,
      },
      infos: [
        {
          name: 'Seeds',
          value: 'Lower 95% confidence interval - first viable seed.',
        },
      ],
    },
    {
      name: 'Average - First Viable Seed',
      range: {
        min: 1463,
        max: 1564,
      },
      infos: [
        {
          name: 'Seeds',
          value: 'Average - first viable seed.',
        },
      ],
    },
    {
      name: 'Upper 95% Confidence Interval - First Viable Seed',
      range: {
        min: 1565,
        max: 1800,
      },
      infos: [
        {
          name: 'Seeds',
          value: 'Upper 95% confidence interval - first viable seed.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    `The Lamb's Quarter Degree Day Model is used to predict seed production timing of Lamb's Quarter (Chenopodium berlandieri) based on the weather in a given season.`,
    'For best results, a biofix date should be set when the cotyledon is first emerged / observed. The growing degree day model can then be used to predict seed production.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-lambs-quarter-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
  ],
}
