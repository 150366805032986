import { GddModelDefine } from '../gdd'

export const gddImportedWillowLeafBeetle: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddImportedWillowLeafBeetle',
  name: 'Imported Willow Leaf Beetle',
  fullName: 'Imported Willow Leaf Beetle (Plagiodera versicolor) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Adults have not yet emerged.',
      range: {
        min: 0,
        max: 120,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult are not yet present.',
        },
      ],
    },
    {
      name: 'Adults Active',
      range: {
        min: 121,
        max: 275,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are present and active.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Imported Willow Leaf Beetle Degree Day Model is used to predict life-stage developments of the Imported Willow Leaf Beetle (Plagiodera versicolor), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-imported-willow-leaf-beetle-growing-degree-day-model/',
  citations: [
    'Herms,D.A. Using degree days and plant phenology to predict pest activity. Chapter 11. Tactics and Tools for IPM.',
  ],
}
