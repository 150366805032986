import { GddModelDefine } from '../gdd'

export const gddMelonFly: GddModelDefine = {
  lowerThreshold: 8.1,
  upperThreshold: 50,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddMelonFly',
  name: 'Melon Fly',
  fullName: 'Melon Fly (Dacus cucurbitae) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 21,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not yet begun to hatch.',
        },
      ],
    },
    {
      name: 'Larvae',
      range: {
        min: 22,
        max: 122,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have emerged from eggs.',
        },
      ],
    },
    {
      name: 'Pupae',
      range: {
        min: 123,
        max: 322,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Flies are in pupae stage, GDD for this stage is unknown.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Melon Fly Degree Day Model is used to predict development of life-cycle stages of the Melon Fly (Dacus cucurbitae), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adult flies are first observed. The growing degree day model can then be used to predict emergence of next generation of adults.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-melon-fly-growing-degree-day-model/',
  citations: [
    `Keck, C. B. 1951. Effect of temperature on development and activity of the melon fly. J. Econ. Entomol. 44: 1001-1002.`,
  ],
}
