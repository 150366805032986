import { GddModelDefine } from '../gdd'

export const gddLygusBugStrawberry: GddModelDefine = {
  lowerThreshold: 54,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddLygusBugStrawberry',
  name: 'Lygus Bug (Strawberry)',
  fullName: 'Lygus Bug (Lygus hesperus) on Strawberry Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 252,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not yet begun to hatch.',
        },
      ],
    },
    {
      name: 'Nymphs',
      range: {
        min: 253,
        max: 623,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs have emerged from eggs.',
        },
      ],
    },
    {
      name: 'Pre-Egglaying Adults',
      range: {
        min: 624,
        max: 799,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emerged but have not yet laid eggs.',
        },
      ],
    },
    {
      name: 'Egglaying Adults',
      range: {
        min: 800,
        max: 1000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emerged but have begun to lay eggs.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Lygus Bug Degree Day Model is used to predict development of life-cycle stages of the Lygus Bug (Lygus hesperus), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adult bugs are first observed. The growing degree day model can then be used to predict emergence of next generation of nymphs, which can be targeted for insecticide treatment.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-lygus-bug-growing-degree-day-model/',
  citations: [
    'Pickel, C., N. C. Welch, and D. B. Walsh. 1990. Timing Lygus sprays using degree-days in Central Coast Strawberries. Santa Cruz County Agricultural Extension Publication.',
  ],
}
