import { GddModelDefine } from '../gdd'

export const gddElmLeafminer: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddElmLeafminer',
  name: 'Elm Leafminer',
  fullName: 'Elm Leafminer (Fenusa ulmi) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Adults Present',
      range: {
        min: 0,
        max: 215,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adult moths have not yet emerged.',
        },
      ],
    },
    {
      name: 'Overwintered Adults Emerge',
      range: {
        min: 216,
        max: 240,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adult moths may be present.',
        },
        {
          name: 'Eggs',
          value: 'Egg-laying has not yet begun.',
        },
        {
          name: 'Larvae',
          value: 'Leaf mining larvae are not yet present.',
        },
      ],
    },
    {
      name: 'Egg Laying',
      range: {
        min: 241,
        max: 365,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adult moths may be present.',
        },
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
        {
          name: 'Larvae',
          value: 'Leaf mining larvae are not yet present.',
        },
      ],
    },
    {
      name: 'First Generation Larvae',
      range: {
        min: 366,
        max: 530,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and feeding in leaf mines.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Elm Leafminer Degree Day Model is used to predict life-stage developments of the Elm Leafminer (Fenusa ulmi.), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict egg hatch and emergence of larvae which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-elm-leafminer-growing-degree-day-model/',
  citations: [
    'Sadof, C.S. 1994. Recommendations for managing insects and mites on shade trees and shrubs. Purdue University, Dept. of Entomology publication E-41. West Lafayette, IN. 117 p.',
  ],
}
