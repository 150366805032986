import { GddModelDefine } from '../gdd'

export const gddEasternPineShootBorer: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddEasternPineShootBorer',
  name: 'Eastern Pine Shoot Borer',
  fullName: 'Eastern Pine Shoot Borer (Eucosma gloriola) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Adults Present',
      range: {
        min: 0,
        max: 75,
      },
      infos: [
        {
          name: 'Adults',
          value: 'No adult moths present.',
        },
      ],
    },
    {
      name: 'Adults Present and Egg-Laying',
      range: {
        min: 76,
        max: 200,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult are present.',
        },
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
        {
          name: 'larvae',
          value: 'Caterpillars may be present.',
        },
      ],
    },
    {
      name: 'Second Generation Larvae',
      range: {
        min: 201,
        max: 500,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and feeding before overwintering.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Eastern Pine Shoot Borer Degree Day Model is used to predict life-stage developments of the Eastern Pine Shoot Borer (Eucosma gloriola), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adult borers which can be targeted by traps.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-eastern-pine-shoot-borer-growing-degree-day-model/',
  citations: [
    'Herms,D.A. Using degree days and plant phenology to predict pest activity. Chapter 11. Tactics and Tools for IPM.',
  ],
}
