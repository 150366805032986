import { GddModelDefine } from '../gdd'

export const gddMintFleaBeetle: GddModelDefine = {
  lowerThreshold: 41,
  upperThreshold: 103,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddMintFleaBeetle',
  name: 'Mint Flea Beetle',
  fullName: 'Mint Flea Beetle (Longitarsus ferrugineus) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 405,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs are present but have not fully hatched.',
        },
      ],
    },
    {
      name: 'First Instar Larvae',
      range: {
        min: 406,
        max: 580,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First Instar Larvae have emerged.',
        },
      ],
    },
    {
      name: 'Second Instar Larvae',
      range: {
        min: 581,
        max: 785,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Second Instar Larvae have emerged.',
        },
      ],
    },
    {
      name: 'Third Instar Larvae',
      range: {
        min: 786,
        max: 900,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Third Instar Larvae have emerged.',
        },
      ],
    },
    {
      name: 'Larvae Exit Rhizomes',
      range: {
        min: 901,
        max: 1205,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have started to exit rhizomes.',
        },
      ],
    },
    {
      name: 'Pre-Pupal Stage',
      range: {
        min: 1206,
        max: 1395,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Pre-pupal stage has begun.',
        },
      ],
    },
    {
      name: 'Pupae',
      range: {
        min: 1396,
        max: 1475,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Pupal stage has begun.',
        },
      ],
    },
    {
      name: 'Adult',
      range: {
        min: 1476,
        max: 1600,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Mint Flea Beetle Degree Day Model is used to predict development of the Mint Flea Beetle (Longitarsus ferrugineus), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adults or eggs are first observed. The emergence of larvae, pupae and adults can then be predicted.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-mint-flea-beetle-growing-degree-day-model/',
  citations: [
    'Berry et al. 2000. Mint Flea Beetle Growing Degree Day model',
  ],
}
