import { GddModelDefine } from '../gdd'

export const gddWesternGrapeleafSkeletonizer: GddModelDefine = {
  lowerThreshold: 48.2,
  upperThreshold: 82.8,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddWesternGrapeleafSkeletonizer',
  name: 'Western Grapeleaf Skeletonizer',
  fullName: 'Western Grapeleaf Skeletonizer (Harrisina brillians) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Adult Moth Flight',
    range: {
      min: 0,
      max: 261,
    },
    infos: [{
      name: 'Adults',
      value: `Adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Eggs have begun to be laid, but have not yet hatched.`,
    }],
  }, {
    name: 'Larvae First Generation',
    range: {
      min: 262,
      max: 954,
    },
    infos: [{
      name: 'Larvae',
      value: `Larvae have emerged from eggs and are active.`,
    }],
  }, {
    name: 'Pupae First Generation',
    range: {
      min: 955,
      max: 1454,
    },
    infos: [{
      name: 'Pupae',
      value: `Larvae have transistioned to pupae stage.`,
    }],
  }, {
    name: 'Adult Moth Flight First Generation',
    range: {
      min: 1455,
      max: 1715,
    },
    infos: [{
      name: 'Adults',
      value: `Adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Eggs have begun to be laid, but have not yet hatched.`,
    }],
  }, {
    name: 'Larvae Second Generation',
    range: {
      min: 1716,
      max: 2408,
    },
    infos: [{
      name: 'Larvae',
      value: `Larvae have emerged from eggs and are active.`,
    }],
  }, {
    name: 'Pupae Second Generation',
    range: {
      min: 2409,
      max: 2908,
    },
    infos: [{
      name: 'Pupae',
      value: `Larvae have transistioned to pupae stage.`,
    }],
  }, {
    name: 'Adult Moth Flight Second Generation',
    range: {
      min: 2909,
      max: 3169,
    },
    infos: [{
      name: 'Adults',
      value: `Adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Eggs have begun to be laid, but have not yet hatched.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Western Grapeleaf Skeletonizer Growing Degree Day Model is used to predict life-stage developments of the Western Grapeleaf Skeletonizer (Harrisina brillians) in order to improve pest management decisions.`,
    `For best results, pheromone traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Roltsch, W. J., and M. A. Mayse. 1993. Simulation phenology model for the western grapeleaf skeletonizer (Lepidoptera: Zygaenidae): development and adult population validation. Environ. Entomol. 22: 577-586.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-western-grapeleaf-skeletonizer-growing-degree-day-model',
}
