import { GddModelDefine } from '../gdd'

export const gddVariegatedCutwormPeppermint: GddModelDefine = {
  lowerThreshold: 41,
  upperThreshold: 88,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddVariegatedCutwormPeppermint',
  name: 'Variegated Cutworm (Peppermint)',
  fullName: 'Variegated Cutworm (Peridroma saucia) on Peppermint Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adults Have Not Yet Emerged',
      range: {
        min: 0,
        max: 500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged from overwintered larvae.',
        },
      ],
    },
    {
      name: 'Peak Adult Emergence - Overwintering Generation',
      range: {
        min: 501,
        max: 900,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have emerged but have not yet reached peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak Egg-laying - Overwintering Generation',
      range: {
        min: 901,
        max: 1150,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have emerged and have reached peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak First Instar Larvae - First Generation',
      range: {
        min: 1150,
        max: 1300,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae are still in flight.',
        },
        {
          name: 'Larvae',
          value: 'First generation larvae have emerged and are in peak of 1st instar.',
        },
      ],
    },
    {
      name: 'End of Moth Flight - Overwintering Generation',
      range: {
        min: 1301,
        max: 1450,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have ended flight.',
        },
        {
          name: 'Larvae',
          value: 'First generation larvae have emerged and are in 1st or 2nd instar.',
        },
      ],
    },
    {
      name: 'Peak Third Instar Larvae - First Generation',
      range: {
        min: 1451,
        max: 1900,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have emerged and are in peak of 3rd instar.',
        },
      ],
    },
    {
      name: 'Peak Fifth Instar Larvae - First Generation',
      range: {
        min: 1901,
        max: 2100,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have emerged and are in peak of 5th instar.',
        },
      ],
    },
    {
      name: 'Peak Pupation - First Generation',
      range: {
        min: 2101,
        max: 2300,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'First generation larvae have begun pupation.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Variegated Cutworm Degree Day Model is used to predict development of life-cycle stages of Variegated Cutworm (Peridroma saucia), in order to improve pest management decisions',
    'For best results, a biofix date should be set to January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults from overwintered pupae as well as following generations. This model uses Peppermint as a host.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-variegated-cutworm-growing-degree-day-model/',
  citations: [
    'Coop L. B. 1987. Management of Variegated Cutworm in Peppermint. PhD Thesis, Dept. Entomology, Oregon State University.',
  ],
}
