import { GddModelDefine } from '../gdd'

export const gddFullerRoseBeetle: GddModelDefine = {
  lowerThreshold: 10.2,
  upperThreshold: 27.4,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddFullerRoseBeetle',
  name: 'Fuller Rose Beetle',
  fullName: 'Fuller Rose Beetle (Asynonychus godmani) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Egg Hatch',
      range: {
        min: 0,
        max: 351,
      },
      infos: [
        {
          name: 'Eggs',
          value: '99% Egg Hatch has not been reached, harvest should be avoided.',
        },
        {
          name: 'Adults',
          value: 'Adult Beetles should not be present after insecticide treatment.',
        },
      ],
    },
    {
      name: '99% Egg Hatch',
      range: {
        min: 352,
        max: 500,
      },
      infos: [
        {
          name: 'Eggs',
          value: '99% Egg hatch has occurred, and harvest can occur.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Fuller Rose Beetle Degree Day Model is used to predict egg hatch of the Fuller Rose Beetle (Asynonychus godmani), in order to improve pest management decisions',
    'For best results, a biofix date should be set to coincide with insecticide treatment to eliminate adult Beetles. The growing degree day model can then be used to predict egg hatch on eggs laid prior to insecticide treatment. After 99% Egg hatch has been reached, harvest can occur.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-fuller-rose-beetle-growing-degree-day-model/',
  citations: [
    `Lakin, K. R., and J. G. Morse. 1989. A degree-day model for Fuller's rose beetle, Pantomorus cervinus (Boheman) (Col., Curculionidae) egg hatch. J. Appl. Ent. 107: 102-106.`,
  ],
}
