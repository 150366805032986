import {
  melCastModelCommon,
  MelCastModelDefine,
} from './mel-cast'

export const melCastMuskmelonAlternariaLeafBlight: MelCastModelDefine = {
  ...melCastModelCommon,
  type: 'melCastMuskmelonAlternariaLeafBlight',
  name: 'MelCast Muskmelon Alternaria Leaf Blight',
  fullName: 'MelCast Muskmelon Alternaria Leaf Blight',
  stages: [{
    name: 'Treatment Not Recommended',
    range: {
      min: 0,
      max: 19,
    },
    infos: [{
      name: 'Muskmelon Alternaria Leaf Blight',
      value: `Risk of infection is low and treatment is not yet recommended.`,
    }],
  }, {
    name: 'Treatment Recommended',
    range: {
      min: 20,
    },
    infos: [{
      name: 'Muskmelon Alternaria Leaf Blight',
      value: `Risk of infection is high and preventative treatment is recommended.`,
    }],
  }],
  notes: [
    'Plant disease risk models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'Muskmelon Alternaria Leaf Blight is caused by the fungus Alternaria cucumerina, and it can be very difficult to control once the disease has been established. Preventative spray treatments are recommend to prevent infection.',
    'The Muskmelon Alternaria Leaf Blight risk model, also known as Melcast, is used to determine interval length between spray treatments. The model calculates daily EFI (environmental favorability index) based on temperature and leaf wetness duration. When 20 EFIs have accumulated, treatment is recommended.',
    'Therefore, initial fungicide sprays on melons should occur between the time that plants are beginning to vine and the time that vines of adjacent plants within rows begin to make contact with each other.',
    'The model should be reset after each spray, in order to time the subsequent treatment.',
  ],
  citations: [
    'Everts, K. L., Korir, R. C., and Newark, M. J. 2016. Re-evaluation of MelCast for fungicide scheduling in mid-Atlantic watermelon. Plant Health Prog. 17:51-52.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-muskmelon-alternaria-leaf-blight-risk-model/',
  outputMatrix: {
    axisRanges: {
      x: { min: 12, max: 30 }, // Mean Temperature
      y: { min: 1, max: 24 }, // Leaf Wetness Hours
    },
    outputs: [ /*
       12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 */
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 1
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 2
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 3
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 4
      [0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 5
      [0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 6
      [0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 7
      [0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0], // 8
      [0, 0, 1, 1, 2, 2, 2, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0], // 9
      [0, 1, 1, 2, 2, 2, 2, 2, 2, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0], // 10
      [0, 1, 1, 2, 2, 3, 2, 2, 2, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0], // 11
      [0, 1, 2, 2, 3, 3, 3, 3, 2, 2, 1, 1, 1, 0, 0, 0, 0, 0, 0], // 12
      [0, 1, 2, 3, 3, 4, 4, 3, 3, 2, 1, 1, 1, 0, 0, 0, 0, 0, 0], // 13
      [0, 1, 2, 3, 4, 4, 4, 4, 3, 2, 2, 1, 1, 1, 0, 0, 0, 0, 0], // 14
      [0, 1, 2, 3, 4, 5, 5, 4, 3, 3, 2, 1, 1, 1, 0, 0, 0, 0, 0], // 15
      [0, 1, 3, 4, 5, 5, 5, 5, 4, 3, 2, 2, 1, 1, 1, 0, 0, 0, 0], // 16
      [1, 1, 3, 4, 5, 6, 6, 5, 4, 3, 3, 2, 1, 1, 1, 0, 0, 0, 0], // 17
      [1, 2, 3, 5, 6, 7, 7, 6, 5, 4, 3, 2, 1, 1, 1, 1, 0, 0, 0], // 18
      [1, 2, 3, 5, 6, 7, 7, 7, 5, 4, 3, 2, 2, 1, 1, 1, 0, 0, 0], // 19
      [1, 2, 4, 5, 7, 8, 8, 7, 6, 5, 4, 3, 2, 1, 1, 1, 1, 1, 1], // 20
      [1, 2, 4, 6, 7, 8, 8, 8, 7, 5, 4, 3, 2, 2, 1, 1, 1, 1, 1], // 21
      [1, 2, 4, 6, 8, 9, 9, 8, 7, 6, 4, 3, 2, 2, 1, 1, 1, 1, 1], // 22
      [1, 3, 5, 7, 8, 9, 9, 9, 8, 6, 5, 4, 3, 2, 2, 1, 1, 1, 1], // 23
      [1, 3, 5, 7, 8, 9, 10, 9, 8, 7, 5, 4, 3, 2, 2, 1, 1, 1, 1], // 24
    ],
  },
}
