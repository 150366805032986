import { GddModelDefine } from '../gdd'

export const gddImportedCabbageworm: GddModelDefine = {
  lowerThreshold: 9.4,
  upperThreshold: 50,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddImportedCabbageworm',
  name: 'Imported Cabbageworm',
  fullName: 'Imported Cabbageworm (Pieris rapae) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No larvae present.',
      range: {
        min: 0,
        max: 194,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have not yet emerged.',
        },
        {
          name: 'Adults',
          value: 'Adult moths have been seen in field.',
        },
      ],
    },
    {
      name: 'Larvae',
      range: {
        min: 195,
        max: 300,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have emerged and may be treated.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Imported Cabbageworm Degree Day Model is used to predict larvae emergence of the Imported Cabbageworm (Pieris rapae), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adult Moths are first observed in the field. The growing degree day model can then be used to predict larvae emergence which can be target by insecticide sprays.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-imported-cabbageworm-growing-degree-day-model/',
  citations: [
    `Tatchell, G. M. 1981. The effects of a granulosis virus infection and temperature on the food consumption of Pieris rapae (Lepidoptera: Pieridae). Entomophaga 26: 291-299.`,
  ],
}
