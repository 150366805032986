import { GddModelDefine } from '../gdd'

export const gddEasternTentCaterpillar: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddEasternTentCaterpillar',
  name: 'Eastern Tent Caterpillar',
  fullName: 'Eastern Tent Caterpillar (Malacosoma americanum) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Eggs Overwintering',
      range: {
        min: 0,
        max: 44,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not begun to hatch.',
        },
      ],
    },
    {
      name: 'Egg Hatch',
      range: {
        min: 45,
        max: 100,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
        {
          name: 'Larvae',
          value: 'Caterpillars are beginning to emerge.',
        },
      ],
    },
    {
      name: 'Larvae Present',
      range: {
        min: 101,
        max: 150,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and feeding.',
        },
      ],
    },
    {
      name: 'Tents Apparent',
      range: {
        min: 151,
        max: 450,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and feeding.',
        },
      ],
    },
    {
      name: 'Pupation',
      range: {
        min: 451,
        max: 550,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have begun pupation',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Eastern Tent Caterpillar Degree Day Model is used to predict life-stage developments of the Eastern Tent Caterpillar (Malacosoma americanum), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted by traps.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-eastern-tent-caterpillar-borer-growing-degree-day-model/',
  citations: [
    'Observations made by Michigan State University Pest Management extension, (https://www.canr.msu.edu/ipm/agriculture/christmas_trees/gdd_of_landscape_insects)',
  ],
}
