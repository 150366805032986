import { GddModelDefine } from '../gdd'

export const gddAzaleaWhitefly: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddAzaleaWhitefly',
  name: 'Azalea Whitefly',
  fullName: 'Azalea Whitefly (Pealius azaleae) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Emergence',
      range: {
        min: 0,
        max: 448,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered nymphs have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence from Overwintered Nymphs',
      range: {
        min: 449,
        max: 700,
      },
      infos: [
        {
          name: 'Adult',
          value: 'First adults from overwintered nymphs have begun to emerge.',
        },
        {
          name: 'Nymphs',
          value: 'Next generation nymphs may be present.',
        },
      ],
    },
    {
      name: 'End Adult Emergence from Overwintered Nymphs',
      range: {
        min: 701,
        max: 1250,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered nymphs have fully emerged.',
        },
        {
          name: 'Nymphs',
          value: 'Next generation nymphs may be present.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - First Generation',
      range: {
        min: 1251,
        max: 1500,
      },
      infos: [
        {
          name: 'Adult',
          value: 'First generation adults have begun to emerge.',
        },
        {
          name: 'Nymphs',
          value: 'Next generation nymphs may be present.',
        },
      ],
    },
    {
      name: 'End Adult Emergence - First Generation',
      range: {
        min: 1501,
        max: 2032,
      },
      infos: [
        {
          name: 'Adult',
          value: 'First generation adults have fully emerged.',
        },
        {
          name: 'Nymphs',
          value: 'Next generation nymphs may be present.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - Second Generation',
      range: {
        min: 2033,
        max: 2150,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Second generation adults have begun to emerge.',
        },
        {
          name: 'Nymphs',
          value: 'Next generation nymphs may be present.',
        },
      ],
    },
    {
      name: 'End Adult Emergence - Second Generation',
      range: {
        min: 2151,
        max: 2300,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Second generation adults have fully emerged.',
        },
        {
          name: 'Nymphs',
          value: 'Next generation nymphs may be present.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Azalea Whitefly Degree Day Model is used to predict development of life-cycle stages of Azalea Whitefly (Pealius azaleae), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults from overwintered nymphs as well as the emergence of the next generation of adults.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-azalea-whitefly-growing-degree-day-model/',
  citations: [
    'Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.',
    'Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.',
  ],
}
