import { GddModelDefine } from '../gdd'

export const gddPlumFruitMoth: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddPlumFruitMoth',
  name: 'Plum Fruit Moth',
  fullName: 'Plum Fruit Moth (Grapholita funebrana) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Adult Moth Flight',
    range: {
      min: 0,
      max: 18,
    },
    infos: [{
      name: 'Adults',
      value: `Peak of overwintered adult moths in flight.`,
    }],
  }, {
    name: 'First Generation Egg Hatch',
    range: {
      min: 19,
      max: 153,
    },
    infos: [{
      name: 'Adults',
      value: `Adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Peak of first generation egg hatch.`,
    }],
  }, {
    name: 'First Generation Larvae',
    range: {
      min: 154,
      max: 468,
    },
    infos: [{
      name: 'Larvae',
      value: `Peak of first generation larvae.`,
    }],
  }, {
    name: 'First Generation Pupae',
    range: {
      min: 469,
      max: 756,
    },
    infos: [{
      name: 'Pupae',
      value: `Peak of first generation pupae.`,
    }],
  }, {
    name: 'First Generation Adult Moth Flight',
    range: {
      min: 757,
      max: 774,
    },
    infos: [{
      name: 'Adults',
      value: `First generation adult moths in flight.`,
    }],
  }, {
    name: 'Second Generation Egg Laying',
    range: {
      min: 775,
      max: 909,
    },
    infos: [{
      name: 'Adults',
      value: `Adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Peak of second generation egg laying.`,
    }],
  }, {
    name: 'Second Generation Egg Hatch',
    range: {
      min: 910,
      max: 1224,
    },
    infos: [{
      name: 'Adults',
      value: `Adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Peak of second generation egg hatch.`,
    }],
  }, {
    name: 'Second Generation Larvae',
    range: {
      min: 1225,
      max: 1512,
    },
    infos: [{
      name: 'Larvae',
      value: `Peak of second generation larvae.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Plum Fruit Moth Growing Degree Day Model is used to predict life-stage developments of the Plum Fruit Moth (Grapholita funebrana) in order to improve pest management decisions.`,
    `For best results, pheromone traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Charmillot, P-J. R. Vallier and S. Tagini-Rosset. 1979. Plum fruit moth (Grapholitha funebrana Tr.): study of the life cycle in relation to the sums of temperature and considerations on the activity of the adult moths. Bulletin de la Societe Entomologique Suisse 52: 19-33.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-plum-fruit-moth-growing-degree-day-model',
}
