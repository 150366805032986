import { GddModelDefine } from '../gdd'

export const gddAnagrusEpos: GddModelDefine = {
  lowerThreshold: 45,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddAnagrusEpos',
  name: 'Anagrus Epos',
  fullName: 'Anagrus Epos Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs and Nymphs',
      range: {
        min: 0,
        max: 440,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged observed eggs or nymphs.',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 441,
        max: 486,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults have emerged.',
        },
      ],
    },
    {
      name: 'Next Generation Eggs',
      range: {
        min: 487,
        max: 550,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have begun next generation egg-laying.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Agagrus Epos Degree Day Model is used to predict development of life-cycle stages of the bio-control insect Anagrus Epos, in order to improve pest management practices.',
    'For best results, a biofix date should be set when Anagrus Epos eggs are first observed or introduced to field. The growing degree day model can then be used to predict emergence of adults and next generation eggs.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-anagrus-epos-growing-degree-day-model/',
  citations: [
    'Williams, D. W. 1984. Ecology of the blackberry-leafhopper-parasite system and its relevance to California grape agroecosystems. Hilgardia 52: 1-32.',
  ],
}
