import { GddModelDefine } from '../gdd'

export const gddArmyworm: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 29,
  cutoffMethod: 'vertical',

  group: 'gdd',
  type: 'gddArmyworm',
  name: 'Armyworm',
  fullName: 'Armyworm (Mythimna unipuncta) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [{
    name: 'Adult Moth Activity Pre Egg Laying',
    range: {
      min: 0,
      max: 70,
    },
    infos: [{
      name: 'Adults',
      value: `Adult moths present.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has not yet begun.`,
    }],
  }, {
    name: 'Adult Moth Activity and Egg Laying',
    range: {
      min: 71,
      max: 133,
    },
    infos: [{
      name: 'Adults',
      value: `Adult moths present.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }, {
    name: 'First Generation Larvae',
    range: {
      min: 134,
      max: 410,
    },
    infos: [{
      name: 'Larvae',
      value: `Peak of first generation larvae.`,
    }],
  }, {
    name: 'First Generation Pupae',
    range: {
      min: 411,
      max: 575,
    },
    infos: [{
      name: 'Pupae',
      value: `Peak of first generation pupae.`,
    }],
  }, {
    name: 'First Generation Adult Moths Pre Egg Laying',
    range: {
      min: 576,
      max: 638,
    },
    infos: [{
      name: 'Adults',
      value: `First generation adult moths present.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has not yet begun.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Armyworm Growing Degree Day Model is used to predict life-stage developments of the Armyworm (Mythimna unipuncta) in order to improve pest management decisions.`,
    `For best results, traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Guppy, J. C. 1969. Some effects of temperature on the immature stages of the armyworm, Pseudaletia unipuncta (Lepidoptera: Noctuidae), under controlled conditions. Can. Ent. 101: 1320-1327.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-armyworm-growing-degree-day-model',
}
