import { GddModelDefine } from '../gdd'

export const gddBandedAshClearwingBorer: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddBandedAshClearwingBorer',
  name: 'Banded Ash Clearwing Borer',
  fullName: 'Banded Ash Clearwing Borer (Podosesia syringae) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Adults Present',
      range: {
        min: 0,
        max: 1800,
      },
      infos: [
        {
          name: 'Adults',
          value: 'No adult moths present.',
        },
      ],
    },
    {
      name: 'Adult Flight and Egg-Laying',
      range: {
        min: 1801,
        max: 2200,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult moths in flight.',
        },
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
      ],
    },
    {
      name: 'Second Generation Larvae',
      range: {
        min: 2201,
        max: 3000,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and feeding.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Banded Ash Clearwing Borer Degree Day Model is used to predict life-stage developments of the Banded Ash Clearwing Borer (Podosesia syringae), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adult moths which can be targeted by traps.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-banded-ash-clearwing-borer-growing-degree-day-model/',
  citations: [
    'Herms,D.A. Using degree days and plant phenology to predict pest activity. Chapter 11. Tactics and Tools for IPM.',
  ],
}
