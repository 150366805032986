import { GddModelDefine } from '../gdd'

export const gddCodlingMoth: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 88,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddCodlingMoth',
  name: 'Codling Moth',
  fullName: 'Codling Moth (Cydia pomonella) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'First Generation Adult Moth Flight',
    range: {
      min: 0,
      max: 220,
    },
    infos: [{
      name: 'Adults',
      value: `First generation adult moths are in flight.`,
    }, {
      name: 'Eggs',
      value: `No eggs laid.`,
    }],
  }, {
    name: 'First Generation Beginning Egg Hatch',
    range: {
      min: 221,
      max: 340,
    },
    infos: [{
      name: 'Adults',
      value: `29% Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `1% Egg Hatch.`,
    }],
  }, {
    name: 'First Generation Early Egg Hatch',
    range: {
      min: 341,
      max: 460,
    },
    infos: [{
      name: 'Adults',
      value: `58% Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `2% Egg Hatch.`,
    }],
  }, {
    name: 'First Generation 10% Egg Hatch',
    range: {
      min: 461,
      max: 535,
    },
    infos: [{
      name: 'Adults',
      value: `83% Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `10% Egg Hatch.`,
    }],
  }, {
    name: 'First Generation 20% Egg Hatch',
    range: {
      min: 536,
      max: 655,
    },
    infos: [{
      name: 'Adults',
      value: `91% Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `20% Egg Hatch.`,
    }],
  }, {
    name: 'First Generation 50% Egg Hatch',
    range: {
      min: 656,
      max: 755,
    },
    infos: [{
      name: 'Adults',
      value: `97% Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `50% Egg Hatch.`,
    }],
  }, {
    name: 'First Generation 75% Egg Hatch',
    range: {
      min: 756,
      max: 920,
    },
    infos: [{
      name: 'Adults',
      value: `99% Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `75% Egg Hatch.`,
    }],
  }, {
    name: 'Second Generation Beginning Adult Flight',
    range: {
      min: 921,
      max: 980,
    },
    infos: [{
      name: 'Adults',
      value: `3% Second Generation Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `95% First Generation Egg Hatch.`,
    }],
  }, {
    name: 'Second Generation Early Adult Flight',
    range: {
      min: 981,
      max: 1160,
    },
    infos: [{
      name: 'Adults',
      value: `5% Second Generation Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `No Second Generation Eggs have been hatched.`,
    }],
  }, {
    name: 'Second Generation 5% Egg Hatch',
    range: {
      min: 1161,
      max: 1480,
    },
    infos: [{
      name: 'Adults',
      value: `18% Second Generation Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `5% Second Generation Egg Hatch.`,
    }],
  }, {
    name: 'Second Generation 30% Egg Hatch',
    range: {
      min: 1481,
      max: 1605,
    },
    infos: [{
      name: 'Adults',
      value: `74% Second Generation Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `30% Second Generation Egg Hatch.`,
    }],
  }, {
    name: 'Second Generation 50% Egg Hatch',
    range: {
      min: 1606,
      max: 1765,
    },
    infos: [{
      name: 'Adults',
      value: `89% Second Generation Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `50% Second Generation Egg Hatch.`,
    }],
  }, {
    name: 'Second Generation 75% Egg Hatch',
    range: {
      min: 1766,
      max: 1940,
    },
    infos: [{
      name: 'Adults',
      value: `96% Second Generation Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `75% Second Generation Egg Hatch.`,
    }],
  }, {
    name: 'Early Third Generation Adult Moth Flight',
    range: {
      min: 1941,
      max: 2120,
    },
    infos: [{
      name: 'Adults',
      value: `7% Third Generation Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `90% Second Generation Egg Hatch.`,
    }],
  }, {
    name: 'Third Generation Adult Moth Flight',
    range: {
      min: 2121,
      max: 2200,
    },
    infos: [{
      name: 'Adults',
      value: `35% Third Generation Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `97% Second Generation Egg Hatch.`,
    }],
  }, {
    name: 'Early Third Generation Egg Hatch',
    range: {
      min: 2201,
      max: 2300,
    },
    infos: [{
      name: 'Adults',
      value: `56% Third Generation Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `13% Third Generation Egg Hatch.`,
    }],
  }, {
    name: 'Third Generation Egg Hatch',
    range: {
      min: 2301,
      max: 2500,
    },
    infos: [{
      name: 'Adults',
      value: `79% Third Generation Adult Moth Flight.`,
    }, {
      name: 'Eggs',
      value: `56% Third Generation Egg Hatch.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Codling Moth Growing Degree Day Model is used to predict life-stage developments of the Codling Moth (Cydia pomonella) in order to improve pest management decisions.`,
    `For best results, pheromone traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays or for mating disruption pheromone application during adult flight stages.`,
  ],
  citations: [
    `Knight, A.L. 2007. Adjusting the phenology model of codling moth (Lepidoptera: Tortricidae) in Washington state apple orchards. Environmental Entomology 36:1485-1493`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-codling-moth-growing-degree-day-model',
}
