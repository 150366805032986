import { GddModelDefine } from '../gdd'

export const gddVegetableLeafminer: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 35,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddVegetableLeafminer',
  name: 'Vegetable Leafminer',
  fullName: 'Vegetable Leafminer (Liriomyza sativae) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Adults / Eggs  Observed',
      range: {
        min: 0,
        max: 40,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have been observed and eggs are present.',
        },
      ],
    },
    {
      name: '50% Egg Stage',
      range: {
        min: 41,
        max: 95,
      },
      infos: [
        {
          name: 'Larvae',
          value: '50% of Eggs have hatched and some larvae are present.',
        },
      ],
    },
    {
      name: '99% Larvae Emergence from Leaves',
      range: {
        min: 96,
        max: 130,
      },
      infos: [
        {
          name: 'Larvae',
          value: '99% of Larvae have emerged from leaves.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Vegetable Leafminer Degree Day Model is used to predict development of life-cycle stages of Vegetable Leafminer (Liriomyza sativae), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adults or eggs are first observed in the field. The growing degree day model can then be used to predict emergence of larvae from leaves.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-vegetable-leafminer-growing-degree-day-model/',
  citations: [
    'Petitt, F. L., J. C. Allen, and C. S. Barfield. 1991. Degree-day model for vegetable leafminer (Diptera: Agromyzidae) phenology. Environ. Entomol. 20: 1134-1140.',
  ],
}
