import { GddModelDefine } from '../gdd'

export const gddCitrusFlowering: GddModelDefine = {
  lowerThreshold: 49,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddCitrusFlowering',
  name: 'Citrus Flower Hardening',
  fullName: 'Citrus Flowering (Washington Navel Orange) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Flowers have not yet developed.',
      range: {
        min: 0,
        max: 536,
      },
      infos: [
        {
          name: 'Flowers',
          value: 'Flowers have not yet developed.',
        },
      ],
    },
    {
      name: '10% Anthesis',
      range: {
        min: 537,
        max: 766,
      },
      infos: [
        {
          name: 'Flowers',
          value: '10% of flower buds have opened.',
        },
      ],
    },
    {
      name: '75% Petal Fall',
      range: {
        min: 767,
        max: 900,
      },
      infos: [
        {
          name: 'Flowers',
          value: '75% of petals have fallen from flowers.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Citrus Flowering Degree Day Model is used to predict flowering of Washington Navel Orange in Southern California based on the weather in a given season.',
    'For best results, a biofix date should be set to January 29 for the Northern Hemisphere/ Southern California. The growing degree day model can then be used to predict beginning of flower development (anthesis) and later petal fall.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-citrus-flowering-growing-degree-day-model/',
  citations: [
    `Lovatt C.J., Streeter S.M., Minter T.C., O'connell N.V., Flaherty D.L., Freeman M.W., Goodell P.B. (1984) Phenology of flowering in Citrus sinensis [L.] Osbeck, cv. Washington Naval orange. Proceedings of the International Society of Citriculture 1, 186-190.`,
  ],
}
