import { GddModelDefine } from '../gdd'

export const gddCranberry: GddModelDefine = {
  lowerThreshold: 41,
  upperThreshold: 85,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddCranberry',
  name: 'Cranberry',
  fullName: 'Cranberry Growing Degree Days',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Tightbud',
    range: {
      min: 0,
      max: 249,
    },
    infos: [{
      name: 'Info',
      value: `Buds are tightly wrapped and are starting to become active.`,
    }],
  }, {
    name: 'Bud-Swell',
    range: {
      min: 250,
      max: 499,
    },
    infos: [{
      name: 'Info',
      value: `Buds have started to become more active and are starting to swell. Most buds still retain red color.`,
    }],
  }, {
    name: 'Cabbagehead',
    range: {
      min: 500,
      max: 599,
    },
    infos: [{
      name: 'Info',
      value: `Buds have lost most of the red pigmentation and significant swelling has occurred.`,
    }],
  }, {
    name: 'Bud-Break & Elongation',
    range: {
      min: 600,
      max: 699,
    },
    infos: [{
      name: 'Info',
      value: `New growth emerges, tips of uppermost leaves become visible. New leaves and flower bracts form as bud elongates.`,
    }],
  }, {
    name: 'Roughneck',
    range: {
      min: 700,
      max: 799,
    },
    infos: [{
      name: 'Info',
      value: `Stem elongates significantly. Flower buds and bracts are visible.`,
    }],
  }, {
    name: 'Hook',
    range: {
      min: 800,
      max: 1299,
    },
    infos: [{
      name: 'Info',
      value: `Flower pedicels elongate and begin to "hook" as flower buds droop.`,
    }],
  }, {
    name: 'Bloom',
    range: {
      min: 1300,
      max: 1699,
    },
    infos: [{
      name: 'Info',
      value: `Flowers open and bloom.`,
    }],
  }, {
    name: 'Fruit-Set',
    range: {
      min: 1700,
      max: 2500,
    },
    infos: [{
      name: 'Info',
      value: `Fruit forms.`,
    }],
  }],
  notes: [
    `Many crop stages will overlap and occur concurrently. The crop stage estimates given represent an estimate of what stage the majority of plants will be in. Different varieties will also have slightly different developmental thresholds.`,
  ],
  description: [
    `The Cranberry Growing Degree Day Crop Model is used to predict phenological stages of the cranberry crop as it develops.`,
  ],
  citations: [
    `Chasen, Elissa and Steffan, Shawn. "Linking Growing Degree Days and Cranberry Plant Phenology". Cranberry Crop Management Journal. University of Wisconsin- Extension. Volume XXX. Issue III. May 22, 2017.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-cranberry-plant-phenology-growing-degree-day-model',
}
