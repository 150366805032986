import { GddModelDefine } from '../gdd'

export const gddCucumberSupremo: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 90,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddCucumberSupremo',
  name: 'Cucumber (Supremo)',
  fullName: 'Cucumber (Supremo) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Planting',
      range: {
        min: 0,
        max: 366,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Leaves have not yet emerged.',
        },
      ],
    },
    {
      name: '2 True Leaves',
      range: {
        min: 367,
        max: 677,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'First 2 true leaves have emerged.',
        },
      ],
    },
    {
      name: 'Early Flowering',
      range: {
        min: 678,
        max: 981,
      },
      infos: [
        {
          name: 'Flowering',
          value: 'Flowering has begun.',
        },
      ],
    },
    {
      name: 'First Harvest',
      range: {
        min: 982,
        max: 1200,
      },
      infos: [
        {
          name: 'Harvest',
          value: 'Harvest has begun (8" fruit).',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Cucumber Degree Day Model is used to predict harvest time of cucumber based on the weather in a given season.',
    `For best results, a biofix date should be set at planting time. If plant is transplanted rather than seeded, the '2 True Leaves' stage should be selected as the biofix starting date. The growing degree day model can then be used to predict harvest time.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-cucumber-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
  ],
}
