import {
  tomCastModelCommon,
  TomCastModelDefine,
} from './tom-cast'

export const tomCastAsparagusStemphyliumLeafSpot: TomCastModelDefine = {
  ...tomCastModelCommon,
  type: 'tomCastAsparagusStemphyliumLeafSpot',
  name: 'Asparagus Stemphylium Leaf Spot',
  fullName: 'Asparagus Stemphylium Leaf Spot',
  stages: [{
    name: 'Treatment Not Recommended',
    range: {
      min: 0,
      max: 19,
    },
    infos: [{
      name: 'Asparagus Stemphylium Leaf Spot',
      value: `Risk of infection is low and treatment is not yet recommended.`,
    }],
  }, {
    name: 'Treatment Recommended - Susceptible Varieties',
    range: {
      min: 20,
    },
    infos: [{
      name: 'Asparagus Stemphylium Leaf Spot',
      value: `Risk of infection is high and preventative treatment is recommended.`,
    }],
  }],
  notes: [
    'Plant disease risk models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'Asparagus Stemphylium Leaf Spot is caused by the fungus Stemphylium vesicarium, and it can be very difficult to control once the disease has been established. Preventative spray treatments are recommend to prevent infection.',
    'The Asparagus Stemphylium Leaf Spot risk model, is a modified verstion of Tomcast, and is used to determine interval length between spray treatments. The model calculates daily DSV (Disease Severity Value) based on temperature and leaf wetness duration. When 20 DSVs have accumulated, treatment is recommended.',
    'Model should be first used after first cladophyll emergence. Treatment should continue until 7-10 days before harvest.',
    'The model should be reset after each spray, in order to time the subsequent treatment.',
  ],
  citations: [
    'Pitblado, R. E. 1992. The development and implementation of TOM-CAST: A weather-timed fungicide spray program for field tomatoes. Ministry of Agriculture and Food, Ontario, Canada.',
    'Madden L., Pennypacker, S. P., and McNab, A. A. 1978. FAST, a forecast system for Alternaria solani on tomato. Phytopathology 68:1354-1358.',
    'Foster, J.; McDonald, M. Evaluation of the TOM-CAST Forecasting model in Asparagus for management of Stemphylium leaf spot in Ontario, Canada. Plant Dis. 2018, 102, 2253–2257.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-asparagus-stemphylium-leaf-spot-risk-model/',
}
