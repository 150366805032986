import { GddModelDefine } from '../gdd'

export const gddStrawberryRootWeevilPeppermint: GddModelDefine = {
  lowerThreshold: 48,
  upperThreshold: 103,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddStrawberryRootWeevilPeppermint',
  name: 'Strawberry Root Weevil (Peppermint)',
  fullName: 'Strawberry Root Weevil (Otiorhynchus ovatus) on Peppermint Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Larvae Emergence',
      range: {
        min: 0,
        max: 225,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Overwintered larvae have not emerged from diapause.',
        },
      ],
    },
    {
      name: 'Overwintered Larvae Emergence',
      range: {
        min: 226,
        max: 375,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Overwintered larvae have begun to emerge from diapause.',
        },
      ],
    },
    {
      name: 'Pupal Development Begins',
      range: {
        min: 376,
        max: 500,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Overwintered larvae have begun pupation.',
        },
      ],
    },
    {
      name: 'Teneral Adult Development Begins',
      range: {
        min: 501,
        max: 700,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Teneral adults development has begun.',
        },
      ],
    },
    {
      name: 'Peak Adult Emergence',
      range: {
        min: 701,
        max: 950,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered larvae have reached peak emergence.',
        },
      ],
    },
    {
      name: 'Peak Egg-Laying',
      range: {
        min: 951,
        max: 1200,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered larvae have reached peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak Egg Hatch',
      range: {
        min: 1201,
        max: 1300,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have reach peak hatch.',
        },
        {
          name: 'Larvae',
          value: 'Next generation larvae have emerged.',
        },
      ],
    },
    {
      name: 'Egg Hatch Nearly Complete',
      range: {
        min: 1301,
        max: 1400,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Egg hatch is nearly complete.',
        },
        {
          name: 'Larvae',
          value: 'Next generation larvae have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Strawberry Root Weevil Degree Day Model is used to predict development of life-cycle stages of the Strawberry Root Weevil (Otiorhynchus ovatus), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict adult emergence.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-strawberry-root-weevil-growing-degree-day-model/',
  citations: [
    'Cacka J. F. 1982. Biology, distribution and economic threshold of the strawberry root weevil, Oriorhynchus ovatus (L.), in peppermint. M. S. Thesis, Dept. Entomology, Oregon State University.',
  ],
}
