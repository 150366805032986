import { GddModelDefine } from '../gdd'

export const gddBluegrass: GddModelDefine = {
  lowerThreshold: 13,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddBluegrass',
  name: 'Bluegrass',
  fullName: 'Bluegrass (Poa annua) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Plant Emerged/ Not Yet Peak Seed',
      range: {
        min: 0,
        max: 363,
      },
      infos: [
        {
          name: 'Seeds',
          value: 'Plants may have emerged, but are not yet in peak seed production.',
        },
      ],
    },
    {
      name: 'Begin Peak Seedhead Production',
      range: {
        min: 364,
        max: 432,
      },
      infos: [
        {
          name: 'Seeds',
          value: 'Peak seedhead production has begun.',
        },
      ],
    },
    {
      name: 'End Peak Seedhead Production',
      range: {
        min: 433,
        max: 450,
      },
      infos: [
        {
          name: 'Seeds',
          value: 'Peak seedhead production has ended.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Bluegrass Degree Day Model is used to predict seed production timing of Bluegrass (Poa annua) based on the weather in a given season.',
    'For best results, a biofix date should be set to April 1st for the Northern Hemisphere. The growing degree day model can then be used to predict seed production timing.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-bluegrass-growing-degree-day-model/',
  citations: [
    `Danneberger, T. K., and J. M. Vargas, Jr. 1984. Annual Bluegrass Seedhead Emergence as Predicted by Degree-day Accumulation. Agronomy Journal 76:756-758.tshade (Solanum nigrum and S. ptycanthum) growth using degree-days. Weed Science 40: 86-89.`,
  ],
}
