import { GddModelDefine } from '../gdd'

export const gddAsparagusBeetle: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 86,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddAsparagusBeetle',
  name: 'Asparagus Beetle',
  fullName: 'Asparagus Beetle (Crioceris asparagi) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Adult Beetle Activity',
    range: {
      min: 0,
      max: 104,
    },
    infos: [{
      name: 'Adults',
      value: `Adult beetles present.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }, {
    name: 'First Generation Larvae',
    range: {
      min: 105,
      max: 406,
    },
    infos: [{
      name: 'Larvae',
      value: `Peak of first generation larvae.`,
    }],
  }, {
    name: 'First Generation Pupae',
    range: {
      min: 407,
      max: 572,
    },
    infos: [{
      name: 'Pupae',
      value: `Peak of first generation pupae.`,
    }],
  }, {
    name: 'First Generation Adult Beetles',
    range: {
      min: 573,
      max: 676,
    },
    infos: [{
      name: 'Adults',
      value: `First generation adult beetles present.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Asparagus Beetle Growing Degree Day Model is used to predict life-stage developments of the Asparagus Beetle (Crioceris asparagi) in order to improve pest management decisions.`,
    `For best results, the biofix should be adjusted to represent the first date that adult beetles have been observed consistently.`,
  ],
  citations: [
    `Taylor, R. G., and D. G. Harcourt. 1978. Effects of temperature on developmental rate of the immature stages of Crioceris asparagi (Coleoptera: Chrysomelidae). Can. Ent. 110: 57-62.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-asparagus-beetle-growing-degree-day-model',
}
