import {
  tomCastModelCommon,
  TomCastModelDefine,
} from './tom-cast'

export const tomCastCarrotAlternariaLeafBlight: TomCastModelDefine = {
  ...tomCastModelCommon,
  type: 'tomCastCarrotAlternariaLeafBlight',
  name: 'Carrot Alternaria Leaf Blight',
  fullName: 'Carrot Alternaria Leaf Blight',
  stages: [{
    name: 'Treatment Not Recommended',
    range: {
      min: 0,
      max: 19,
    },
    infos: [{
      name: 'Carrot Alternaria Leaf Blight',
      value: `Risk of infection is low and treatment is not yet recommended.`,
    }],
  }, {
    name: 'Treatment Recommended - Susceptible Varieties',
    range: {
      min: 20,
    },
    infos: [{
      name: 'Carrot Alternaria Leaf Blight',
      value: `Risk of infection is high and preventative treatment is recommended.`,
    }],
  }],
  notes: [
    'Plant disease risk models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'Carrot Alternaria Leaf Blight is caused by the fungus Alternaria dauci, and it can be very difficult to control once the disease has been established. Preventative spray treatments are recommend to prevent infection.',
    'The Carrot Alternia Leaf Blight risk model, is a modified verstion of Tomcast, and is used to determine interval length between spray treatments. The model calculates daily DSV (Disease Severity Value) based on temperature and leaf wetness duration. When 20 DSVs have accumulated, treatment is recommended.',
    'Model should be first used after July 5 (in the Northern Hemisphere), or after 35 DSVs have accumulated. Treatment should continue until 7-10 days before harvest.',
    'The model should be reset after each spray, in order to time the subsequent treatment.',
  ],
  citations: [
    'Pitblado, R. E. 1992. The development and implementation of TOM-CAST: A weather-timed fungicide spray program for field tomatoes. Ministry of Agriculture and Food, Ontario, Canada.',
    'Madden L., Pennypacker, S. P., and McNab, A. A. 1978. FAST, a forecast system for Alternaria solani on tomato. Phytopathology 68:1354-1358.',
    'Bolkan, H. A., and Reinert, W. R. 1994. Developing and implementing IPM strategies to assist farmers: an industry approach. Plant Dis. 78:545-550.'
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-carrot-alternaria-leaf-blight-risk-model/',
}
