import { GddModelDefine } from '../gdd'

export const gddDouglasFirNeedleMidge: GddModelDefine = {
  lowerThreshold: 39,
  upperThreshold: 110,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddDouglasFirNeedleMidge',
  name: 'Douglas-Fir Needle Midge',
  fullName: 'Douglas-Fir Needle Midge (Contarinia pseudotsugae) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Emergence from Overwintering',
      range: {
        min: 0,
        max: 435,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults are not yet present.',
        },
      ],
    },
    {
      name: 'Pre-Adult Emergence, Approximate Trap Time',
      range: {
        min: 436,
        max: 581,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults will start to emerge, traps should be implemented.',
        },
      ],
    },
    {
      name: '5% Adult Trap Catch',
      range: {
        min: 582,
        max: 758,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have begun to emerge.',
        },
      ],
    },
    {
      name: '50% Adult Trap Catch',
      range: {
        min: 759,
        max: 927,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have reached peak activity.',
        },
      ],
    },
    {
      name: '95% Adult Trap Catch',
      range: {
        min: 928,
        max: 1000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have reached peak activity.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Douglas-Fir Needle Midge Degree Day Model is used to predict development of life-cycle stages of the Douglas-Fir Needle Midge (Contarinia pseudotsugae), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict peak spring trap catch.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-douglas-fir-needle-midge-growing-degree-day-model/',
  citations: [
    `West, K., Deangelis, J., B. Simko. 1991. The Biology and Control of the Douglas-fir Needle Midge in Christmas Trees. OR St. Ext. Serv. EC 1373`,
  ],
}
