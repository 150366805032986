import { GddModelDefine } from '../gdd'

export const gddJapaneseKnotweedPsyllid: GddModelDefine = {
  lowerThreshold: 43,
  upperThreshold: 95,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddJapaneseKnotweedPsyllid',
  name: 'Japanese Knotweed Psyllid',
  fullName: 'Japanese Knotweed Psyllid (Aphalara itadori) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Pre-Emergence',
      range: {
        min: 0,
        max: 420,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Overwintered Adult Emergence',
      range: {
        min: 421,
        max: 551,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have begun to emerge.',
        },
      ],
    },
    {
      name: '2% Overwintered Adult Egg-Laying',
      range: {
        min: 552,
        max: 799,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have reached 2% egg-laying.',
        },
      ],
    },
    {
      name: '20% Overwintered Adult Egg-Laying',
      range: {
        min: 800,
        max: 1596,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have reached 20% egg-laying.',
        },
      ],
    },
    {
      name: '90% Overwintered Adult Egg-Laying',
      range: {
        min: 1597,
        max: 2167,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults have reached 90% egg-laying.',
        },
      ],
    },
    {
      name: '2% First Generation Adult Egg-Laying / Peak Nymphs',
      range: {
        min: 2168,
        max: 2415,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults have reached 2% egg-laying.',
        },
        {
          name: 'Nymphs',
          value: 'Peak first generation nymphs.',
        },
      ],
    },
    {
      name: '20% First Generation Adult Egg-Laying',
      range: {
        min: 2416,
        max: 3212,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults have reached 20% egg-laying.',
        },
      ],
    },
    {
      name: '90% First Generation Adult Egg-Laying',
      range: {
        min: 3213,
        max: 3783,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults have reached 90% egg-laying.',
        },
      ],
    },
    {
      name: '2% Second Generation Adult Egg-Laying / Peak Nymphs',
      range: {
        min: 3784,
        max: 4031,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have reached 2% egg-laying.',
        },
        {
          name: 'Nymphs',
          value: 'Peak second generation nymphs.',
        },
      ],
    },
    {
      name: '20% Second Generation Adult Egg-Laying',
      range: {
        min: 4032,
        max: 4828,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have reached 20% egg-laying.',
        },
      ],
    },
    {
      name: '90% Second Generation Adult Egg-Laying',
      range: {
        min: 4829,
        max: 5399,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have reached 90% egg-laying.',
        },
      ],
    },
    {
      name: '2% Third Generation Adult Egg-Laying / Peak Nymphs',
      range: {
        min: 5400,
        max: 5647,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Third generation adults have reached 2% egg-laying.',
        },
        {
          name: 'Nymphs',
          value: 'Peak third generation nymphs.',
        },
      ],
    },
    {
      name: '20% Third Generation Adult Egg-Laying',
      range: {
        min: 5648,
        max: 6444,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Third generation adults have reached 20% egg-laying.',
        },
      ],
    },
    {
      name: '90% Third Generation Adult Egg-Laying',
      range: {
        min: 6445,
        max: 7015,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Third generation adults have reached 90% egg-laying.',
        },
      ],
    },
    {
      name: '2% Fourth Generation Adult Egg-Laying / Peak Nymphs',
      range: {
        min: 7016,
        max: 7263,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Fourth generation adults have reached 2% egg-laying.',
        },
        {
          name: 'Nymphs',
          value: 'Peak fourth generation nymphs.',
        },
      ],
    },
    {
      name: '20% Fourth Generation Adult Egg-Laying',
      range: {
        min: 7264,
        max: 8060,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Fourth generation adults have reached 20% egg-laying.',
        },
      ],
    },
    {
      name: '90% Fourth Generation Adult Egg-Laying',
      range: {
        min: 8061,
        max: 8200,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Fourth generation adults have reached 90% egg-laying.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Japanese Knotweed Psyllid Degree Day Model is used to predict development of life-cycle stages of the bio-control insect Japanese Knotweed Psyllid (Aphalara itadori), in order to improve pest management practices.',
    'For best results, a biofix date should be set to January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of overwintered adults and adult emergence of subsequent generations.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-japanese-knotweed-psyllid-growing-degree-day-model/',
  citations: [
    'Myint, Y.Y., K. Nakahira, M. Takagi, N. Furuya, R.H. Shaw. 2012. Using life-history parameters and a degree-day model to predict climate suitability in',
  ],
}
