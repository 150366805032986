import { GddModelDefine } from '../gdd'

export const gddBlackCutworm: GddModelDefine = {
  lowerThreshold: 10.4,
  upperThreshold: 30,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddBlackCutworm',
  name: 'Black Cutworm',
  fullName: 'Black Cutworm (Agrotis ipsilon) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [{
    name: 'Adult Moth Flight and Egg Laying',
    range: {
      min: 0,
      max: 52,
    },
    infos: [{
      name: 'Adults',
      value: `Adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }, {
    name: 'First Generation Larvae',
    range: {
      min: 53,
      max: 405,
    },
    infos: [{
      name: 'Larvae',
      value: `Peak of first generation larvae.`,
    }],
  }, {
    name: 'First Generation Pupae',
    range: {
      min: 406,
      max: 643,
    },
    infos: [{
      name: 'Pupae',
      value: `Peak of first generation pupae.`,
    }],
  }, {
    name: 'First Generation Adult Moth Flight',
    range: {
      min: 644,
      max: 695,
    },
    infos: [{
      name: 'Adults',
      value: `First generation adult moths in flight.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Black Cutworm Growing Degree Day Model is used to predict life-stage developments of the Black Cutworm (Agrotis ipsilon) in order to improve pest management decisions.`,
    `For best results, pheromone traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Luckmann, W. H., J. T. Shaw, D. W. Sherrod and W. G. Ruesink. 1976. Developmental rate of the black cutworm. J. Econ. Entomol. 69: 386-388.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-black-cutworm-growing-degree-day-model',
}
