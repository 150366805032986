import { GddModelDefine } from '../gdd'

export const gddBlackVineWeevil: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddBlackVineWeevil',
  name: 'Black Vine Weevil',
  fullName: 'Black Vine Weevil (Otiorhynchus sulcatus) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Emergence',
      range: {
        min: 0,
        max: 148,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered larvae have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence from Overwintered Larvae',
      range: {
        min: 149,
        max: 400,
      },
      infos: [
        {
          name: 'Adult',
          value: 'First adults from overwintered larvae have begun to emerge.',
        },
      ],
    },
    {
      name: 'End Adult Emergence',
      range: {
        min: 401,
        max: 500,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults have fully emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Black Vine Weevil Growing Degree Day Model is used to predict development of life-cycle stages of Black Vine Weevil (Otiorhynchus sulcatus), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults from overwintered larvae which can be targeted for treatment.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-black-vine-weevil-growing-degree-day-model/',
  citations: [
    'Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.',
    'Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.',
  ],
}
