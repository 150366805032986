import { GddModelDefine } from '../gdd'

export const gddBean5630: GddModelDefine = {
  lowerThreshold: 40,
  upperThreshold: 90,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddBean5630',
  name: 'Bean (5630)',
  fullName: 'Bean (5630) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Planting',
      range: {
        min: 0,
        max: 601,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Leaves have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Trifoliate Leaves',
      range: {
        min: 602,
        max: 1148,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'First trifoliate leaves have emerged.',
        },
      ],
    },
    {
      name: 'First Open Flowers',
      range: {
        min: 1149,
        max: 1630,
      },
      infos: [
        {
          name: 'Flowering',
          value: 'Flowering has begun.',
        },
      ],
    },
    {
      name: 'First Harvest',
      range: {
        min: 1631,
        max: 1800,
      },
      infos: [
        {
          name: 'Harvest',
          value: 'Harvest has begun.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Bean Degree Day Model is used to predict harvest time of beans based on the weather in a given season.',
    `For best results, a biofix date should be set at planting time. If plant is transplanted rather than seeded, the 'First trifoliate' stage should be selected as the biofix starting date. The growing degree day model can then be used to predict harvest time.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-bean-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
  ],
}
