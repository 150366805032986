import { gddCornLeafAphid } from './gdd-corn-leaf-aphid'

export const gddCornLeafAphidApterous = gddCornLeafAphid.variety('Apterous', {
  lowerThreshold: 42.9,
  upperThreshold: 79.3,
  cutoffMethod: 'horizontal',

  type: 'gddCornLeafAphidApterous',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Eggs',
    range: {
      min: 0,
      max: 163,
    },
    infos: [{
      name: 'Eggs',
      value: `Most aphids are in the egg stage.`,
    }],
  }, {
    name: 'Adults Pre Egg Laying',
    range: {
      min: 164,
      max: 179,
    },
    infos: [{
      name: 'Adults',
      value: `Most aphids are adults.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has not yet begun.`,
    }],
  }, {
    name: 'Adults Egg Laying',
    range: {
      min: 180,
      max: 343,
    },
    infos: [{
      name: 'Adults',
      value: `Most aphids are adults.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Corn Leaf Aphid Growing Degree Day Model is used to predict life-stage developments of the Corn Leaf Aphid (Rhopalosiphum maidis) in order to improve pest management decisions.`,
  ],
  citations: [
    `Elliott, N. C., R. W. Kieckhefer, and D. D. Walgenbach. 1988. Effects of constant and fluctuating temperature on development rates and demographic statistics for the corn leaf aphid (Homoptera: Aphididae). J. Econ. Entomol. 81: 1383-1389.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-corn-leaf-aphid-growing-degree-day-model',
})
