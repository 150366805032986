import { GddModelDefine } from '../gdd'

export const gddWesternPineShootBorer: GddModelDefine = {
  lowerThreshold: 42,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddWesternPineShootBorer',
  name: 'Western Pine Shoot Borer',
  fullName: 'Western Pine Shoot Borer (Eucosoma sonomana) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Emergence from Overwintering',
      range: {
        min: 0,
        max: 90,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered pupae have not begun flight.',
        },
      ],
    },
    {
      name: 'First Moth Flight - Adults from Overwintered Pupae',
      range: {
        min: 91,
        max: 225,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae have begun flight and laying eggs.',
        },
      ],
    },
    {
      name: '25% Moth Flight / Egg-Laying',
      range: {
        min: 226,
        max: 306,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae are in flight and laying eggs.',
        },
      ],
    },
    {
      name: '50% Moth Flight / First Larvae',
      range: {
        min: 307,
        max: 396,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae are in flight and laying eggs.',
        },
        {
          name: 'Larvae',
          value: 'First larvae have begun to mine terminals.',
        },
      ],
    },
    {
      name: '75% Moth Flight / First Instar Larvae in Terminals',
      range: {
        min: 397,
        max: 630,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae are in flight and laying eggs.',
        },
        {
          name: 'Larvae',
          value: 'First instar larvae are in terminals.',
        },
      ],
    },
    {
      name: 'Moth Flight Ends / First-Third Instar Larvae in Terminals',
      range: {
        min: 631,
        max: 900,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Moth flight has ended.',
        },
        {
          name: 'Larvae',
          value: 'First-third instar larvae are in terminals.',
        },
      ],
    },
    {
      name: 'Second-Fifth Instar Larvae in Terminals',
      range: {
        min: 901,
        max: 1170,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Moth flight has ended.',
        },
        {
          name: 'Larvae',
          value: 'Second-fifth instar larvae are in terminals.',
        },
      ],
    },
    {
      name: 'Third-Fifth Instar Larvae in Terminals - First Larvae Emergence',
      range: {
        min: 1171,
        max: 1440,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Third-fifth instar larvae are in terminals. First larvae have begun to emerge from terminals.',
        },
      ],
    },
    {
      name: '90% Larvae Emergence from Terminals',
      range: {
        min: 1441,
        max: 1710,
      },
      infos: [
        {
          name: 'Larvae',
          value: '90% larvae have emerged from terminals.',
        },
      ],
    },
    {
      name: 'End Larvae Emergence from Terminals',
      range: {
        min: 1711,
        max: 1800,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'All larvae have emerged from terminals.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Western Pine Shoot Borer Degree Day Model is used to predict development of life-cycle stages of Western Pine Shoot Borer (Eucosoma sonomana), in order to improve pest management decisions',
    'For best results, a biofix date should be set for March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict adult emergence and egg laying and larvae emergence of the following generations.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-western-pine-shoot-borer-growing-degree-day-model/',
  citations: [
    'Mitchell, R. G., and L. L. Sower. 1988. Phenology and heat accumulation as field guides for the seasonal history of the western pine shoot borer (Lepidoptera: Olethreutidae).',
  ],
}
