import { GddModelDefine } from '../gdd'

export const gddAppleMaggotEmergence: GddModelDefine = {
  lowerThreshold: 44.1,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddAppleMaggotEmergence',
  name: 'Apple Maggot',
  fullName: 'Apple Maggot (Rhagoletis pomonella) Emergence Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Pre-Emergence',
    range: {
      min: 0,
      max: 1456,
    },
    infos: [{
      name: 'Adults',
      value: `Adults have not yet emerged.`,
    }],
  }, {
    name: 'Adult Emergence',
    range: {
      min: 1457,
      max: 2457,
    },
    infos: [{
      name: 'Adults',
      value: `Adults have begun to emerge.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Apple Maggot Emergence Growing Degree Day Model is used to predict the spring emergence of Apple Maggot (Rhagoletis pomonella) in order to prepare for setting sticky traps or beginning a spray program.`,
  ],
  citations: [
    `Jones, V. P., D. W. Davis, S. L. Smith, and D. B. Allred. 1989. Phenology of apple maggot, Rhagoletis pomonella (Diptera: Tephritidae) associated with cherry and hawthorn in Utah. J. Econ. Entomol. 82: 788-782.`,
    `Jones, V. P., S. L. Smith, and D. W. Davis. 1990. Comparing apple maggot adult phenology in eastern and western North America. IN: Dowell, R. V., L. T. Wilson, and V. P. Jones (eds), Apple maggot in the west, history, biology and control. University of California Division of Agriculture and Natural Resources. Publication #3341.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-apple-maggot-emergence-growing-degree-day-model/',
}
