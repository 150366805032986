import { GddModelDefine } from '../gdd'

export const gddBalsamGallMidge: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddBalsamGallMidge',
  name: 'Balsam Gall Midge',
  fullName: 'Balsam Gall Midge (Paradiplosis tumifex) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Adults Present',
      range: {
        min: 0,
        max: 150,
      },
      infos: [
        {
          name: 'Adults',
          value: 'No adult midges present.',
        },
      ],
    },
    {
      name: 'Adult Egg-Laying',
      range: {
        min: 151,
        max: 300,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult midges have emerged',
        },
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
      ],
    },
    {
      name: 'First Generation Larvae',
      range: {
        min: 300,
        max: 550,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae and present and feeding.',
        },
      ],
    },
    {
      name: 'Galls Apparent',
      range: {
        min: 551,
        max: 700,
      },
      infos: [
        {
          name: 'Galls',
          value: 'Galls caused by Midge should be visible.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Balsam Gall Midge Degree Day Model is used to predict life-stage developments of the Balsam Gall Midge (Paradiplosis tumifex), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict egg hatch and emergence of larvae which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-balsam-gall-midge-growing-degree-day-model/',
  citations: [
    'Observations made by Michigan State University Pest Management extension, (https://www.canr.msu.edu/ipm/agriculture/christmas_trees/gdd_of_landscape_insects)',
  ],
}
