import { GddModelDefine } from '../gdd'

export const gddEgyptianCottonWorm: GddModelDefine = {
  lowerThreshold: 53,
  upperThreshold: 95,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddEgyptianCottonWorm',
  name: 'Egyptian Cotton Worm',
  fullName: 'Egyptian Cotton Worm (Spodoptera littoralis) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Emergence from Overwintering',
      range: {
        min: 0,
        max: 432,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered larvae have not begun flight.',
        },
      ],
    },
    {
      name: 'First Flight Adults from Overwintered Larvae',
      range: {
        min: 433,
        max: 709,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have begun flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - Adults from Overwintered Larvae',
      range: {
        min: 710,
        max: 798,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae are in peak flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'Peak Egghatch- 1st Generation',
      range: {
        min: 799,
        max: 1264,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae are in flight and egg-laying is in peak.',
        },
        {
          name: 'Larvae',
          value: 'First generation larvae have emerged.',
        },
      ],
    },
    {
      name: 'First Flight - 1st Generation Adults',
      range: {
        min: 1265,
        max: 1565,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from first generation larvae have begun flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - 1st Generation Adults',
      range: {
        min: 1566,
        max: 2422,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults are in peak flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'Peak Flight - 2nd Generation',
      range: {
        min: 2423,
        max: 3278,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - 3rd Generation',
      range: {
        min: 3279,
        max: 4135,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Third generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - 4th Generation',
      range: {
        min: 4136,
        max: 4991,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Fourth generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - 5th Generation',
      range: {
        min: 4991,
        max: 5100,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Fifth generation adults are in peak flight.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Egyptian Cottown Worm Degree Day Model is used to predict development of life-cycle stages of Egyptian Cottown Worm (Spodoptera littoralis), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence peak flight of the following generations.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-egyptian-cotton-worm-growing-degree-day-model/',
  citations: [
    `Baker, C.R.B. and G.W. Miller. 1974. Some effects of temperature and larval food on the development of Spodoptera littoralis. (Lep., Noctuidae). Bull. Entom. Res. 63:495-51.`,
  ],
}
