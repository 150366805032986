import { GddModelDefine } from '../gdd'

export const gddSunflower: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddSunflower',
  name: 'Sunflower',
  fullName: 'Sunflower (early dward hybrids) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Seed Planted',
      range: {
        min: 0,
        max: 305,
      },
      infos: [
        {
          name: 'Shoot Break',
          value: 'Shoot has not broken through soil.',
        },
      ],
    },
    {
      name: 'Shoot Break through soil.',
      range: {
        min: 306,
        max: 372,
      },
      infos: [
        {
          name: 'Shoot Break',
          value: 'Shoot has broken through the soil.',
        },
      ],
    },
    {
      name: 'First true leaf unfolds.',
      range: {
        min: 373,
        max: 1049,
      },
      infos: [
        {
          name: 'First leaf.',
          value: 'First true leaf has unfolded.',
        },
      ],
    },
    {
      name: 'Internode elongates.',
      range: {
        min: 1050,
        max: 1726,
      },
      infos: [
        {
          name: 'Internode.',
          value: 'Internode directly below base of inflorescence elongates .5 to 2 cm above nearest leaf.',
        },
      ],
    },
    {
      name: 'Inflorescence Begins to Open',
      range: {
        min: 1727,
        max: 1997,
      },
      infos: [
        {
          name: 'Inflorescence',
          value: 'Inflorescence begins to open, small ray florets just visible.',
        },
      ],
    },
    {
      name: 'Flower buds in head just visible.',
      range: {
        min: 1998,
        max: 2200,
      },
      infos: [
        {
          name: 'Flower bud',
          value: 'Most flower buds in head visible, bud not in anthesis. Ray florets have visible petals.',
        },
      ],
    },
    {
      name: '50% of Flowers on Head Visible.',
      range: {
        min: 2201,
        max: 3012,
      },
      infos: [
        {
          name: 'Flowers',
          value: '50% of flowers on head visible.',
        },
      ],
    },
    {
      name: 'Thresh when seed moisture is 12 to 15%.',
      range: {
        min: 3013,
        max: 3113,
      },
      infos: [
        {
          name: 'Seeds',
          value: 'Thresh when seed moisture is 12 to 15%.',
        },
      ],
    },
    {
      name: 'Brachts Yellow-Brown.',
      range: {
        min: 3114,
        max: 3283,
      },
      infos: [
        {
          name: 'Brachts',
          value: 'Brachts become yellow and brown on back of head.',
        },
      ],
    },
    {
      name: 'Head Dry for Threshing',
      range: {
        min: 3283,
        max: 3400,
      },
      infos: [
        {
          name: 'Head',
          value: 'Head completely dried for threshing.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Sunflower Degree Day Model is used to predict harvest time and other growth stages of early dward hybrids of Sunflower based on the weather in a given season.',
    'For best results, a biofix date should be set at planting. The growing degree day model can then be used to predict harvest time. For best results the biofix should be reset when later stages have been observed, in order to more accurately predict the next stage.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-sunflower-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
    `Stu Brandt, Scott, SK 1993-97 and Perry Miller, Swift Current, SK 1995-98`,
  ],
}
