import { GddModelDefine } from '../gdd'

export const gddSanJoseScaleEmergence: GddModelDefine = {
  lowerThreshold: 51,
  upperThreshold: 90,
  cutoffMethod: 'vertical',

  group: 'gdd',
  type: 'gddSanJoseScaleEmergence',
  name: 'San Jose Scale Emergence',
  fullName: 'San Jose Scale (Quadraspidiotus perniciosus) Emergence Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [{
    name: 'Pre-Emergence of Adults',
    range: {
      min: 0,
      max: 200,
    },
    infos: [{
      name: 'Adults',
      value: `Overwintered scales have not yet emerged. Recommended to set biofix to Jan. 1 for Northern Hemisphere.`,
    }],
  }, {
    name: 'Male Emergence',
    range: {
      min: 201,
      max: 741,
    },
    infos: [{
      name: 'Adult Males',
      value: `Adult males have emerged.`,
    }],
  }, {
    name: 'Pre-larviposition Female Emergence',
    range: {
      min: 742,
      max: 972,
    },
    infos: [{
      name: 'Adult Females',
      value: `Adult females have emerged but have not entered larviposition.`,
    }],
  }, {
    name: 'First Instar Females',
    range: {
      min: 973,
      max: 1192,
    },
    infos: [{
      name: 'Adult Females',
      value: `Adult females are at first instar stage.`,
    }],
  }, {
    name: 'Second Instar Females',
    range: {
      min: 1193,
      max: 1250,
    },
    infos: [{
      name: 'Adult Females',
      value: `Adult females are at second instar stage.`,
    }],
  }, {
    name: 'Tight Cap Females',
    range: {
      min: 1251,
      max: 1450,
    },
    infos: [{
      name: 'Adult Females',
      value: `Adult females are at tight cap stage.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The San Jose Scale Emergence Growing Degree Day Model is used to predict the spring emergence of San Jose Elm Scale (Quadraspidiotus perniciosus) in order to prepare for setting traps or beginning a spray program. For best results the biofix date should be reset after the first male scales are observed.`,
  ],
  citations: [
    `Jorgensen, C. D., R. E. Rice, S. C. Hoyt, and P. H. Westigard. 1981. Phenology of the San Jose Scale (Homoptera: Diaspididae). Can. Ent. 113: 149-159.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-san-jose-scale-emergence-growing-degree-day-model',
}
