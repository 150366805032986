import { GddModelDefine } from '../gdd'

export const gddAsianCitrusPsyllid: GddModelDefine = {
  lowerThreshold: 52,
  upperThreshold: 90,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddAsianCitrusPsyllid',
  name: 'Asian Citrus Psyllid',
  fullName: 'Asian Citrus Psyllid (Diaphorina citri) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'First Leaf Flush',
      range: {
        min: 0,
        max: 630,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults have not begun laying eggs',
        },
      ],
    },
    {
      name: 'First Overwintering Adult Egg-Laying',
      range: {
        min: 631,
        max: 990,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have begun to lay eggs.',
        },
      ],
    },
    {
      name: 'Peak Overwintering Adult Egg-Laying',
      range: {
        min: 991,
        max: 1260,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak F1 Nymphs',
      range: {
        min: 1261,
        max: 2033,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs are at peak activity.',
        },
      ],
    },
    {
      name: 'Peak F1 Adult Egg-Laying',
      range: {
        min: 2034,
        max: 2303,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak F2 Nymphs',
      range: {
        min: 2304,
        max: 3077,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs are at peak activity.',
        },
      ],
    },
    {
      name: 'Peak F2 Adult Egg-Laying',
      range: {
        min: 3078,
        max: 3347,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak F3 Nymphs',
      range: {
        min: 3348,
        max: 4120,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs are at peak activity.',
        },
      ],
    },
    {
      name: 'Peak F3 Adult Egg-Laying',
      range: {
        min: 4121,
        max: 4390,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak F4 Nymphs',
      range: {
        min: 4391,
        max: 5164,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs are at peak activity.',
        },
      ],
    },
    {
      name: 'Peak F4 Adult Egg-Laying',
      range: {
        min: 5165,
        max: 5434,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak F5 Nymphs',
      range: {
        min: 5435,
        max: 6207,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs are at peak activity.',
        },
      ],
    },
    {
      name: 'Peak F5 Adult Egg-Laying',
      range: {
        min: 6208,
        max: 6477,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak F6 Nymphs',
      range: {
        min: 6478,
        max: 7251,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs are at peak activity.',
        },
      ],
    },
    {
      name: 'Peak F6 Adult Egg-Laying',
      range: {
        min: 7252,
        max: 7521,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak F7 Nymphs',
      range: {
        min: 7522,
        max: 8294,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs are at peak activity.',
        },
      ],
    },
    {
      name: 'Peak F7 Adult Egg-Laying',
      range: {
        min: 8295,
        max: 9000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Asian Citrus Psyllid Degree Day Model is used to predict development of life-cycle stages of the Asian Citrus Psyllid (Diaphorina citri), in order to improve pest management decisions.',
    'For best results, a biofix date should be set at the time of the first leaf flush of the season. The growing degree day model can then be used to predict emergence of overwintering adult, egg-laying and nymph activity for the following generations through the season.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-asian-citrus-psyllid-growing-degree-day-model/',
  citations: [
    `Liu, Y.H., and J.H. Tsai. 2000. Effects of temperature on biology and life table parameters of the Asian citrus psyllid, Diaphorina citri Kuwayama (Homoptera: Psyllidae). Ann. Appl. Biol 137:201-206.`,
  ],
}
