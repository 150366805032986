import { gddEnglishGrainAphid } from './gdd-english-grain-aphid'

export const gddEnglishGrainAphidAlate = gddEnglishGrainAphid.variety('Alate', {
  lowerThreshold: 39.2,
  upperThreshold: 76.3,
  cutoffMethod: 'horizontal',

  type: 'gddEnglishGrainAphidAlate',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Eggs',
    range: {
      min: 0,
      max: 247,
    },
    infos: [{
      name: 'Eggs',
      value: `Most aphids are in the egg stage.`,
    }],
  }, {
    name: 'Adults Egg Laying',
    range: {
      min: 248,
      max: 494,
    },
    infos: [{
      name: 'Adults',
      value: `Most aphids are adults.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The English Grain Aphid Growing Degree Day Model is used to predict life-stage developments of the English Grain Aphid (Macrosiphum avenae) in order to improve pest management decisions.`,
  ],
  citations: [
    `Kieckhefer, R. W., N. C. Elliott, and D. D. Walagenbach. 1989. Effects of constant and fluctuating temperatures on developmental rates and demographic statistics of the English grain aphid (Homoptera: Aphididae). Ann. Entomol. Soc. Am. 82: 701-706.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-english-grain-aphid-growing-degree-day-model',
})
