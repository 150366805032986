import { GddModelDefine } from '../gdd'

export const gddSugarbeetRootMaggot: GddModelDefine = {
  lowerThreshold: 47.5,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddSugarbeetRootMaggot',
  name: 'Sugarbeet Root Maggot',
  fullName: 'Sugarbeet Root Maggot (Tetanops myopaeformis) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Emergence',
      range: {
        min: 0,
        max: 360,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult flies have not yet emerged.',
        },
      ],
    },
    {
      name: 'Peak Capture - Maggot Flies',
      range: {
        min: 361,
        max: 500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Sugarbeet Root Maggot Degree Day Model is used to predict development of life-cycle stages of the Sugarbeet Root Maggot (Tetanops myopaeformis), in order to improve pest management decisions',
    'For best results, a biofix date should be set to March 1 for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adult flies.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-sugarbeet-root-maggot-growing-degree-day-model/',
  citations: [
    'E. J. Bechinski, C. D. McNeal, J. J. Gallian, Development of Action Thresholds for the Sugarbeet Root Maggot (Diptera: Otitidae), Journal of Economic Entomology, Volume 82, Issue 2, 1 April 1989, Pages 608–615, https://doi.org/10.1093/jee/82.2.608',
  ],
}
