import { GddModelDefine } from '../gdd'

export const gddPineEngraver: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddPineEngraver',
  name: 'Pine Engraver',
  fullName: 'Pine Engraver - Ips Beetle (Ips pini) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Adults have not yet emerged.',
      range: {
        min: 0,
        max: 100,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult are not yet present.',
        },
      ],
    },
    {
      name: 'Adults Active',
      range: {
        min: 101,
        max: 150,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are present and active.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pine Engraver (Ips Beetle) Growing Degree Day Model is used to predict life-stage developments of the Pine Engraver Beetle (Ips pini), in order to improve pest management decisions.',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-pine-engraver-growing-degree-day-model/',
  citations: [
    'Observations made by Michigan State University Pest Management extension, (https://www.canr.msu.edu/ipm/agriculture/christmas_trees/gdd_of_landscape_insects)',
  ],
}
