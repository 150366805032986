import { GddModelDefine } from '../gdd'

export const gddWheatSpring: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 95,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddWheatSpring',
  name: 'Spring Wheat',
  fullName: 'Bauer Spring Wheat Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Seed Planted',
      range: {
        min: 0,
        max: 180,
      },
      infos: [
        {
          name: 'Coleoptyle',
          value: 'Coleoptyle has not broken through soil.',
        },
      ],
    },
    {
      name: 'Germination: Coleoptyle has broken through soil.',
      range: {
        min: 181,
        max: 252,
      },
      infos: [
        {
          name: 'Coleoptyle',
          value: 'Coleoptyle has broken through the soil.',
        },
      ],
    },
    {
      name: 'First leaf unfolds.',
      range: {
        min: 253,
        max: 395,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'First leaf has unfolded.',
        },
      ],
    },
    {
      name: 'Second leaf unfolds.',
      range: {
        min: 396,
        max: 538,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Second leaf has unfolded.',
        },
      ],
    },
    {
      name: '3 Leaves, Tillers Emerge',
      range: {
        min: 539,
        max: 681,
      },
      infos: [
        {
          name: 'Tiller',
          value: 'Tillers have become visible.',
        },
        {
          name: 'Leaves',
          value: 'Third leaf has unfolded',
        },
      ],
    },
    {
      name: '4 Leaves',
      range: {
        min: 682,
        max: 895,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Fourth leaf has unfolded',
        },
      ],
    },
    {
      name: 'Jointing',
      range: {
        min: 896,
        max: 1110,
      },
      infos: [
        {
          name: 'Jointing',
          value: 'Jointing has occurred.',
        },
      ],
    },
    {
      name: '7 Leaves',
      range: {
        min: 1111,
        max: 1255,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Seventh leaf has unfolded',
        },
      ],
    },
    {
      name: 'Flag leaf has emerged.',
      range: {
        min: 1256,
        max: 1396,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Flag (eight) leaf has unfolded',
        },
      ],
    },
    {
      name: 'Boot Swelling Begins',
      range: {
        min: 1397,
        max: 1567,
      },
      infos: [
        {
          name: 'Boot Swelling',
          value: 'Boot swelling begins.',
        },
      ],
    },
    {
      name: 'Heading Begins',
      range: {
        min: 1568,
        max: 1739,
      },
      infos: [
        {
          name: 'Heading',
          value: 'Heading begins.',
        },
      ],
    },
    {
      name: 'Flowering Begins',
      range: {
        min: 1740,
        max: 1825,
      },
      infos: [
        {
          name: 'Flowering',
          value: 'Flowering begins.',
        },
      ],
    },
    {
      name: 'Kernel Watery Ripe',
      range: {
        min: 1826,
        max: 2000,
      },
      infos: [
        {
          name: 'Kernel',
          value: 'Kernel is watery ripe.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Bauer Spring Wheat Degree Day Model is used to predict harvest time and other growth stages of Spring Wheat based on the weather in a given season.',
    'For best results, a biofix date should be set at planting. The growing degree day model can then be used to predict leaf and node development, and later flowering and harvest. For best results the biofix should be reset when later stages have been observed, in order to more accurately predict the next stage.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-spring-winter-growing-degree-day-model/',
  citations: [
    `Bauer, A., C. Fanning, J.W. Enz, and C.V. Eberlein. 1984. Use of growing-degree days to determine spring wheat growth stages. North Dakota Coop. Ext. Ser. EB-37. Fargo, ND.`,
  ],
}
