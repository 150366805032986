import { GddModelDefine } from '../gdd'

export const gddCitricolaScaleEmergence: GddModelDefine = {
  lowerThreshold: 51.8,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddCitricolaScaleEmergence',
  name: 'Citricola Scale Emergence',
  fullName: 'Citricola Scale (Coccus pseudomagnoliarum) Emergence Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Pre-Emergence of Crawlers',
    range: {
      min: 0,
      max: 635,
    },
    infos: [{
      name: 'Crawlers',
      value: `Crawlers have not yet emerged. Recommended to set biofix to Mar. 1 for Northern Hemisphere.`,
    }],
  }, {
    name: 'Peak Crawler Abundance',
    range: {
      min: 636,
      max: 936,
    },
    infos: [{
      name: 'Crawlers',
      value: `Crawlers have begun to emerge.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Citricola Scale Growing Degree Day Model is used to predict the spring emergence of the Citricola Scale (Coccus pseudomagnoliarum) in order to prepare for setting sticky traps or beginning a spray program.`,
  ],
  citations: [
    `Dreistadt, S.H. 1996. Citricola scale (Homoptera: Coccidae) abundance on Chinese hackberry and scale control with spray oil or acephate trunk implants. J. Econ. Entomol. 89: 481-487.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-citricola-scale-emergence-growing-degree-day-model',
}
