import { GddModelDefine } from '../gdd'

export const gddPineRootCollarWeevil: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddPineRootCollarWeevil',
  name: 'Pine Root Collar Weevil',
  fullName: 'Pine Root Collar Weevil (Hylobius radicis) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Adults have not yet emerged.',
      range: {
        min: 0,
        max: 300,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult are not yet present.',
        },
      ],
    },
    {
      name: 'Adults Active',
      range: {
        min: 301,
        max: 350,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are present and active.',
        },
      ],
    },
    {
      name: 'Second Generation Adults have not yet emerged.',
      range: {
        min: 351,
        max: 1200,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are not yet present.',
        },
      ],
    },
    {
      name: 'Second Generation Adults Active',
      range: {
        min: 1201,
        max: 1400,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are present and active.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pine Root Collar Weevil Growing Degree Day Model is used to predict life-stage developments of the Pine Root Collar Weevil (Hylobius radicis), in order to improve pest management decisions.',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-pine-root-collar-weevil-growing-degree-day-model/',
  citations: [
    'Observations made by Michigan State University Pest Management extension, (https://www.canr.msu.edu/ipm/agriculture/christmas_trees/gdd_of_landscape_insects)',
  ],
}
