import { GddModelDefine } from '../gdd'

export const gddBathyplectesCurculionis: GddModelDefine = {
  lowerThreshold: 43,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddBathyplectesCurculionis',
  name: 'Bathyplectes Curculionis',
  fullName: 'Bathyplectes Curculionis Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Pre-Emergence',
      range: {
        min: 0,
        max: 425,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged from cocoons.',
        },
      ],
    },
    {
      name: 'Spring Emergence from Cocoons',
      range: {
        min: 426,
        max: 1392,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have begun to emerge from cocoons.',
        },
      ],
    },
    {
      name: 'Next Generation Adults',
      range: {
        min: 1393,
        max: 1500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'A complete life-cycle has finished and next generation adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Bathyplectes Curculionis Degree Day Model is used to predict development of life-cycle stages of the bio-control insect Bathyplectes Curculionis, in order to improve pest management practices.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-bathyplectes-curculionis-growing-degree-day-model/',
  citations: [
    'Eklund, L. R., and R. G. Simpson. 1977. Correlation of activities of the alfalfa weevil and Bathyplectes curculionis with alfalfa height and degree-day accumulation in Colorado. Environ. Entomol. 6: 69-71.',
  ],
}
