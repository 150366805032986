import { GddModelDefine } from '../gdd'

export const gddEncarsiaFormosa: GddModelDefine = {
  lowerThreshold: 54.9,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddEncarsiaFormosa',
  name: 'Encarsia Formosa',
  fullName: 'Encarsia Formosa Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 340,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged observed eggs or nymphs.',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 341,
        max: 500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults have emerged and begun laying next generation eggs.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Encarsia Formosa Degree Day Model is used to predict development of life-cycle stages of the bio-control insect Encarsia Formosa, in order to improve pest management practices.',
    'For best results, a biofix date should be set when Encarsia Formosa eggs are first observed or introduced to field. The growing degree day model can then be used to predict emergence of adults and next generation eggs.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-encarsia-formosa-growing-degree-day-model/',
  citations: [
    'Osborne, L. S. 1982. Temperature-dependent development of greenhouse whitefly and its parasite Encarsia formosa. Environ. Entomol. 11: 483-485.',
  ],
}
