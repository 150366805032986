import { GddModelDefine } from '../gdd'

export const gddSparganothisMoth: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 86,
  cutoffMethod: 'intermediate',

  group: 'gdd',
  type: 'gddSparganothisMoth',
  name: 'Sparganothis Moth',
  fullName: 'Sparganothis Moth (Sparganothis sulfureana) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Overwintered Larvae Emerging',
    range: {
      min: 0,
      max: 595,
    },
    infos: [{
      name: 'Info',
      value: `Larvae that have overwintered begin to emerge and feed. First insecticide treatment targeting larvae is recommended at this stage.`,
    }],
  }, {
    name: 'Flight Initiation',
    range: {
      min: 596,
      max: 680,
    },
    infos: [{
      name: 'Info',
      value: `Larvae have begun to complete development into adults and have initiated flight. Successful control through spraying is more challenging in this phase.`,
    }],
  }, {
    name: 'First Eggs Laid',
    range: {
      min: 681,
      max: 885,
    },
    infos: [{
      name: 'Info',
      value: `First eggs have been laid by adult females. Adults remain in active flight period.`,
    }],
  }, {
    name: 'Peak Flight',
    range: {
      min: 886,
      max: 895,
    },
    infos: [{
      name: 'Info',
      value: `Peak number of adult moths in flight. Eggs continue to be laid, but have not yet started hatching.`,
    }],
  }, {
    name: 'First Egg Hatched',
    range: {
      min: 896,
      max: 1634,
    },
    infos: [{
      name: 'Info',
      value: `Eggs have begun to hatch. Larvae have begun to emerge and are susceptible to insecticide sprays. Adults continue to fly and lay eggs.`,
    }],
  }, {
    name: 'End of Egg Laying',
    range: {
      min: 1635,
      max: 1889,
    },
    infos: [{
      name: 'Info',
      value: `Adults have completed egg laying. Eggs are continuing to hatch. Larvae are continuing to emerge and are susceptible to insecticide sprays.`,
    }],
  }, {
    name: 'Last Egg Hatched',
    range: {
      min: 1890,
    },
    infos: [{
      name: 'Info',
      value: `Eggs have completed hatching. All larvae have emerged from eggs and have begun feeding. They are beginning to overwinter and will be more challenging to control through sprays.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `This model is used to predict insect life stages of the Sparganothis Moth (Sparganothis sulfureana). Larvae should be targeted for control when first emerging in early spring and, for the second generation, between 895 and 1890 Degree Days. This window is important because second generation larvae can enter fruit when feeding, decreasing spray coverage.`,
    `Growing Degree Day models should always be coupled with scouting throughout the season to verify and calibrate the model. When adult moths are first observed or captured in traps, the biofix can be adjusted to Flight Initiation stage. This will increase the accuracy of subsequent moth life stage predictions.`,
  ],
  citations: [
    `Deutsch, A. E.; Rodriguez-saona, C. R.; Kyryczenko-roth, V.; Sojka, J.; Zalapa, J. E.; Steffan, S. A. Degree-day benchmarks for Sparganothis sulfureana (Lepidoptera: Tortricidae) development in cranberries. J. Econ. Entomol. 2014, 107, 2130-2136.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-sparganothis-moth-growing-degree-day-model',
}
