import { GddModelDefine } from '../gdd'

export const gddSpodopteraLitura: GddModelDefine = {
  lowerThreshold: 51,
  upperThreshold: 104,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddSpodopteraLitura',
  name: 'Spodoptera Litura',
  fullName: 'Spodoptera Litura (Tobacco Cutworm, Cotton Leafworm) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence from Overwintering',
      range: {
        min: 0,
        max: 216,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have not emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - Overwintering Generation',
      range: {
        min: 217,
        max: 314,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from overwintered larvae have emerged.',
        },
      ],
    },
    {
      name: 'First Egg-laying - Overwintering Generation',
      range: {
        min: 315,
        max: 707,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have emerged and are in first oviposition.',
        },
      ],
    },
    {
      name: 'Peak Larvae - First Generation',
      range: {
        min: 708,
        max: 1239,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First generation larvae have emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence - First Generation',
      range: {
        min: 1240,
        max: 1370,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from next generation larvae have emerged.',
        },
      ],
    },
    {
      name: 'Peak Adult Flight - First Generation',
      range: {
        min: 1371,
        max: 1729,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from first generation larvae have emerged and are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Larvae - Second Generation',
      range: {
        min: 1730,
        max: 2393,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Second generation larvae have emerged.',
        },
      ],
    },
    {
      name: 'Peak Adult Flight - Second Generation',
      range: {
        min: 2394,
        max: 2752,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from second generation larvae have emerged and are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Larvae - Third Generation',
      range: {
        min: 2753,
        max: 3415,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Third generation larvae have emerged.',
        },
      ],
    },
    {
      name: 'Peak Adult Flight - Third Generation',
      range: {
        min: 3416,
        max: 3775,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from third generation larvae have emerged and are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Larvae - Fourth Generation',
      range: {
        min: 3776,
        max: 4438,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Fourth generation larvae have emerged.',
        },
      ],
    },
    {
      name: 'Peak Adult Flight - Fourth Generation',
      range: {
        min: 4439,
        max: 5461,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from fourth generation larvae have emerged and are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Adult Flight - Fifth Generation',
      range: {
        min: 5462,
        max: 6483,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from fifth generation larvae have emerged and are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Adult Flight - Sixth Generation',
      range: {
        min: 6483,
        max: 6600,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from sixth generation larvae have emerged and are in peak flight.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Spodoptera litura Degree Day Model is used to predict development of life-cycle stages of Spodopter litura (Tobacco Cutworm, Cotton Leafworm), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted by insecticides and then adults which can be targeted by pheromone traps.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-spodoptera-litura-growing-degree-day-model/',
  citations: [
    'Ranga Rao, G. V., J. A. Wightman, and D. V. Ranga Rao. 1989. Threshold temperatures and thermal requirements for the development of Spodoptera litura (Lepidoptera: Noctuidae). Environ. Entomol. 18:548-551.',
  ],
}
