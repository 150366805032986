import { GddModelDefine } from '../gdd'

export const gddGreenhouseWhitefly: GddModelDefine = {
  lowerThreshold: 46.9,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddGreenhouseWhitefly',
  name: 'Greenhouse Whitefly',
  fullName: 'Greenhouse Whitefly (Trialeurodes vaporariorum) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Eggs',
    range: {
      min: 0,
      max: 221,
    },
    infos: [{
      name: 'Eggs',
      value: `Most whiteflies are in the egg stage.`,
    }],
  }, {
    name: 'Nymphs',
    range: {
      min: 222,
      max: 685,
    },
    infos: [{
      name: 'Nymphs',
      value: `Most whiteflies are in the nymph stage.`,
    }],
  }, {
    name: 'Adults',
    range: {
      min: 686,
      max: 906,
    },
    infos: [{
      name: 'Adults',
      value: `Most whiteflies are adults. Biofix should be reset for next generation`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Greenhouse Whitefly Growing Degree Day Model is used to predict life-stage developments of the Greenhouse Whitefly (Trialeurodes vaporariorum) in order to improve pest management decisions.`,
  ],
  citations: [
    `Osborne, L. S. 1982. Temperature-dependent development of greenhouse whitefly and its parasite, Encarsia formosa. Environ. Entomol. 11: 483-485.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-greenhouse-whitefly-growing-degree-day-model',
}
