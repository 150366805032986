import { GddModelDefine } from '../gdd'

export const gddWesternGrapeLeafhopper: GddModelDefine = {
  lowerThreshold: 50.5,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddWesternGrapeLeafhopper',
  name: 'Western Grape Leafhopper',
  fullName: 'Western Grape Leafhopper (Erythroneura elegantula) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Eggs',
    range: {
      min: 0,
      max: 980,
    },
    infos: [{
      name: 'Eggs',
      value: `Most leafhoppers are in the egg stage.`,
    }],
  }, {
    name: 'Adults',
    range: {
      min: 981,
      max: 1180,
    },
    infos: [{
      name: 'Adults',
      value: `Most leafhoppers are adults. Biofix should be reset for next generation`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Western Grape Leafhopper Growing Degree Day Model is used to predict life-stage developments of the Western Grape Leafhopper (Erythroneura elegantula) in order to improve pest management decisions.`,
  ],
  citations: [
    `Jensen, F. L., and D. L. Flaherty. 1982. Grape Leafhopper IN Grape Pest Management. U.C. Div. Agr. Sci. Publ. #4105. pp.95-110.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-western-grape-leafhopper-growing-degree-day-model',
}
