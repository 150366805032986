import { GddModelDefine } from '../gdd'

export const gddSeedcornMaggotEmergence: GddModelDefine = {
  lowerThreshold: 39,
  upperThreshold: 84.2,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddSeedcornMaggotEmergence',
  name: 'Seedcorn Maggot Emergence',
  fullName: 'Seedcorn Maggot (Delia platura) Emergence Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [{
    name: 'Pre-Emergence',
    range: {
      min: 0,
      max: 342,
    },
    infos: [{
      name: 'Adults',
      value: `Adults have not yet emerged. Recommended to set biofix to Jan. 1 for Northern Hemisphere`,
    }],
  }, {
    name: 'Adult Emergence',
    range: {
      min: 343,
      max: 500,
    },
    infos: [{
      name: 'Adults',
      value: `Adults have begun to emerge.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Seedcorn Maggot Emergence Growing Degree Day Model is used to predict the spring emergence of the Seedcorn Maggot (Delia platura) in order to prepare for setting sticky traps or beginning a spray program.`,
  ],
  citations: [
    `Funderburk, J. E., L. G. Higley and L. P. Pedigo. 1984. Seedcorn maggot (Diptera: Anthomyiidae) phenology in central Iowa and examination of a thermal-unit system to predict development under field conditions. Environ. Entomol. 13: 105-109.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-seedcorn-maggot-emergence-growing-degree-day-model',
}
