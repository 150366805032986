import { GddModelDefine } from '../gdd'

export const gddZimmermanPineMoth: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddZimmermanPineMoth',
  name: 'Zimmerman Pine Moth',
  fullName: 'Zimmerman Pine Moth (Dioryctria zimmermani) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Larvae have not emerged',
      range: {
        min: 0,
        max: 25,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Larvae',
      range: {
        min: 26,
        max: 100,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have emerged and have begun to feed.',
        },
      ],
    },
    {
      name: 'Pupation',
      range: {
        min: 101,
        max: 1700,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'First larvae have entered pupae stage.',
        },
      ],
    },
    {
      name: 'Adult Flight',
      range: {
        min: 1701,
        max: 1800,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults moths are active and in flight.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Zimmerman Pine Moth Degree Day Model is used to predict life-stage developments of the Zimmerman Pine Moth (Dioryctria zimmermani), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted by insecticedes.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-zimmerman-pine-moth-growing-degree-day-model/',
  citations: [
    'Eliason, E.A. and D.G. McCullough. 1997. Survival and fecundity of three insects reared on four varieties of Scotch pine Christmas trees. Journal of Economic Entomology 90:1598-1608.',
  ],
}
