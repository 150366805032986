import { GddModelDefine } from '../gdd'

export const gddChickpeaDesi: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddChickpeaDesi',
  name: 'Chickpea (Desi)',
  fullName: 'Chickpea (Desi) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Planting',
      range: {
        min: 0,
        max: 280,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Leaves have not yet emerged.',
        },
        {
          name: 'Shoot Break',
          value: 'Shoot has not broken through soil.',
        },
      ],
    },
    {
      name: 'Shoot Break through soil.',
      range: {
        min: 281,
        max: 350,
      },
      infos: [
        {
          name: 'Shoot Break',
          value: 'Shoot has broken through the soil.',
        },
      ],
    },
    {
      name: 'First True Leaves',
      range: {
        min: 351,
        max: 440,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'First true leaves have emerged.',
        },
      ],
    },
    {
      name: 'Fourth True Leaves',
      range: {
        min: 441,
        max: 1610,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Fourth true leaves have emerged.',
        },
      ],
    },
    {
      name: 'Flower buds just visible.',
      range: {
        min: 1611,
        max: 1920,
      },
      infos: [
        {
          name: 'Flowers',
          value: 'Flower buds just visible (inside auxiliary buds).',
        },
      ],
    },
    {
      name: '10% of flowers visible.',
      range: {
        min: 1921,
        max: 2240,
      },
      infos: [
        {
          name: 'Flowers',
          value: '10% of flowers are visible. 13 to 14 nodes.',
        },
      ],
    },
    {
      name: '10% of pods solid to the touch.',
      range: {
        min: 2241,
        max: 2810,
      },
      infos: [
        {
          name: 'Pods',
          value: '10% of pods are solid to the touch.',
        },
      ],
    },
    {
      name: '90% of pods solid to the touch.',
      range: {
        min: 2811,
        max: 3060,
      },
      infos: [
        {
          name: 'Pods',
          value: '90% of pods are solid to the touch. Only upper parts of plant still green.',
        },
      ],
    },
    {
      name: 'Harvest',
      range: {
        min: 3061,
        max: 3300,
      },
      infos: [
        {
          name: 'Harvest',
          value: 'Vine, pods, and seeds ripe and dry. 18% moisture. ',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Chickpea (Desi) Degree Day Model is used to predict harvest time of chickpea (Desi) based on the weather in a given season.',
    'For best results, a biofix date should be set at planting time. The growing degree day model can then be used to predict harvest time.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-chickpea-desi-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
    `Stu Brandt, Scott, SK 1995-97 and Perry Miller, Swift Current, SK 1995-98`,
  ],
}
