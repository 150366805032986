import { GddModelDefine } from '../gdd'

export const gddCabbageAphid: GddModelDefine = {
  lowerThreshold: 44.1,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddCabbageAphid',
  name: 'Cabbage Aphid',
  fullName: 'Cabbage Aphid (Brevicoryne brassicae) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Eggs',
    range: {
      min: 0,
      max: 227,
    },
    infos: [{
      name: 'Eggs',
      value: `Most aphids are in the egg stage.`,
    }],
  }, {
    name: 'Adult Pre Egg Laying',
    range: {
      min: 228,
      max: 256,
    },
    infos: [{
      name: 'Adults',
      value: `Most aphids are adults.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has not yet begun.`,
    }],
  }, {
    name: 'Adults Egg Laying',
    range: {
      min: 257,
      max: 533,
    },
    infos: [{
      name: 'Adults',
      value: `Most aphids are adults.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Cabbage Aphid Growing Degree Day Model is used to predict life-stage developments of the Cabbage Aphid (Brevicoryne brassicae) in order to improve pest management decisions.`,
  ],
  citations: [
    `Raworth, D. A. 1984. Population dynamics of the cabbage aphid, Brevicoryne brassicae (Homoptera: Aphididae) at Vancouver, British Columbia. II. Development, fecundity, and longevity. Can. Ent. 116: 871-878.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-cabbage-aphid-growing-degree-day-model',
}
