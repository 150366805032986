import { gddPeaAphid } from './gdd-pea-aphid'

export const gddPeaAphidApterous = gddPeaAphid.variety('Apterous', {
  lowerThreshold: 41.9,
  upperThreshold: 82.4,
  cutoffMethod: 'horizontal',

  type: 'gddPeaAphidApterous',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Eggs',
    range: {
      min: 0,
      max: 197,
    },
    infos: [{
      name: 'Eggs',
      value: `Most aphids are in the egg stage.`,
    }],
  }, {
    name: 'Adults Egg Laying',
    range: {
      min: 198,
      max: 394,
    },
    infos: [{
      name: 'Adults',
      value: `Most aphids are adults.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Pea Aphid Growing Degree Day Model is used to predict life-stage developments of the Pea Aphid (Acyrthosiphon pisum) in order to improve pest management decisions.`,
  ],
  citations: [
    `Campbell, A. and M. Mackauer. 1975. Thermal constants for development of the pea aphid (Homoptera: Aphididae) and some of its parasites. Can. Ent. 107: 419-423.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-pea-aphid-growing-degree-day-model',
})
