import { GddModelDefine } from '../gdd'

export const gddSweetCornSugarPearlTemptationDS: GddModelDefine = {
  lowerThreshold: 44,
  upperThreshold: 86,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddSweetCornSugarPearlTemptationDS',
  name: 'Sweet Corn (Sugar Pearl + Temptation DS)',
  fullName: 'Sweet Corn (Sugar Pearl + Temptation) Direct Seeded Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Planting - Direct Seeding',
      range: {
        min: 0,
        max: 446,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Leaves have not yet emerged.',
        },
      ],
    },
    {
      name: '5 True Leaves',
      range: {
        min: 447,
        max: 982,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'First 5 true leaves have emerged.',
        },
      ],
    },
    {
      name: '5 Inch Tassels',
      range: {
        min: 983,
        max: 1342,
      },
      infos: [
        {
          name: 'Tassels',
          value: 'Tassels are approximately 5 inches.',
        },
      ],
    },
    {
      name: '95% Silk Emergence',
      range: {
        min: 1343,
        max: 1883,
      },
      infos: [
        {
          name: 'Silk Emergence',
          value: '95% of Silk has Emerged.',
        },
      ],
    },
    {
      name: 'Fresh Market Harvest',
      range: {
        min: 1884,
        max: 2000,
      },
      infos: [
        {
          name: 'Harvest',
          value: 'Fresh market harvest is ready.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Sweet Corn Growing Degree Day Model is used to predict harvest time of sweet corn based on the weather in a given season.',
    'For best results, a biofix date should be set at planting time. The growing degree day model can then be used to predict harvest time.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-sweet-corn-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
  ],
}
