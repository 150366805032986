import { GddModelDefine } from '../gdd'

export const gddFlax: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddFlax',
  name: 'Flax',
  fullName: 'Flax Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Seed Planted',
      range: {
        min: 0,
        max: 290,
      },
      infos: [
        {
          name: 'Coleoptyle',
          value: 'Coleoptyle has not broken through soil.',
        },
      ],
    },
    {
      name: 'Germination: Coleoptyle has broken through soil.',
      range: {
        min: 291,
        max: 360,
      },
      infos: [
        {
          name: 'Coleoptyle',
          value: 'Coleoptyle has broken through the soil.',
        },
      ],
    },
    {
      name: 'First leaf unfolds.',
      range: {
        min: 361,
        max: 620,
      },
      infos: [
        {
          name: 'First leaf.',
          value: 'First leaf has unfolded.',
        },
      ],
    },
    {
      name: '9 Pairs of Leaves Unfolded',
      range: {
        min: 621,
        max: 1650,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Nine pairs of leaves unfolded.',
        },
      ],
    },
    {
      name: 'First Flower Buds Visible',
      range: {
        min: 1651,
        max: 1910,
      },
      infos: [
        {
          name: 'Flowers',
          value: 'First flower buds visible (unfold stem tips to observe).',
        },
      ],
    },
    {
      name: 'First flower petals visible.',
      range: {
        min: 1911,
        max: 2110,
      },
      infos: [
        {
          name: 'Flowers',
          value: 'First flower petals visible, flowers still closed.',
        },
      ],
    },
    {
      name: 'Flowering 50% Complete.',
      range: {
        min: 2111,
        max: 2890,
      },
      infos: [
        {
          name: 'Flowers',
          value: 'Flowering 50% complete.',
        },
      ],
    },
    {
      name: '90% Color Change of Seeds.',
      range: {
        min: 2891,
        max: 2980,
      },
      infos: [
        {
          name: 'Seeds',
          value: '90% of seeds have changed color. Upper levels scenescent. Seeds rattle.',
        },
      ],
    },
    {
      name: 'Harvest',
      range: {
        min: 2981,
        max: 3200,
      },
      infos: [
        {
          name: 'Harvest',
          value: 'Crop is ready for harvest.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Flax Degree Day Model is used to predict harvest time and other growth stages of flax based on the weather in a given season.',
    'For best results, a biofix date should be set at planting. The growing degree day model can then be used to predict harvest time. For best results the biofix should be reset when later stages have been observed, in order to more accurately predict the next stage.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-flax-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
    `Perry Miller, Swift Current, SK 1996-98`,
  ],
}
