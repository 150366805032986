import { GddModelDefine } from '../gdd'

export const gddElmBarkBeetle: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddElmBarkBeetle',
  name: 'Elm Bark Beetle',
  fullName: 'Elm Bark Beetle (Scolytus spp.) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Emergence',
      range: {
        min: 0,
        max: 7,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Adult Emergence',
      range: {
        min: 8,
        max: 120,
      },
      infos: [
        {
          name: 'Adult',
          value: 'First adults have begun to emerge.',
        },
      ],
    },
    {
      name: 'End Adult Emergence',
      range: {
        min: 121,
        max: 200,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults have fully emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Elm Bark Beetle Growing Degree Day Model is used to predict development of life-cycle stages of Elm Bark Beetle (Scolytus spp.), in order to improve pest management decisions.',
    'For best results, a biofix date should be set to March 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults which can be targeted for treatment.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-elm-bark-beetle-growing-degree-day-model/',
  citations: [
    'Growing Degree Day Information. Nathan W. Siegert, Deborah G. McCullough and Jeffrey A. Andresen. Michigan State University, 2 October 2015. Web. 25 February 2016.',
    'Using Growing Degree-Days for Insect Pest Management. Thomas Kowalsick and Scott Clark. Cornell Cooperative Extension in Suffolk County, March 2012. Web. 25 February 2016.',
  ],
}
