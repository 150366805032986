import {
  tomCastModelCommon,
  TomCastModelDefine,
} from './tom-cast'

export const tomCastPistachioAlternariaLateBlight: TomCastModelDefine = {
  ...tomCastModelCommon,
  type: 'tomCastPistachioAlternariaLateBlight',
  name: 'Pistachio Alternaria Late Blight',
  fullName: 'Pistachio Alternaria Late Blight',
  stages: [{
    name: 'Treatment Not Recommended',
    range: {
      min: 0,
      max: 9,
    },
    infos: [{
      name: 'Pistachio Alternaria Late Blight',
      value: `Risk of infection is low and treatment is not yet recommended.`,
    }],
  }, {
    name: 'Treatment Recommended - Susceptible Varieties',
    range: {
      min: 10,
    },
    infos: [{
      name: 'Pistachio Alternaria Late Blight',
      value: `Risk of infection is high and preventative treatment is recommended.`,
    }],
  }],
  notes: [
    'Plant disease risk models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'Pistachio Alternaria Late Blight is caused by the fungus Alternaria spp, and it can be very difficult to control once the disease has been established. Preventative spray treatments are recommend to prevent infection.',
    'The Pistachio Alternia Late Blight risk model, is a modified verstion of Tomcast, and is used to determine interval length between spray treatments. The model calculates daily DSV (Disease Severity Value) based on temperature and late wetness duration. When 10 DSVs have accumulated, treatment is recommended.',
    'Model should be first used in late June or early July (in the Northern Hemisphere) after the first preventative spray.',
    'The model should be reset after each spray, in order to time the subsequent treatment.',
  ],
  citations: [
    'Pitblado, R. E. 1992. The development and implementation of TOM-CAST: A weather-timed fungicide spray program for field tomatoes. Ministry of Agriculture and Food, Ontario, Canada.',
    'Madden L., Pennypacker, S. P., and McNab, A. A. 1978. FAST, a forecast system for Alternaria solani on tomato. Phytopathology 68:1354-1358.',
    'Morgan, D.; Puckett, R.; Michailides, T.J. Reducing fungicide applications to control Alternaria late blight of California pistachios by spraying after early season 10-unit DSV thresholds. Phytopathology 2013, 103, S.3.'
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-pistachio-alternaria-late-blight-risk-model/'
}
