import { GddModelDefine } from '../gdd'

export const gddMimosaWebworm: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddMimosaWebworm',
  name: 'Mimosa Webworm',
  fullName: 'Mimosa Webworm (Homadaula anisocentra) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Eggs Overwintering',
      range: {
        min: 0,
        max: 850,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not begun to hatch.',
        },
      ],
    },
    {
      name: 'Egg Hatch',
      range: {
        min: 851,
        max: 900,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
        {
          name: 'larvae',
          value: 'Caterpillars are beginning to emerge.',
        },
      ],
    },
    {
      name: 'Larvae Present',
      range: {
        min: 901,
        max: 1200,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and may be feeding.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Mimosa Webworm Degree Day Model is used to predict life-stage developments of the Mimosa Webworm (Homadaula anisocentra), in order to improve pest management decisions.',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted by traps.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-mimosa-webworm-growing-degree-day-model/',
  citations: [
    'Herms,D.A. Using degree days and plant phenology to predict pest activity. Chapter 11. Tactics and Tools for IPM.',
  ],
}
