import { GddModelDefine } from '../gdd'

export const gddEgyptianAlfalfaWeevil: GddModelDefine = {
  lowerThreshold: 45,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddEgyptianAlfalfaWeevil',
  name: 'Egyptian Alfalfa Weevil',
  fullName: 'Egyptian Alfalfa Weevil (Hypera brunnipennis) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Adult / Eggs',
    range: {
      min: 0,
      max: 211,
    },
    infos: [{
      name: 'Adults',
      value: `Adults are active.`,
    }, {
      name: 'Eggs',
      value: `Adult have begun to lay eggs.`,
    }],
  }, {
    name: 'Larvae',
    range: {
      min: 212,
      max: 584,
    },
    infos: [{
      name: 'Larvae',
      value: `Eggs have begun to hatch and larvae have emerged.`,
    }],
  }, {
    name: 'Pupae',
    range: {
      min: 585,
      max: 868,
    },
    infos: [{
      name: 'Pupae',
      value: `Larvae have developed into the pupae stage.`,
    }],
  }, {
    name: 'Adult Pre Egg Laying',
    range: {
      min: 869,
      max: 1147,
    },
    infos: [{
      name: 'Adult',
      value: `Pupae have developed into adults.`,
    }, {
      name: 'Eggs',
      value: `Eggs have not yet begun to be laid.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Egyptian Alfalfa Weevil Growing Degree Day Model is used to predict life-stage developments of the Egyptian Alfalfa Weevil (Hypera brunnipennis) in order to improve pest management decisions.`,
    `For best results, sweep nets should be used early in the season to monitor for insect presence. The biofix should be adjusted to represent the first date that adult weevils have been caught consistently. The growing degree day model can then be used to predict emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Integrated Pest Management for Alfalfa Hay. University of California Statewide IPM Project. Div. Agr. Sci. Publ. #4104. pp. 47-50.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-alfalfa-weevil-growing-degree-day-model',
}
