import { GddModelDefine } from '../gdd'

export const gddSmallBroomrape: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddSmallBroomrape',
  name: 'Small Broomrape',
  fullName: 'Small Broomrape (Orobanche minor) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Emergence Yet',
      range: {
        min: 0,
        max: 720,
      },
      infos: [
        {
          name: 'Tubercles',
          value: 'Tubercles have not yet attached to red clover.',
        },
      ],
    },
    {
      name: 'Initial Attachment to Red Clover',
      range: {
        min: 721,
        max: 1340,
      },
      infos: [
        {
          name: 'Tubercles',
          value: 'Tubercles have attached to red clover.',
        },
      ],
    },
    {
      name: 'Pre-Herbicide Treatment',
      range: {
        min: 1341,
        max: 1440,
      },
      infos: [
        {
          name: 'Herbicide Treatment',
          value: 'Check herbicide pregrazing and preharvest intervals prior to any application.',
        },
      ],
    },
    {
      name: 'Begin Herbicide Application Window',
      range: {
        min: 1441,
        max: 1800,
      },
      infos: [
        {
          name: 'Herbicide Treatment',
          value: 'Begin herbicide application window.',
        },
      ],
    },
    {
      name: 'End of Herbicide Application Window',
      range: {
        min: 1801,
        max: 1980,
      },
      infos: [
        {
          name: 'Herbicide Treatment',
          value: 'End of herbicide application window.',
        },
      ],
    },
    {
      name: 'Flower Stalk Emergence',
      range: {
        min: 1981,
        max: 2160,
      },
      infos: [
        {
          name: 'Flower Stalk',
          value: 'Small broomrape flower stalks have begun to emerge.',
        },
      ],
    },
    {
      name: 'Beginning of Red Clover Growth Reduction',
      range: {
        min: 2161,
        max: 2400,
      },
      infos: [
        {
          name: 'Red Clover',
          value: 'Beginning of red clover growth reduction because of parasitism.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Small Broomrape Degree Day Model is used to predict emergence of Small Broomrape (Orobanche minor) based on the weather in a given season.',
    'For best results, a biofix date should be set to January 1st for the Northern Hemisphere. The growing degree day model can then be used to time herbicide treatment.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-small-broomrape-growing-degree-day-model/',
  citations: [
    `Hanan Eizenberg, et al. “A Predictive Degree-Days Model for Small Broomrape (Orobanche Minor) Parasitism in Red Clover in Oregon.” Weed Science, vol. 53, no. 1, 2005, pp. 37–40. JSTOR, http://www.jstor.org/stable/4046886. Accessed 20 Mar. 2023.`,
  ],
}
