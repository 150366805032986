import { GddModelDefine } from '../gdd'

export const gddPeaYellow: GddModelDefine = {
  lowerThreshold: 32,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddPeaYellow',
  name: 'Pea (Yellow)',
  fullName: 'Pea (Yellow) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Planting',
      range: {
        min: 0,
        max: 270,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Leaves have not yet emerged.',
        },
        {
          name: 'Shoot Break',
          value: 'Shoot has not broken through soil.',
        },
      ],
    },
    {
      name: 'Shoot Break through soil.',
      range: {
        min: 271,
        max: 330,
      },
      infos: [
        {
          name: 'Shoot Break',
          value: 'Shoot has broken through the soil.',
        },
      ],
    },
    {
      name: 'First True Leaves',
      range: {
        min: 331,
        max: 420,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'First true leaves have emerged.',
        },
      ],
    },
    {
      name: 'Fourth True Leaves',
      range: {
        min: 421,
        max: 1520,
      },
      infos: [
        {
          name: 'Leaves',
          value: 'Fourth true leaves have emerged.',
        },
      ],
    },
    {
      name: 'Flower buds just visible.',
      range: {
        min: 1521,
        max: 1820,
      },
      infos: [
        {
          name: 'Flowers',
          value: 'Flower buds just visible (inside auxiliary buds).',
        },
      ],
    },
    {
      name: '10% of flowers visible.',
      range: {
        min: 1821,
        max: 2120,
      },
      infos: [
        {
          name: 'Flowers',
          value: '10% of flowers are visible. 12 to 16 nodes.',
        },
      ],
    },
    {
      name: '10% of pods are final size.',
      range: {
        min: 2121,
        max: 2420,
      },
      infos: [
        {
          name: 'Pods',
          value: '10% of pods are final size.',
        },
      ],
    },
    {
      name: '10% of pods are yellow to brown.',
      range: {
        min: 2421,
        max: 2660,
      },
      infos: [
        {
          name: 'Pods',
          value: '10% of pods are yellow to brown. Upper pods still green.',
        },
      ],
    },
    {
      name: '90% of seeds changing color.',
      range: {
        min: 2661,
        max: 2750,
      },
      infos: [
        {
          name: 'Seeds',
          value: '90% of seeds started to change color (specks or solid). Bottom 1/3 ripe, middle 1/3 yellow-tan and seeds dry and firm.',
        },
      ],
    },
    {
      name: 'Harvest',
      range: {
        min: 2751,
        max: 3000,
      },
      infos: [
        {
          name: 'Harvest',
          value: 'Plants of broadleafs rip and dry. ',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pea (Yellow) Degree Day Model is used to predict harvest time of Pea (Yellow) based on the weather in a given season.',
    'For best results, a biofix date should be set at planting time. The growing degree day model can then be used to predict harvest time.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-pea-yellow-growing-degree-day-model/',
  citations: [
    `Andrews et al. Vegetable Degree-day Models: An Introduction for Farmers and Gardeners. EM 9305. 2021.`,
    `Stu Brandt, Scott, SK 1995-97 and Perry Miller, Swift Current, SK 1995-98`,
  ],
}
