import { GddModelDefine } from '../gdd'

export const gddBlackberryLeafhopper: GddModelDefine = {
  lowerThreshold: 46.6,
  upperThreshold: 82.4,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddBlackberryLeafhopper',
  name: 'Blackberry Leafhopper',
  fullName: 'Blackberry Leafhopper (Dikrella californica) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Adult Pre Egg Laying',
    range: {
      min: 0,
      max: 254,
    },
    infos: [{
      name: 'Adults',
      value: `Adult leafhoppers are present but have not yet begun to lay eggs.`,
    }],
  }, {
    name: 'Eggs',
    range: {
      min: 255,
      max: 616,
    },
    infos: [{
      name: 'Eggs',
      value: `Most leafhoppers are in the egg stage.`,
    }],
  }, {
    name: 'Nymphs',
    range: {
      min: 617,
      max: 1070,
    },
    infos: [{
      name: 'Nymphs',
      value: `Most leafhoppers are in the nymph stage.`,
    }],
  }, {
    name: 'Adults',
    range: {
      min: 1071,
      max: 1324,
    },
    infos: [{
      name: 'Adults',
      value: `Most leafhoppers are adults. Biofix should be reset for next generation`,
    }, {
      name: 'Eggs',
      value: `Egg laying has not yet begun.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Blackberry Leafhopper Leafhopper Growing Degree Day Model is used to predict life-stage developments of the Blackberry Leafhopper (Dikrella californica) in order to improve pest management decisions.`,
  ],
  citations: [
    `Williams, D. W. 1984. Ecology of the blackberry-leafhopper-parasite system and its relevance to California grape agroecosystems. Hilgardia 52: 1-32.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-blackberry-leafhopper-growing-degree-day-model',
}
