import { GddModelDefine } from '../gdd'

export const gddIntroducedPineSawfly: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddIntroducedPineSawfly',
  name: 'Introduced Pine Sawfly',
  fullName: 'Introduced Pine Sawfly (Diprion similis) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Larvae Present',
      range: {
        min: 0,
        max: 400,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult flies may be present.',
        },
        {
          name: 'Larvae',
          value: 'Larvae have not yet emerged.',
        },
      ],
    },
    {
      name: 'Larvae Present',
      range: {
        min: 401,
        max: 600,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and can be targeted for control',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Introduced Pine Sawfly Degree Day Model is used to predict life-stage developments of the Introduced Pine Sawfly (Diprion similis), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted by insecticide treatments.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-introduced-pine-sawfly-growing-degree-day-model/',
  citations: [
    'Herms,D.A. Using degree days and plant phenology to predict pest activity. Chapter 11. Tactics and Tools for IPM.',
  ],
}
