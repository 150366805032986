import { GddModelDefine } from '../gdd'

export const gddTobaccoBudwormEmergence: GddModelDefine = {
  lowerThreshold: 55,
  upperThreshold: 86,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddTobaccoBudwormEmergence',
  name: 'Tobacco Budworm Emergence',
  fullName: 'Tobacco Budworm (Helicoverpa virescens) Emergence Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Spring Emergence',
      range: {
        min: 0,
        max: 271,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Spring Emergence',
      range: {
        min: 272,
        max: 328,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults from overwintered pupae have emerged.',
        },
      ],
    },
    {
      name: '10% Spring Emergence',
      range: {
        min: 329,
        max: 384,
      },
      infos: [
        {
          name: 'Adults',
          value: '10% of adults from overwintered pupae have emerged.',
        },
      ],
    },
    {
      name: '25% Spring Emergence',
      range: {
        min: 385,
        max: 440,
      },
      infos: [
        {
          name: 'Adults',
          value: '25% of adults from overwintered pupae have emerged.',
        },
      ],
    },
    {
      name: '50% Spring Emergence',
      range: {
        min: 441,
        max: 497,
      },
      infos: [
        {
          name: 'Adults',
          value: '50% of adults from overwintered pupae have emerged.',
        },
      ],
    },
    {
      name: '75% Spring Emergence',
      range: {
        min: 498,
        max: 591,
      },
      infos: [
        {
          name: 'Adults',
          value: '75% of adults from overwintered pupae have emerged.',
        },
      ],
    },
    {
      name: '90% Spring Emergence',
      range: {
        min: 592,
        max: 700,
      },
      infos: [
        {
          name: 'Adults',
          value: '90% of adults from overwintered pupae have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Tobacco Budworm Degree Day Model is used to predict development of life-cycle stages of Tobacco Budworm (Helicoverpa virescens), in order to improve pest management decisions',
    `For best results, a biofix date should be set to January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults from overwintered pupae. In order to predict later generations within the season, the 'Tobacco Budworm' Model should be used.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-tobacco-budworm-growing-degree-day-model/',
  citations: [
    'Potter, M. F., R. T. Huber, and T. F. Watson. 1981. Heat unit requirements for emergence of overwintering tobacco budworm, Heliothis virescens, (F.), in Arizona. Environ. Entomol. 10: 543-545.',
  ],
}
