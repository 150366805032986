import { GddModelDefine } from '../gdd'

export const gddEuropeanFruitLecaniumScale: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddEuropeanFruitLecaniumScale',
  name: 'European Fruit Lecanium Scale',
  fullName: 'European Fruit Lecanium Scale (Rhyacionia bouliana) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Crawlers have not yet Emerged',
      range: {
        min: 0,
        max: 800,
      },
      infos: [
        {
          name: 'Crawlers',
          value: 'Crawlers have not yet emerged from overwintered nymphs.',
        },
      ],
    },
    {
      name: 'Crawler Emergence',
      range: {
        min: 801,
        max: 1000,
      },
      infos: [
        {
          name: 'Crawlers',
          value: 'Crawlers have emerged from overwintered nymphs.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The European Fruit Lecanium Scale Degree Day Model is used to predict development of life-cycle stages of the European Fruit Lecanium Scale (Rhyacionia bouliana), in order to improve pest management decisions',
    'For best results, a biofix date should be set as January 1st for the Northern Hemisphere to predict overwintering emergence.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-european-fruit-lecanium-scale-growing-degree-day-model/',
  citations: [
    'Kowalsick, Thomas, and Scott Clark. 2006. Using Growing Degree Days for Insect Pest Management. Cornell Cooperative Extension. 2006.',
  ],
}
