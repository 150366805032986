import { GddModelDefine } from '../gdd'

export const gddStrawberryRootWeevilStrawberry: GddModelDefine = {
  lowerThreshold: 40,
  upperThreshold: 103,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddStrawberryRootWeevilStrawberry',
  name: 'Strawberry Root Weevil (Strawberry)',
  fullName: 'Strawberry Root Weevil (Otiorhynchus ovatus) on Strawberry Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Pupal Development has not yet Begun',
      range: {
        min: 0,
        max: 564,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Overwintered larvae have not yet begun pupation.',
        },
      ],
    },
    {
      name: 'Pupal Development Begins',
      range: {
        min: 565,
        max: 1056,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Overwintered larvae have begun pupation.',
        },
      ],
    },
    {
      name: 'Beginning Adult Emergence',
      range: {
        min: 1057,
        max: 1498,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered larvae have begun emergence from pupae.',
        },
      ],
    },
    {
      name: 'Beginning Egg-Laying',
      range: {
        min: 1499,
        max: 1798,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered larvae have begun egg-laying.',
        },
      ],
    },
    {
      name: 'End of Egg-Laying',
      range: {
        min: 1799,
        max: 1900,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered larvae have reached end of egg-laying.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Strawberry Root Weevil Degree Day Model is used to predict development of life-cycle stages of the Strawberry Root Weevil (Otiorhynchus ovatus), in order to improve pest management decisions',
    'For best results, a biofix date should be set for February 15th for the Northern Hemisphere. The growing degree day model can then be used to predict adult emergence.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-strawberry-root-weevil-growing-degree-day-model/',
  citations: [
    'J. R. Umble and J. R. Fisher 2000. Temperature-Dependent Development of Otiorhynchus ovatus (Coleoptera: Curculionidae) Pupae Environmental Entomology: 29:758-765.',
  ],
}
