import { GddModelDefine } from '../gdd'

export const gddHoneylocustPlantBug: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddHoneylocustPlantBug',
  name: 'Honeylocust Plant Bug',
  fullName: 'Honeylocust Plant Bug (Diaphnocoris chlorionis) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Egg hatching has not begun.',
      range: {
        min: 0,
        max: 220,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs are overwintering.',
        },
      ],
    },
    {
      name: 'Egg Hatching',
      range: {
        min: 221,
        max: 250,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have begun to hatch.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Honeylocust Plant Bug Degree Day Model is used to predict life-stage developments of the Honeylocust Plant Bug (Diaphnocoris chlorionis), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict egg hatching which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-honeylocust-plant-bug-growing-degree-day-model/',
  citations: [
    'Herms,D.A. Using degree days and plant phenology to predict pest activity. Chapter 11. Tactics and Tools for IPM.',
  ],
}
