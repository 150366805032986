import { GddModelDefine } from '../gdd'

export const gddMeadowSpittlebug: GddModelDefine = {
  lowerThreshold: 37,
  upperThreshold: 80,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddMeadowSpittlebug',
  name: 'Meadow Spittlebug',
  fullName: 'Meadow Spittlebug (Philaenus spumarius) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 216,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not yet begun to hatch.',
        },
      ],
    },
    {
      name: '1st Nymphal Instar',
      range: {
        min: 217,
        max: 493,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have begun to hatch.',
        },
        {
          name: 'Nymphs',
          value: '1st Nymphal Instar stage',
        },
      ],
    },
    {
      name: '2nd Nymphal Instar',
      range: {
        min: 494,
        max: 678,
      },
      infos: [
        {
          name: 'Nymphs',
          value: '2nd Nymphal Instar stage',
        },
      ],
    },
    {
      name: '3rd Nymphal Instar',
      range: {
        min: 679,
        max: 860,
      },
      infos: [
        {
          name: 'Nymphs',
          value: '3rd Nymphal Instar stage',
        },
      ],
    },
    {
      name: '4th Nymphal Instar',
      range: {
        min: 861,
        max: 1063,
      },
      infos: [
        {
          name: 'Nymphs',
          value: '4th Nymphal Instar stage',
        },
      ],
    },
    {
      name: '5th Nymphal Instar',
      range: {
        min: 1064,
        max: 1389,
      },
      infos: [
        {
          name: 'Nymphs',
          value: '5th Nymphal Instar stage',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 1390,
        max: 1490,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Meadow Spittlebug Degree Day Model is used to predict development of life-cycle stages of the Meadow Spittlebug (Philaenus spumarius), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adults or eggs are first observed. The growing degree day model can then be used to predict development of different nymphal stages and emergence of next generation of adults.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-meadow-spittlebug-growing-degree-day-model/',
  citations: [
    `Zajac, M. A., F. R. Hall, and M. Curtis Wilson. 1989. Heat unit model for the development of meadow spittlebug (Homoptera: Ceropidae) on strawberry. Environ. Entomol. 18: 347-350.`,
    `Chmiel, S. M., and M. Curtis Wilson. 1979. Estimation of the lower and upper developmental threshold temperatures and duration of the nymphal stages of the meadow spittlebug, Philaenus spumarius. Environ. Entomol. 8: 682-685.`,
  ],
}
