import { GddModelDefine } from '../gdd'

export const gddNeoseiulusFallacis: GddModelDefine = {
  lowerThreshold: 54,
  upperThreshold: 90,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddNeoseiulusFallacis',
  name: 'Neoseiulus Fallacis',
  fullName: 'Neoseiulus Fallacis Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'First Generation Egg-Hatch has not yet begun',
      range: {
        min: 0,
        max: 40,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adults may have emerged.',
        },
        {
          name: 'Eggs',
          value: 'First generation egg hatch has not yet begun.',
        },
      ],
    },
    {
      name: 'First Generation Egg-Hatch has begun',
      range: {
        min: 41,
        max: 81,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'First generation egg hatch has begun.',
        },
      ],
    },
    {
      name: 'First Generation Older Nymphs',
      range: {
        min: 82,
        max: 134,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'First generation older nymphs have emerged.',
        },
      ],
    },
    {
      name: 'First Generation Adults Egg-Laying',
      range: {
        min: 135,
        max: 300,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults have emerged and begun egg-laying.',
        },
      ],
    },
    {
      name: 'Second Generation Adults Egg-Laying',
      range: {
        min: 301,
        max: 370,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults have emerged and begun egg-laying.',
        },
      ],
    },
    {
      name: 'First Generation Adults 50% Egg-Laying',
      range: {
        min: 371,
        max: 740,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults have emerged and reached 50% egg-laying.',
        },
        {
          name: 'Dispersal',
          value: 'Approximately 6 ft. dispersal range.',
        },
      ],
    },
    {
      name: '2 Full Generations Complete',
      range: {
        min: 741,
        max: 1110,
      },
      infos: [
        {
          name: 'Dispersal',
          value: 'Approximately 0.05 acre dispersal range.',
        },
      ],
    },
    {
      name: '3 Full Generations Complete',
      range: {
        min: 1111,
        max: 1480,
      },
      infos: [
        {
          name: 'Dispersal',
          value: 'Approximately 0.2 acre dispersal range.',
        },
      ],
    },
    {
      name: '4 Full Generations Complete',
      range: {
        min: 1481,
        max: 1850,
      },
      infos: [
        {
          name: 'Dispersal',
          value: 'Approximately 1.2 acre dispersal range.',
        },
      ],
    },
    {
      name: '5 Full Generations Complete',
      range: {
        min: 1851,
        max: 2220,
      },
      infos: [
        {
          name: 'Dispersal',
          value: 'Over 1.2 acre dispersal range.',
        },
      ],
    },
    {
      name: '6 Full Generations Complete',
      range: {
        min: 2221,
        max: 2590,
      },
      infos: [
        {
          name: 'Dispersal',
          value: 'Over 1.2 acre dispersal range.',
        },
      ],
    },
    {
      name: '7 Full Generations Complete',
      range: {
        min: 2591,
        max: 2960,
      },
      infos: [
        {
          name: 'Dispersal',
          value: 'Over 1.2 acre dispersal range.',
        },
      ],
    },
    {
      name: '8 Full Generations Complete',
      range: {
        min: 2961,
        max: 3000,
      },
      infos: [
        {
          name: 'Dispersal',
          value: 'Over 1.2 acre dispersal range.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Neoseiulus Fallacis Degree Day Model is used to predict development of life-cycle stages of the bio-control insect Neoseiulus Fallacis, in order to improve pest management practices.',
    'For best results, a biofix date should be set eggs are released/ observed. The growing degree day model can then be used to predict emergence of adults and dispersal of subsequent generations.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-neoseiulus-fallacis-growing-degree-day-model/',
  citations: [
    'Dover, et al. 1979. Biological Control of Panonychus ulmi (Acarina:Tetranychidae) by Amblyseius fallacis (Acarina:Phytoseiidae) on Apple: a Prey-Predator Model. Environmental Entomology, Volume 8, Issue 2, 1 April 1979, Pages 282–292,',
  ],
}
