import { GddModelDefine } from '../gdd'

export const gddOnionThrips: GddModelDefine = {
  lowerThreshold: 11.5,
  upperThreshold: 50,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddOnionThrips',
  name: 'Onion Thrips',
  fullName: 'Onion Thrips (Thrips tabaci) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 78,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not yet begun to hatch.',
        },
      ],
    },
    {
      name: 'Larvae',
      range: {
        min: 79,
        max: 178,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have emerged from eggs.',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 179,
        max: 257,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Onion Thrips Degree Day Model is used to predict development of life-cycle stages of the Onion Thrips (Thrips tabaci), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adult thrips are first observed. The growing degree day model can then be used to predict emergence of next generation of adults.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-onion-thrips-growing-degree-day-model/',
  citations: [
    `Edelson, J. V., and J. J. Magaro. 1988. Development of onion thrips, Thrips tabaci Lindeman, as a function of temperature. SW Entomol. 13: 171-176.`,
  ],
}
