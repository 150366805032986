import { GddModelDefine } from '../gdd'

export const gddTomatoFruitBorer: GddModelDefine = {
  lowerThreshold: 48,
  upperThreshold: 90,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddTomatoFruitBorer',
  name: 'Tomato Fruit Borer',
  fullName: 'Tomato Fruit Borer (Neoleucinodes elegantalis) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Emergence from Overwintering',
      range: {
        min: 0,
        max: 100,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered pupae have not begun flight.',
        },
      ],
    },
    {
      name: 'First Adults from Overwintered Pupae',
      range: {
        min: 101,
        max: 200,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae have begun flight and laying eggs.',
        },
      ],
    },
    {
      name: 'Peak Adults from Overwintered Pupae',
      range: {
        min: 201,
        max: 1139,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered pupae are in peak flight and laying eggs.',
        },
      ],
    },
    {
      name: 'First Flight - 1st Generation Adults',
      range: {
        min: 1140,
        max: 1394,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults have just begun flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'Peak Flight - 1st Generation Adults',
      range: {
        min: 1395,
        max: 2606,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults are in peak flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: 'Peak Flight - 2nd Generation',
      range: {
        min: 2607,
        max: 3819,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - 3rd Generation',
      range: {
        min: 3820,
        max: 5031,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Third generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - 4th Generation',
      range: {
        min: 5032,
        max: 6243,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Fourth generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - 5th Generation',
      range: {
        min: 6244,
        max: 7456,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Fifth generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - 6th Generation',
      range: {
        min: 7457,
        max: 8668,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Sixth generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - 7th Generation',
      range: {
        min: 8669,
        max: 9880,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Seventh generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - 8th Generation',
      range: {
        min: 9881,
        max: 10000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Eight generation adults are in peak flight.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Tomato Fruit Borer Degree Day Model is used to predict development of life-cycle stages of Tomato Fruit Borer (Neoleucinodes elegantalis), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence peak flight of the following generations.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-tomato-fruit-borer-growing-degree-day-model/',
  citations: [
    'Moraes, C.. and L.A. Foerster. 2015. Thermal Requirements, fertility, and number of generations of Neoleucinodes elegantalis (Guenee)(Lepidoptera: Crambidae) Neotrop. Entomol 44:338-344.',
  ],
}
