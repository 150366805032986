import { GddModelDefine } from '../gdd'

export const gddOakAmbrosiaBeetle: GddModelDefine = {
  lowerThreshold: 52,
  upperThreshold: 100,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddOakAmbrosiaBeetle',
  name: 'Oak Ambrosia Beetle',
  fullName: 'Oak Ambrosia Beetle (Monarthrum dentiger) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Overwintering Larvae have Pupated',
      range: {
        min: 0,
        max: 423,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Overwintered Larvae have not begun Pupation.',
        },
      ],
    },
    {
      name: 'Overwintering Larvae Pupate',
      range: {
        min: 424,
        max: 815,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Overwintered larvae have begun pupation.',
        },
      ],
    },
    {
      name: 'First Adult Emergence',
      range: {
        min: 816,
        max: 1229,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults have emerged.',
        },
      ],
    },
    {
      name: '50% Adult Emergence',
      range: {
        min: 1230,
        max: 2101,
      },
      infos: [
        {
          name: 'Adults',
          value: '50% of adults have emerged.',
        },
      ],
    },
    {
      name: 'End of Adult Emergence',
      range: {
        min: 2102,
        max: 2440,
      },
      infos: [
        {
          name: 'Adults',
          value: 'All adults have emerged.',
        },
      ],
    },
    {
      name: 'Peak Larvae Development',
      range: {
        min: 2441,
        max: 3076,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Peak larvae development in galleries.',
        },
      ],
    },
    {
      name: 'First Second Generation Adult Emergence',
      range: {
        min: 3077,
        max: 3850,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First second generation adults have emerged.',
        },
      ],
    },
    {
      name: '50% Second Generation Adult Emergence',
      range: {
        min: 3851,
        max: 4486,
      },
      infos: [
        {
          name: 'Adults',
          value: '50% of second generation adults have emerged.',
        },
      ],
    },
    {
      name: 'End of Second Generation Adult Emergence',
      range: {
        min: 4487,
        max: 4700,
      },
      infos: [
        {
          name: 'Adults',
          value: 'All second generation adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Oak Ambrosia Beetle Degree Day Model is used to predict development of the Oak Ambrosia Beetle (Monarthrum dentiger), in order to improve pest management decisions',
    'For best results, a biofix date should be set to Januar 1st for the Northern Hemisphere. The emergence of adults can then be predicted.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-oak-ambrosia-beetle-growing-degree-day-model/',
  citations: [
    'Sone, K, T. Mori, and M. Ide. 1998. Life history of the oak borer, Platypus quercivorus (Murayama). Appl. Entomol. Zool. 33:67-75.',
  ],
}
