import { GddModelDefine } from '../gdd'

export const gddMediterraneanFruitFly: GddModelDefine = {
  lowerThreshold: 9.7,
  upperThreshold: 50,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddMediterraneanFruitFly',
  name: 'Mediterranean Fruit Fly',
  fullName: 'Mediterranean Fruit Fly (Ceratitis capitata) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Eggs and Larvae',
      range: {
        min: 0,
        max: 142,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs are present and may have begun to hatch.',
        },
        {
          name: 'Larvae',
          value: 'Larvae may have emerged from eggs.',
        },
      ],
    },
    {
      name: 'Pupae',
      range: {
        min: 143,
        max: 325,
      },
      infos: [
        {
          name: 'Pupae',
          value: 'Flies are in pupae stage.',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 326,
        max: 371,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Mediterranean Fruit Fly Degree Day Model is used to predict development of life-cycle stages of the Mediterranean Fruit Fly (Ceratitis capitata), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adult flies are first observed. The growing degree day model can then be used to predict emergence of next generation of adults.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-mediterranean-fruit-fly-growing-degree-day-model/',
  citations: [
    `Tassan, R. L., K. S. Hagen, A. Cheng, T. K. Palmer, G. Feliciano and T. L. Bough. 1982. Mediterranean fruit fly life cycle estimations for the California eradication program. CEC/IOBC Symposium Athens November 1982. pp. 564-570.`,
  ],
}
