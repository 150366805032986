import { GddModelDefine } from '../gdd'

export const gddOmnivorousLeafroller: GddModelDefine = {
  lowerThreshold: 48,
  upperThreshold: 87,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddOmnivorousLeafroller',
  name: 'Omnivorous Leafroller',
  fullName: 'Omnivorous Leafroller (Platynota stultana) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Adult Flight Stage (From Overwintered Larvae)',
    range: {
      min: 0,
      max: 499,
    },
    infos: [{
      name: 'Info',
      value: `Adults are in flight and may be observed in pheromone traps. Adults have begun to lay eggs.`,
    }],
  }, {
    name: 'First Egg Hatch (First Generation)',
    range: {
      min: 500,
      max: 699,
    },
    infos: [{
      name: 'Info',
      value: `First generation eggs have begun hatching.`,
    }],
  }, {
    name: 'Larval Stage (First Generation)',
    range: {
      min: 700,
      max: 899,
    },
    infos: [{
      name: 'Info',
      value: `First generation larvae have emerged and are susceptible to insecticides.`,
    }],
  }, {
    name: 'Pupae Stage (First Generation)',
    range: {
      min: 900,
      max: 1168,
    },
    infos: [{
      name: 'Info',
      value: `First generation larvae have begun to pupate.`,
    }],
  }, {
    name: 'Adult Flight Stage (Second Generation)',
    range: {
      min: 1169,
      max: 1667,
    },
    infos: [{
      name: 'Info',
      value: `Adults are in flight and may be observed in pheromone traps. Adults have begun to lay eggs.`,
    }],
  }, {
    name: 'First Egg Hatch (Second Generation)',
    range: {
      min: 1668,
      max: 1868,
    },
    infos: [{
      name: 'Info',
      value: `Second generation eggs have begun hatching.`,
    }],
  }, {
    name: 'Larval Stage (Second Generation)',
    range: {
      min: 1869,
      max: 2068,
    },
    infos: [{
      name: 'Info',
      value: `Second generation larvae have emerged and are susceptible to insecticides.`,
    }],
  }, {
    name: 'Pupae Stage (Second Generation)',
    range: {
      min: 2069,
      max: 2337,
    },
    infos: [{
      name: 'Info',
      value: `Second generation larvae have begun to pupate.`,
    }],
  }, {
    name: 'Adult Flight Stage (Second Generation)',
    range: {
      min: 2338,
      max: 2836,
    },
    infos: [{
      name: 'Info',
      value: `Adults are in flight and may be observed in pheromone traps. Adults have begun to lay eggs.`,
    }],
  }, {
    name: 'First Egg Hatch (Third Generation)',
    range: {
      min: 2837,
      max: 3037,
    },
    infos: [{
      name: 'Info',
      value: `Third generation eggs have begun hatching.`,
    }],
  }, {
    name: 'Larval Stage (Third Generation)',
    range: {
      min: 3038,
      max: 3237,
    },
    infos: [{
      name: 'Info',
      value: `Third generation larvae have emerged and are susceptible to insecticides.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `For best results, pheromone traps should be placed early in the season.The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Kido, H., D. L. Flaherty, W. W. Barnett and H. L. Andris. 1982. Omnivorous Leafroller. IN: Grape Pest Management. U.C. Div. Agr. Sci. Publ. #4105. pp. 126-136.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-omnivorous-leafroller-growing-degree-day-model',
}
