import { GddModelDefine } from '../gdd'

export const gddLargeAspenTortrix: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddLargeAspenTortrix',
  name: 'Large Aspen Tortrix',
  fullName: 'Large Aspen Tortrix (Choristoneura conflictana) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Larvae',
      range: {
        min: 0,
        max: 600,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae may have emerged.',
        },
      ],
    },
    {
      name: 'Pupation',
      range: {
        min: 601,
        max: 700,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have begun to pupate.',
        },
      ],
    },
    {
      name: 'Adult Moths',
      range: {
        min: 701,
        max: 800,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult moths have begun to emerge.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Large Aspen Tortrix Degree Day Model is used to predict life-stage developments of the Large Aspen Tortrix (Choristoneura conflictana), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults which can be targeted by traps.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-large-aspen-tortrix-growing-degree-day-model/',
  citations: [
    'Observations made by Michigan State University Pest Management extension, (https://www.canr.msu.edu/ipm/agriculture/christmas_trees/gdd_of_landscape_insects)',
  ],
}
