import { GddModelDefine } from '../gdd'

export const gddPeachtreeBorer: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 31,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddPeachtreeBorer',
  name: 'Peachtree Borer',
  fullName: 'Peachtree Borer (Synanthedon exitiosa) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Emergence from Overwintering',
      range: {
        min: 0,
        max: 800,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults are not yet present.',
        },
      ],
    },
    {
      name: 'Start of Consistent Trap Catch',
      range: {
        min: 801,
        max: 1291,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have begun flight and egg-laying has begun.',
        },
      ],
    },
    {
      name: '50% Cumulative Trap Catch',
      range: {
        min: 1292,
        max: 1500,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are in peak flight and egg-laying has begun.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Peachtree Borer Degree Day Model is used to predict development of life-cycle stages of the Peachtree Borer (Synanthedon exitiosa), in order to improve pest management decisions',
    'For best results, a biofix date should be set for January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence and peak flight of the following generations.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-peachtree-borer-growing-degree-day-model/',
  citations: [
    `Johnson, D. T., and R. L. Mayes. 1983. Studies of larval development and adult flight of the peachtree borer, Synanthedon exitiosa (Say) in Arkansas. J. Georgia Entomol. Soc. 19: 216-223.`,
  ],
}
