import { GddModelDefine } from '../gdd'

export const gddPotatoPsyllid: GddModelDefine = {
  lowerThreshold: 40,
  upperThreshold: 86,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddPotatoPsyllid',
  name: 'Potato Psyllid',
  fullName: 'Potato Psyllid (Bactericera cockerelli) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Emergence',
      range: {
        min: 0,
        max: 162,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Overwintered adults have not yet emerged.',
        },
      ],
    },
    {
      name: 'First Overwintering Adult Egg-Laying',
      range: {
        min: 163,
        max: 324,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have begun to lay eggs.',
        },
      ],
    },
    {
      name: 'Peak Overwintering Adult Egg-Laying',
      range: {
        min: 325,
        max: 762,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak F1 Nymphs',
      range: {
        min: 763,
        max: 1044,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs are at peak activity.',
        },
      ],
    },
    {
      name: 'First F1 Adults',
      range: {
        min: 1045,
        max: 1242,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First F1 adults have emerged.',
        },
      ],
    },
    {
      name: 'Peak F1 Adults',
      range: {
        min: 1243,
        max: 1900,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak F2 Nymphs',
      range: {
        min: 1901,
        max: 2348,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs are at peak activity.',
        }, {
          name: 'Adults',
          value: 'First F2 Adults have emerged.',
        },
      ],
    },
    {
      name: 'Peak F2 Adults',
      range: {
        min: 2349,
        max: 2844,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
    {
      name: 'First F3 Adults',
      range: {
        min: 2845,
        max: 3568,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First F3 adults have emerged.',
        },
      ],
    },
    {
      name: 'Peak F3 Adults',
      range: {
        min: 3569,
        max: 4788,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak F4 Adults',
      range: {
        min: 4789,
        max: 6008,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak F5 Adults',
      range: {
        min: 6009,
        max: 7228,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
    {
      name: 'Peak F6 Adults',
      range: {
        min: 7229,
        max: 7400,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults are at peak egg-laying.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Potato Psyllid Degree Day Model is used to predict development of life-cycle stages of the Potato Psyllid (Bactericera cockerelli), in order to improve pest management decisions.',
    'For best results, a biofix date should be set at to January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of overwintering adults, egg-laying and nymph activity for the following generations through the season.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-potato-psyllid-growing-degree-day-model/',
  citations: [
    'Munyaneze 2012 Invited Review Article Zebra chip disease of potato: biology, epidemiology, and management. Am. J. Pot. Res. 89:329-350',
    'Lewis, O.M., G.J. Michels, E.A. Pierson, and K.M. Heinz. 2015. A predictive degree day model for the development of Bactericera cockerelli',
    '(Hemiptera: Triozidae) infesting Solanum tuberosum. Env. Entomol. 44:1201-1209',
  ],
}
