import { gddBlueAlfalfaAphid } from './gdd-blue-alfalfa-aphid'

export const gddBlueAlfalfaAphidAlate = gddBlueAlfalfaAphid.variety('Alate', {
  lowerThreshold: 38.2,
  upperThreshold: 80.8,
  cutoffMethod: 'horizontal',

  type: 'gddBlueAlfalfaAphidAlate',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Eggs',
    range: {
      min: 0,
      max: 277,
    },
    infos: [{
      name: 'Eggs',
      value: `Most aphids are in the egg stage.`,
    }],
  }, {
    name: 'Adults Pre Egg Laying',
    range: {
      min: 278,
      max: 329,
    },
    infos: [{
      name: 'Adults',
      value: `Most aphids are adults.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has not yet begun.`,
    }],
  }, {
    name: 'Adults Egg Laying',
    range: {
      min: 330,
      max: 606,
    },
    infos: [{
      name: 'Adults',
      value: `Most aphids are adults.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Blue Alfalfa Aphid Growing Degree Day Model is used to predict life-stage developments of the Blue Alfalfa Aphid (Acyrthosiphon kondoi) in order to improve pest management decisions.`,
  ],
  citations: [
    `Summers, C.G., R.L. Coviello, and A.P. Gutierrez. 1984. Influence of constant temperatures on the development and reproduction of Acyrthosiphon kondoi (Homoptera: Aphididae). Environ. Entomol. 13: 236-242.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-blue-alfalfa-aphid-growing-degree-day-model',
})
