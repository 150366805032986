import { GddModelDefine } from '../gdd'

export const gddFilbertworm: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 110,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddFilbertworm',
  name: 'Filbertworm',
  fullName: 'Filbertworm (Cydia latiferreana) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Emergence from Overwintering',
      range: {
        min: 0,
        max: 580,
      },
      infos: [
        {
          name: 'Adult',
          value: 'Adults from overwintered larvae have not begun flight.',
        },
      ],
    },
    {
      name: 'First Flight Adults from Overwintered Larvae',
      range: {
        min: 581,
        max: 610,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae have begun flight.',
        },
      ],
    },
    {
      name: 'Peak Flight - Adults from Overwintered Larvae',
      range: {
        min: 611,
        max: 815,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae are in peak flight but egg-laying has not begun.',
        },
      ],
    },
    {
      name: 'First Eggs Laid',
      range: {
        min: 816,
        max: 955,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults from overwintered larvae are in flight and egg-laying is in peak.',
        },
      ],
    },
    {
      name: 'First Eggs Hatch',
      range: {
        min: 956,
        max: 1022,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have begun to hatch.',
        },
      ],
    },
    {
      name: 'First Larvae found in Nuts',
      range: {
        min: 1023,
        max: 1158,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'First larvae have emerged and are found in nuts.',
        },
      ],
    },
    {
      name: 'Early Peak Moths- 1st Generation Adults',
      range: {
        min: 1159,
        max: 1188,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Average Peak Moths- 1st Generation Adults',
      range: {
        min: 1189,
        max: 1217,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Latest Peak Moths- 1st Generation Adults',
      range: {
        min: 1218,
        max: 1393,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults are in peak flight.',
        },
      ],
    },
    {
      name: 'Peak Egglaying- 1st Generation Adults',
      range: {
        min: 1393,
        max: 1533,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First generation adults are in flight and egg-laying is in peak.',
        },
      ],
    },
    {
      name: 'Peak Egg-hatch',
      range: {
        min: 1534,
        max: 1700,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Peak egg-hatch next generation has begun.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Filbertworm Degree Day Model is used to predict development of life-cycle stages of Filbertworm (Cydia latiferreana), in order to improve pest management decisions',
    'For best results, a biofix date should be set for April 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence peak flight of the following generations.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-filbertworm-growing-degree-day-model/',
  citations: [
    `AliNiazee, M. T. 1983. A Degree-Day Method for Predicting the Filbertworm Emergence. Proc. Nut Growers Soc. of Oreg. Wash and Brit. Columbia. Vol. 68:37-39.`,
  ],
}
