import { GddModelDefine } from '../gdd'

export const gddPineTubeMoth: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddPineTubeMoth',
  name: 'Pine Tube Moth',
  fullName: 'Pine Tube Moth (Argyrotaenia pinatubana) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Adults emerged',
      range: {
        min: 0,
        max: 90,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae may have emerged.',
        },
      ],
    },
    {
      name: 'Adult Moths',
      range: {
        min: 91,
        max: 250,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult moths have begun to emerge.',
        },
        {
          name: 'Eggs',
          value: 'Egg laying has begun.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Pine Tube Moth Degree Day Model is used to predict life-stage developments of the Pine Tube Moth (Argyrotaenia pinatubana), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults which can be targeted by traps.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-pine-tube-moth-growing-degree-day-model/',
  citations: [
    'Herms,D.A. Using degree days and plant phenology to predict pest activity. Chapter 11. Tactics and Tools for IPM.',
  ],
}
