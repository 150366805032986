import { GddModelDefine } from '../gdd'

export const gddSquashBug: GddModelDefine = {
  lowerThreshold: 58.03,
  upperThreshold: 91.94,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddSquashBug',
  name: 'Squash Bug',
  fullName: 'Squash Bug (Anasa tristis) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Eggs',
      range: {
        min: 0,
        max: 193,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not yet begun to hatch.',
        },
      ],
    },
    {
      name: 'Nymphs',
      range: {
        min: 194,
        max: 746,
      },
      infos: [
        {
          name: 'Nymphs',
          value: 'Nymphs have emerged from eggs.',
        },
      ],
    },
    {
      name: 'Adults',
      range: {
        min: 747,
        max: 1000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation adults have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Squash Bug Degree Day Model is used to predict development of life-cycle stages of the Squash Bug (Anasa tristis), in order to improve pest management decisions',
    'For best results, a biofix date should be set when adult bugs are first observed. The growing degree day model can then be used to predict emergence of next generation of nymphs, which can be targeted for insecticide treatment.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-squash-bug-growing-degree-day-model/',
  citations: [
    'Fargo, W. S., and E. L. Bonjour. 1988. Developmental rate of the squash bug, Anasa tristis (Heteroptera: Coreidae), at constant temperatures. Environ. Entomol. 17: 926-929.',
  ],
}
