import { GddModelDefine } from '../gdd'

export const gddPotatoLeafhopper: GddModelDefine = {
  lowerThreshold: 52.5,
  upperThreshold: 86,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddPotatoLeafhopper',
  name: 'Potato Leafhopper',
  fullName: 'Potato Leafhopper (Empoasca fabae) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Eggs',
    range: {
      min: 0,
      max: 783,
    },
    infos: [{
      name: 'Eggs',
      value: `Most leafhoppers are in the egg stage.`,
    }],
  }, {
    name: 'Adults',
    range: {
      min: 784,
      max: 1566,
    },
    infos: [{
      name: 'Adults',
      value: `Most leafhoppers are adults. Biofix should be reset for next generation`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Potato Leafhopper Growing Degree Day Model is used to predict life-stage developments of the Potato Leafhopper (Empoasca fabae) in order to improve pest management decisions.`,
  ],
  citations: [
    `Kouskolekas, C. A., and G. C. Decker. 1966. The effect of temperature on the rate of development of the potato leafhopper, Empoasca fabae (Homoptera: Cicadellidae). Ann. Ent. Soc. Amer. 59: 292-298.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-potato-leafhopper-growing-degree-day-model',
}
