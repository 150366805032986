import { GddModelDefine } from '../gdd'

export const gddJapanesePineSawyerBeetle: GddModelDefine = {
  lowerThreshold: 54,
  upperThreshold: 95,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddJapanesePineSawyerBeetle',
  name: 'Japanese Pine Sawyer Beetle',
  fullName: 'Japanese Pine Sawyer Beetle (Monochamus alternatus) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Overwintered Adult Emergence',
      range: {
        min: 0,
        max: 360,
      },
      infos:
        [
          {
            name: 'Adults',
            value: 'Overwintered Adult Beetles have not yet emerged.',
          },
        ],
    },
    {
      name: 'First Overwintered Adult Emergence',
      range: {
        min: 361,
        max: 666,
      },
      infos:
        [
          {
            name: 'Adults',
            value: '5% Overwintered Adult Beetles have emerged.',
          },
        ],
    },
    {
      name: '50% Overwintered Adult Emergence',
      range: {
        min: 667,
        max: 1037,
      },
      infos:
        [
          {
            name: 'Adults',
            value: '50% Overwintered Adult Beetles have emerged.',
          },
        ],
    },
    {
      name: '90% Overwintered Adult Emergence',
      range: {
        min: 1038,
        max: 2344,
      },
      infos:
        [
          {
            name: 'Adults',
            value: '90% Overwintered Adult Beetles have emerged.',
          },
        ],
    },
    {
      name: 'Second Generation Adult Emergence',
      range: {
        min: 2345,
        max: 2650,
      },
      infos:
        [
          {
            name: 'Adults',
            value: '5% Second Generation Adult Beetles have emerged.',
          },
        ],
    },
    {
      name: '50% Second Generation Adult Emergence',
      range: {
        min: 2651,
        max: 3020,
      },
      infos:
        [
          {
            name: 'Adults',
            value: '50% Second Generation Adult Beetles have emerged.',
          },
        ],
    },
    {
      name: '90% Second Generation Adult Emergence',
      range: {
        min: 3021,
        max: 4327,
      },
      infos:
        [
          {
            name: 'Adults',
            value: '90% Second Generation Adult Beetles have emerged.',
          },
        ],
    },
    {
      name: 'Third Generation Adult Emergence',
      range: {
        min: 4328,
        max: 4500,
      },
      infos:
        [
          {
            name: 'Adults',
            value: '5% Third Generation Adult Beetles have emerged.',
          },
        ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Japanese Pine Sawyer Beetle Degree Day Model is used to predict overwintered adult emergence, and following generations of the Japanes Pine Sawyer Beetle (Monochamus alternatus), in order to improve pest management decisions',
    'For best results, a biofix date should be set to January 1 for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of beetles from overwintering, as well as following generations.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-japanese-pine-sawyer-beetle-growing-degree-day-model/',
  citations: [
    'Park, C.G, D.S. Kim, S.M. Lee, Y.S. Moon, Y.J. Chung, and D.S. Kim. 2014. A forecasting model for the adult emergence of overwintered Monochamus alternatus. (Coleoptera: Cerambycidae) larvae based on degree-days in Korea. Appl Entomol. Zool: 49:35-42.',
    'Kobayashi F., A. Yamane, and T. Ikeda. 1984. The Japanese pine sawyerbeetle as the vector of pine wilt disease.',
  ],
}
