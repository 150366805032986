import { GddModelDefine } from '../gdd'

export const gddVariegatedCutworm: GddModelDefine = {
  lowerThreshold: 7.2,
  upperThreshold: 26.7,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddVariegatedCutworm',
  name: 'Variegated Cutworm',
  fullName: 'Variegated Cutworm (Peridroma saucia) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [{
    name: 'Adult Moth Flight and Egg Laying',
    range: {
      min: 0,
      max: 73,
    },
    infos: [{
      name: 'Adults',
      value: `Adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }, {
    name: 'First Generation Larvae',
    range: {
      min: 74,
      max: 435,
    },
    infos: [{
      name: 'Larvae',
      value: `Peak of first generation larvae.`,
    }],
  }, {
    name: 'First Generation Pupae',
    range: {
      min: 436,
      max: 675,
    },
    infos: [{
      name: 'Pupae',
      value: `Peak of first generation pupae.`,
    }],
  }, {
    name: 'First Generation Adult Moth Flight',
    range: {
      min: 676,
      max: 769,
    },
    infos: [{
      name: 'Adults',
      value: `First generation adult moths in flight.`,
    }],
  }, {
    name: 'Second Generation Egg Laying',
    range: {
      min: 770,
      max: 840,
    },
    infos: [{
      name: 'Adults',
      value: `Adult moths in flight.`,
    }, {
      name: 'Eggs',
      value: `Egg laying has begun.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Variegated Cutworm Growing Degree Day Model is used to predict life-stage developments of the Variegated Cutworm (Peridroma saucia) in order to improve pest management decisions.`,
    `For best results, pheromone traps should be placed early in the season. The biofix should be adjusted to represent the first date that adult moths have been caught consistently. The growing degree day model can then be used to predict egg hatch and emergence of larvae, which can be targeted by insecticide sprays.`,
  ],
  citations: [
    `Simonet, D. E., S. L. Clement, W. L. Rubink and R. W. Rings. 1981. Temperature requirements for development and oviposition of Peridroma saucia (Lepidoptera: Noctuidae). Can. Ent. 113: 891-897.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-variegated-cutworm-growing-degree-day-model',
}
