import { GddModelDefine } from '../gdd'

export const gddWesternBeanCutworm: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 130,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddWesternBeanCutworm',
  name: 'Western Bean Cutworm',
  fullName: 'Western Bean Cutworm (Striacosta albicosta) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'No Adult Moth Emergence',
      range: {
        min: 0,
        max: 1320,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult moths have not yet emerged.',
        },
      ],
    },
    {
      name: '25% Moth Emergence',
      range: {
        min: 1321,
        max: 1422,
      },
      infos: [
        {
          name: 'Adults',
          value: '25% of adult moths have emerged.',
        },
        {
          name: 'Eggs /Larvae',
          value: 'Scouting should begin for eggs or small larvae.',
        },
      ],
    },
    {
      name: '50% Moth Emergence',
      range: {
        min: 1423,
        max: 1536,
      },
      infos: [
        {
          name: 'Adults',
          value: '50% of adult moths have emerged.',
        },
      ],
    },
    {
      name: '75% Moth Emergence',
      range: {
        min: 1537,
        max: 1700,
      },
      infos: [
        {
          name: 'Adults',
          value: '75% of adult moths have emerged.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Western Bean Cutworm Degree Day Model is used to predict development of life-cycle stages of the Western Bean Cutworm (Striacosta albicosta), in order to improve pest management decisions',
    'For best results, a biofix date should be set to May 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of next generation of adult moths.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-western-bean-cutworm-growing-degree-day-model/',
  citations: [
    'Neb. IPM. Western Bean Cutworm. Growing Degree Day Model.',
  ],
}
