import {
  CustomOptions,
  Static,
  TEnumType,
  TSchema,
  TString,
  TUnion,
  TValue,
  Type,
} from '@sinclair/typebox'
import _ from 'lodash'
import {
  ModelGroupName,
  gddCutoffMethods,
  modelGroupDefines,
  sprayLevels,
} from '../models'
import {
  ModelType,
  autoWeatherUpdateErrors,
  dateFormats,
  iapSubscriptionProductIds,
  locationStatuses,
  measurementUnits,
  modelTypes,
  paymentPlatforms,
  stripeSubscriptionPriceIds,
  stripeSubscriptionStatuses,
  userActivityTypes,
} from './entity.utils'
import { emailReportTypes } from './general.utils'
import { stripeSubscriptionTypes } from './subscription-stripe.utils'
import { modelUnitsTypes } from './units.utils'

const typeNull = Type.Null()
type TypeNull = typeof typeNull

/* eslint-disable-next-line no-template-curly-in-string */
export const prop = '${0#}'
export const defaultError = `${prop} is invalid`

export const defaultErrorOptions: CustomOptions = {
  errorMessage: defaultError,
}

export const locationIncludeQueryOptions = [
  'models',
  'models.modelDays',
] as const

export const modelIncludeQueryOptions = [
  'modelDays',
] as const

// The types in this file are meant to be public representations of the internal models. So some
// properties may be missing that we don't want to expose in the API or the client.

const modelGroupMap = _.chain(_.keys(modelGroupDefines))
  .keyBy()
  .mapValues(() => Type.String())
  .value() as { [K in ModelGroupName]: TString }

const modelTypeMap = _.chain(modelTypes)
  .keyBy()
  .mapValues(() => Type.String())
  .value() as { [K in ModelType]: TString }

export const TypeBasic = {

  Date(options?: CustomOptions) {
    return TypeBasic.String({
      format: 'date',
      ...options,
    })
  },

  DateTime(options?: CustomOptions) {
    return TypeBasic.String({
      format: 'date-time',
      ...options,
    })
  },

  Email(options?: CustomOptions) {
    return TypeBasic.String({
      format: 'email',
      ...options,
    })
  },

  Nullable<T extends TSchema>(type: T): TUnion<[T, TypeNull]> {
    return {
      ...type,
      nullable: true,
    } as any
  },

  Integer(options?: CustomOptions) {
    return Type.Integer({
      ...defaultErrorOptions,
      ...options,
    })
  },

  Number(options?: CustomOptions) {
    return Type.Number({
      ...defaultErrorOptions,
      ...options,
    })
  },

  String(options?: CustomOptions) {
    return Type.String({
      ...defaultErrorOptions,
      ...options,
    })
  },

  Boolean(options?: CustomOptions) {
    return Type.Boolean({
      ...defaultErrorOptions,
      ...options,
    })
  },

  Literal<T extends TValue>(value: T, options?: CustomOptions) {
    return Type.Literal(value, {
      ...defaultErrorOptions,
      ...options,
    })
  },

  Enum<T extends TEnumType>(item: T, options?: CustomOptions) {
    return Type.Enum(item, {
      ...defaultErrorOptions,
      ...options,
    })
  },

  Array<T extends TSchema>(items: T, options?: CustomOptions) {
    return Type.Array(items, {
      ...defaultErrorOptions,
      ...options,
    })
  },

}

export const TypeData = {

  User() {
    return Type.Object({
      createdAt: TypeBasic.DateTime({
        description: `The date and time the user was created.`,
      }),
      updatedAt: TypeBasic.DateTime({
        description: `The date and time the user was last updated.`,
      }),
      id: TypeBasic.Integer({
        description: `The unique identifier of the user.`,
      }),
      firebaseUid: TypeBasic.String({
        description: `The unique identifier of the user in Firebase.`,
      }),
      email: TypeBasic.Email({
        description: `The email address of the user.`,
      }),
      name: TypeBasic.Nullable(TypeBasic.String({
        description: `The name of the user.`,
      })),
      company: TypeBasic.Nullable(TypeBasic.String({
        description: `The company of the user.`,
      })),
      jobTitle: TypeBasic.Nullable(TypeBasic.String({
        description: `The job title of the user.`,
      })),
      measurementUnits: TypeData.MeasurementUnits(),
      dateFormat: TypeData.DateFormat(),
      analyticsEnabled: TypeBasic.Boolean({
        description: `Whether the user has analytics enabled.`,
      }),
      emailEnabledDailyReport: TypeBasic.Boolean({
        description: `Whether the user has daily email reports enabled.`,
      }),
      emailEnabledWeeklyReport: TypeBasic.Boolean({
        description: `Whether the user has weekly email reports enabled.`,
      }),
      emailEnabledMonthlyReport: TypeBasic.Boolean({
        description: `Whether the user has monthly email reports enabled.`,
      }),
      emailEnabledNewsletter: TypeBasic.Boolean({
        description: `Whether the user has newsletter emails enabled.`,
      }),
      iapSubscription: TypeBasic.Nullable(TypeData.IapSubscription()),
      stripeSubscription: TypeBasic.Nullable(TypeData.StripeSubscription()),
      stripeCustomerId: TypeBasic.Nullable(TypeBasic.String()),
      heardAboutUsFrom: Type.Optional(TypeBasic.Nullable(TypeBasic.String())),
    })
  },

  UserSettings(options?: CustomOptions) {
    return Type.Pick(
      TypeData.User(), [
        'email',
        'name',
        'company',
        'jobTitle',
        'measurementUnits',
        'dateFormat',
        'analyticsEnabled',
        'emailEnabledDailyReport',
        'emailEnabledWeeklyReport',
        'emailEnabledMonthlyReport',
      ],
      options,
    )
  },

  Location() {
    return Type.Object({
      createdAt: TypeBasic.DateTime({
        description: `The date and time the location was created.`,
      }),
      updatedAt: TypeBasic.DateTime({
        description: `The date and time the location was last updated.`,
      }),
      id: TypeBasic.Integer({
        description: `The unique identifier of the location.`,
      }),
      name: TypeBasic.String({
        description: `The name of the location.`,
      }),
      latitude: TypeBasic.Number({
        description: `The latitude of the location.`,
      }),
      longitude: TypeBasic.Number({
        description: `The longitude of the location.`,
      }),
      status: TypeData.LocationStatus(),
      autoWeatherUpdateEnabled: TypeBasic.Boolean({
        description: `Whether the location is automatically updated with weather data.`,
      }),
      autoWeatherUpdateNextAt: TypeBasic.DateTime({
        description: `The date and time the next automatic weather update will occur.`,
      }),
      autoWeatherUpdateError: TypeBasic.Nullable(TypeData.WeatherRequestError()),
      models: Type.Optional(Type.Array(TypeData.Model())),
      timezone: TypeBasic.Nullable(TypeBasic.String({
        description: `The timezone of the location.`,
      })),
    })
  },

  LocationSettings() {
    return Type.Pick(
      TypeData.Location(), [
        'name',
        'latitude',
        'longitude',
        'autoWeatherUpdateEnabled',
      ],
    )
  },

  Model() {
    return Type.Object({
      createdAt: TypeBasic.DateTime({
        description: `The date and time the model was created.`,
      }),
      updatedAt: TypeBasic.DateTime({
        description: `The date and time the model was last updated.`,
      }),
      id: TypeBasic.Integer({
        description: `The unique identifier of the model.`,
      }),
      type: TypeData.ModelType(),
      note: Type.Optional(TypeBasic.Nullable(TypeData.ModelNote())),
      biofixDate: Type.Optional(TypeBasic.Nullable(TypeData.BiofixDate())),
      biofixStage: Type.Optional(TypeBasic.Nullable(TypeData.BiofixStage())),
      modelDays: Type.Optional(TypeBasic.Array(TypeData.ModelDay())),
    })
  },

  ModelSettings() {
    return Type.Pick(
      TypeData.Model(), [
        'note',
        'biofixDate',
        'biofixStage',
      ],
    )
  },

  ModelDay() {
    return Type.Object({
      date: TypeBasic.DateTime({
        description: `The date of the day.`,
      }),
      isForecast: TypeBasic.Boolean({
        description: `Whether the model day contains forecast data.`,
      }),
      value: TypeBasic.Number({
        description: `The model output value for the day. Unit type (index, gdd, etc) will depend on the model.`,
      }),
    })
  },

  WeatherDay() {
    return Type.Object({
      date: TypeBasic.DateTime({
        description: `The datetime of the day's first hour, in the timezone of the location.`,
      }),
      hours: TypeBasic.Array(TypeData.WeatherHour()),
      precipAmount: Type.Optional(TypeBasic.Nullable(TypeBasic.Number({
        description: `The precipitation amount for the day, in millimeters.`,
        minimum: 0,
      }))),
      snowfallAmount: Type.Optional(TypeBasic.Nullable(TypeBasic.Number({
        description: `The snowfall amount for the day, in millimeters.`,
        minimum: 0,
      }))),
      sunriseTime: Type.Optional(TypeBasic.Nullable(TypeBasic.DateTime({
        description: `The datetime of the sunrise for the day.`,
      }))),
      sunsetTime: Type.Optional(TypeBasic.Nullable(TypeBasic.DateTime({
        description: `The datetime of the sunset for the day.`,
      }))),
      temperatureMax: Type.Optional(TypeBasic.Nullable(TypeBasic.Number({
        description: `The maximum temperature for the day.`,
      }))),
      temperatureMin: Type.Optional(TypeBasic.Nullable(TypeBasic.Number({
        description: `The minimum temperature for the day.`,
      }))),
      timezone: Type.Optional(TypeBasic.String({
        description: `The timezone for the day.`,
      })),
      weatherCode: Type.Optional(TypeBasic.Number({
        description: `The WMO weather code for the hour.`,
      })),
    })
  },

  WeatherHour() {
    return Type.Object({
      cloudCover: Type.Optional(TypeBasic.Number({
        description: `The cloud cover for the hour.`,
      })),
      dewPoint: Type.Optional(TypeBasic.Number({
        description: `The dew point for the hour, in degrees Celsius.`,
      })),
      humidity: Type.Optional(TypeBasic.Number({
        description: `The relative humidity at the start of the hour.`,
      })),
      isForecast: Type.Optional(TypeBasic.Boolean({
        description: `Whether the hour is a forecast.`,
      })),
      leafWetness: Type.Optional(TypeBasic.Boolean({
        description: `Whether leaf wetness was detected at the start of the hour.`,
      })),
      precipAmount: Type.Optional(TypeBasic.Number({
        description: `The precipitation amount for the hour, in millimeters.`,
        minimum: 0,
      })),
      pressure: Type.Optional(TypeBasic.Number({
        description: `The sea-level air pressure, in millibars`,
      })),
      snowfallAmount: Type.Optional(TypeBasic.Number({
        description: `The snowfall amount over the course of the hour, in millimeters.`,
      })),
      temperature: TypeBasic.Number({
        description: `The temperature at the start of the hour, in degrees Celsius.`,
      }),
      temperatureApparent: Type.Optional(TypeBasic.Number({
        description: `The feels-like temperature when considering wind and humidity, at the start of the hour, in degrees Celsius.`,
      })),
      time: TypeBasic.String({
        description: `The datetime of the hour, in the location's timezone.`,
      }),
      weatherCode: Type.Optional(TypeBasic.Number({
        description: `The WMO weather code for the hour.`,
      })),
      windDirection: Type.Optional(TypeBasic.Number({
        description: `The direction of the wind at the start of the hour, in degrees.`,
      })),
      windGust: Type.Optional(TypeBasic.Number({
        description: `The maximum wind gust speed during the hour, in kilometers per hour.`,
        minimum: 0,
      })),
      windSpeed: Type.Optional(TypeBasic.Number({
        description: `The wind speed at the start of the hour, in kilometers per hour.`,
        minimum: 0,
      })),
    })
  },

  IapSubscription() {
    return Type.Object({
      createdAt: TypeBasic.DateTime({
        description: `The date and time the subscription was created.`,
      }),
      updatedAt: TypeBasic.DateTime({
        description: `The date and time the subscription was last updated.`,
      }),
      id: TypeBasic.String({
        description: `The unique identifier of the subscription.`,
      }),
      platform: TypeData.PaymentPlatform(),
      productId: TypeData.IapSubscriptionProductId(),
      expiresAt: TypeBasic.DateTime({
        description: `The date and time the subscription expires.`,
      }),
      autoRenewing: TypeBasic.Boolean({
        description: `Whether the subscription is automatically renewed.`,
      }),
    })
  },

  StripeSubscription() {
    return Type.Object({
      createdAt: TypeBasic.DateTime({
        description: `The date and time the subscription was created.`,
      }),
      updatedAt: TypeBasic.DateTime({
        description: `The date and time the subscription was last updated.`,
      }),
      id: TypeBasic.String({
        description: `The unique identifier of the subscription.`,
      }),
      platform: TypeData.PaymentPlatform(),
      priceId: TypeData.StripeSubscriptionPriceId(),
      quantity: TypeBasic.Integer({
        description: `The quantity of the subscription.`,
      }),
      currentPeriodEnd: TypeBasic.DateTime({
        description: `The date and time the subscription current period ends.`,
      }),
      status: TypeData.StripeSubscriptionStatus(),
      cancelAtPeriodEnd: TypeBasic.Boolean({
        description: `Whether the subscription is canceled at the end of the current period.`,
      }),
      trialEnd: TypeBasic.Nullable(TypeBasic.DateTime({
        description: `The date and time the subscription trial ends.`,
      })),
      discount: Type.Optional(TypeBasic.Nullable(TypeData.StripeDiscount())),
    })
  },

  StripeDiscount() {
    return Type.Object({
      createdAt: TypeBasic.DateTime({
        description: `The date and time the discount was created.`,
      }),
      updatedAt: TypeBasic.DateTime({
        description: `The date and time the discount was last updated.`,
      }),
      couponId: TypeBasic.String({
        description: `The unique identifier of the coupon.`,
      }),
      couponName: TypeBasic.Nullable(TypeBasic.String({
        description: `The name of the coupon.`,
      })),
      amountOff: TypeBasic.Nullable(TypeBasic.Number({
        description: `The amount off of the subscription.`,
      })),
      percentOff: TypeBasic.Nullable(TypeBasic.Number({
        description: `The percent off of the subscription.`,
      })),
      start: TypeBasic.DateTime({
        description: `The date and time the discount starts.`,
      }),
      end: TypeBasic.Nullable(TypeBasic.DateTime({
        description: `The date and time the discount ends.`,
      })),
    })
  },

  StripeSourceCardInfo() {
    return Type.Object({
      last4: TypeBasic.String({
        description: `The last four digits of the card.`,
      }),
      expMonth: TypeBasic.Integer({
        description: `The expiration month of the card.`,
      }),
      expYear: TypeBasic.Integer({
        description: `The expiration year of the card.`,
      }),
    })
  },

  StripeSubscriptionType() {
    return TypeBasic.Enum(_.keyBy(stripeSubscriptionTypes), {
      description: `The type of subscription.`,
    })
  },

  StripeSubscriptionStatus() {
    return TypeBasic.Enum(_.keyBy(stripeSubscriptionStatuses), {
      description: `The status of the subscription.`,
    })
  },

  PaymentPlatform() {
    return TypeBasic.Enum(_.keyBy(paymentPlatforms), {
      description: `The payment platform.`,
    })
  },

  MeasurementUnits() {
    return TypeBasic.Enum(_.keyBy(measurementUnits), {
      description: `The preferred measurement units of the user.`,
    })
  },

  ModelUnits() {
    return TypeBasic.Enum(_.keyBy(modelUnitsTypes), {
      description: `The model units.`,
    })
  },

  ModelUnitsName() {
    return Type.Object({
      metric: TypeBasic.String({
        description: `The model units name in metric.`,
      }),
      imperial: TypeBasic.String({
        description: `The model units name in imperial.`,
      }),
    })
  },

  DateFormat() {
    return TypeBasic.Enum(_.keyBy(dateFormats), {
      description: `The preferred date format of the user.`,
    })
  },

  EmailReportType() {
    return TypeBasic.Enum(_.keyBy(emailReportTypes))
  },

  LocationStatus() {
    return TypeBasic.Enum(_.keyBy(locationStatuses), {
      description: `The status of the location.`,
    })
  },

  WeatherRequestError() {
    return TypeBasic.Enum(_.keyBy(autoWeatherUpdateErrors), {
      description: `The error that occurred during the automatic weather update (null if no error).`,
    })
  },

  ModelGroup() {
    return Type.KeyOf(Type.Object(modelGroupMap), {
      $id: 'ModelGroup',
      description: `The model group.`,
      ...defaultErrorOptions,
    })
  },

  ModelType() {
    return Type.KeyOf(Type.Object(modelTypeMap), {
      $id: 'ModelType',
      description: `The type of model.`,
      ...defaultErrorOptions,
    })
  },

  StripeSubscriptionPriceId() {
    return TypeBasic.Enum(_.keyBy(stripeSubscriptionPriceIds), {
      description: `The price ID of the subscription.`,
    })
  },

  IapSubscriptionProductId() {
    return TypeBasic.Enum(_.keyBy(iapSubscriptionProductIds), {
      description: `The product ID of the subscription.`,
    })
  },

  SubscriptionProductId() {
    return Type.Union([
      TypeData.StripeSubscriptionPriceId(),
      TypeData.IapSubscriptionProductId(),
    ])
  },

  UserActivityType() {
    return TypeBasic.Enum(_.keyBy(userActivityTypes), {
      description: `The type of user activity.`,
    })
  },

  ModelDefine() {
    return Type.Object({
      group: TypeData.ModelGroup(),
      type: TypeData.ModelType(),
      name: TypeBasic.String({
        description: `The name of the model.`,
      }),
      fullName: TypeBasic.String({
        description: `The full name of the model.`,
      }),
      measurementUnits: Type.Optional(TypeBasic.String({
        description: `The measurement units for the model.`,
      })),
      stages: TypeBasic.Array(TypeData.ModelStage()),
      notes: TypeBasic.Array(TypeBasic.String({
        description: `The notes for the model.`,
      })),
      description: TypeBasic.Array(TypeBasic.String({
        description: `The description for the model.`,
      })),
      moreInfoUrl: TypeBasic.String({
        format: 'uri',
        description: `The URL for more information about the model.`,
      }),
      citations: TypeBasic.Array(TypeBasic.String({
        description: `The citations for the model.`,
      })),
      varietyName: Type.Optional(TypeBasic.String({
        description: `The variety name for the model.`,
      })),
    })
  },

  PowderyMildewModelDefine() {
    return Type.Intersect([
      TypeData.ModelDefine(),
      Type.Object({
        lowerTempRiskThreshold: TypeBasic.Number({
          description: `The lower temperature risk threshold.`,
        }),
        upperTempRiskThreshold: TypeBasic.Number({
          description: `The upper temperature risk threshold.`,
        }),
        excessiveTempThreshold: TypeBasic.Number({
          description: `The excessive temperature threshold.`,
        }),
        consecutiveRiskHoursRequired: TypeBasic.Number({
          description: `The number of consecutive risk hours required.`,
        }),
      }),
    ])
  },

  GddModelDefine() {
    return Type.Intersect([
      TypeData.ModelDefine(),
      Type.Object({
        lowerThreshold: Type.Optional(TypeBasic.Number({
          description: `The lower temperature threshold.`,
        })),
        upperThreshold: Type.Optional(TypeBasic.Number({
          description: `The upper temperature threshold.`,
        })),
        cutoffMethod: TypeBasic.Enum(_.keyBy(gddCutoffMethods), {
          description: `The cutoff method.`,
        }),
      }),
    ])
  },

  ModelStage() {
    return Type.Object({
      name: TypeBasic.String({
        description: `The name of the stage.`,
      }),
      sprayLevel: Type.Optional(TypeBasic.Enum(_.keyBy(sprayLevels), {
        description: `The recommended spray level.`,
      })),
      infos: Type.Array(Type.Object({
        name: TypeBasic.String(),
        value: TypeBasic.String(),
      }), {
        description: `Array of information for the stage.`,
      }),
    })
  },

  ModelDayDateRange(options?: CustomOptions) {
    return Type.Object({
      dateGte: Type.Optional(TypeBasic.DateTime({
        description: `Only include model days that are greater than or equal to this value.`,
      })),
      dateLte: Type.Optional(TypeBasic.DateTime({
        description: `Only include model days that are less than or equal to this value.`,
      })),
    }, options)
  },

  ModelNote() {
    return TypeBasic.String({
      description: `An optional note for the model.`,
    })
  },

  BiofixDate() {
    return TypeBasic.DateTime({
      description: `The biofix date (if applicable to model type).`,
    })
  },

  BiofixStage() {
    return TypeBasic.Integer({
      description: `The model stage at the biofix date (if applicable to model type).`,
    })
  },

  ModelInfo() {
    return Type.Object({
      biofixDefaultDate: Type.Optional(Type.Object({
        month: TypeBasic.Integer(),
        day: TypeBasic.Integer(),
      }, {
        description: `Default biofix date for the model.`,
      })),
      biofixDefaultStage: Type.Optional(TypeData.BiofixStage()),
      citations: TypeBasic.Array(TypeBasic.String(), {
        description: `Citations for the model.`,
      }),
      currentStageHeader: TypeBasic.String({
        description: `Header for the current stage.`,
      }),
      description: TypeBasic.Array(TypeBasic.String(), {
        description: `Descriptions for the model.`,
      }),
      fullName: TypeBasic.String({
        description: `Full name of the model.`,
      }),
      group: TypeData.ModelGroup(),
      measurementUnits: TypeData.MeasurementUnits(),
      modelUnits: TypeData.ModelUnits(),
      modelUnitsName: TypeData.ModelUnitsName(),
      moreInfoUrl: TypeBasic.String({
        format: 'uri',
        description: `URL for more information about the model.`,
      }),
      name: TypeBasic.String({
        description: `Short name of the model.`,
      }),
      notes: TypeBasic.Array(TypeBasic.String(), {
        description: `Notes for the model.`,
      }),
      requiresBiofixDate: TypeBasic.Boolean({
        description: `Whether the model requires a biofix date.`,
      }),
      requiresBiofixStage: TypeBasic.Boolean({
        description: `Whether the model requires a biofix stage.`,
      }),
      stages: TypeBasic.Array(TypeData.ModelStage()),
      stagesHeader: TypeBasic.String({
        description: `Header for the stages.`,
      }),
      type: TypeData.ModelType(),
    })
  },

  ModelInfoMinimal() {
    return Type.Pick(TypeData.ModelInfo(), [
      'fullName',
      'group',
      'name',
      'type',
    ])
  }

}

export const TypeParam = {

  LocationId() {
    return Type.Object({
      locationId: TypeBasic.Integer({
        description: `The unique identifier of the location.`,
      }),
    })
  },

  ModelId() {
    return Type.Object({
      modelId: TypeBasic.Integer({
        description: `The unique identifier of the model.`,
      }),
    })
  },

}

export const TypeQuery = {

  LocationInclude() {
    return Type.Object({
      include: Type.Optional(Type.Array(
        TypeBasic.Enum(_.keyBy(locationIncludeQueryOptions)), {
          description: `Used to include location data that isn't included by default.`,
        },
      )),
    })
  },

  ModelInclude() {
    return Type.Object({
      include: Type.Optional(Type.Array(
        TypeBasic.Enum(_.keyBy(modelIncludeQueryOptions)), {
          description: `Used to include model data that isn't included by default.`,
        },
      )),
    })
  },

}

export const TypeBody = {

  UserCreate(options?: CustomOptions) {
    return Type.Pick(
      TypeData.User(), [
        'email',
        'name',
        'company',
        'jobTitle',
        'measurementUnits',
        'dateFormat',
        'heardAboutUsFrom',
      ],
      options,
    )
  },

  UserSettings(options?: CustomOptions) {
    return Type.Partial(TypeData.UserSettings(), options)
  },

  LocationCreate(options?: CustomOptions) {
    const locationProps = TypeData.Location().properties
    return Type.Object({
      name: locationProps.name,
      latitude: locationProps.latitude,
      longitude: locationProps.longitude,
      autoWeatherUpdateEnabled: locationProps.autoWeatherUpdateEnabled,
      models: Type.Array(TypeBody.ModelCreate({ additionalProperties: false }), {
        description: `The models to add to the location.`,
      }),
    }, options)
  },

  LocationSettings(options?: CustomOptions) {
    return Type.Partial(TypeData.LocationSettings(), options)
  },

  ModelCreate(options?: CustomOptions) {
    return Type.Object({
      type: TypeData.ModelType(),
      note: Type.Optional(TypeBasic.Nullable(TypeData.ModelNote())),
      biofixDate: Type.Optional(
        Type.Union([
          TypeData.BiofixDate(),
          Type.Literal('default'),
          Type.Null(),
        ]),
      ),
      biofixStage: Type.Optional(
        Type.Union([
          TypeData.BiofixStage(),
          Type.Literal('default'),
          Type.Null(),
        ]),
      ),
    }, options)
  },

  ModelSettings(options?: CustomOptions) {
    return Type.Object({
      note: Type.Optional(TypeBasic.Nullable(TypeData.ModelNote())),
      biofixDate: Type.Optional(
        Type.Union([
          TypeData.BiofixDate(),
          Type.Literal('default'),
          Type.Null(),
        ]),
      ),
      biofixStage: Type.Optional(
        Type.Union([
          TypeData.BiofixStage(),
          Type.Literal('default'),
          Type.Null(),
        ]),
      ),
    }, options)
  },

  WeatherDayCreate(options?: CustomOptions) {
    return Type.Pick(
      TypeData.WeatherDay(), [
        'date',
        'hours',
        'precipAmount',
        'snowfallAmount',
        'sunriseTime',
        'sunsetTime',
        'temperatureMax',
        'temperatureMin',
        'timezone',
        'weatherCode',
      ],
      options,
    )
  },

  StripeSourceTokenId(options?: CustomOptions) {
    return Type.Object({
      sourceTokenId: TypeBasic.String({
        description: `The stripe source token id.`,
      }),
    }, options)
  },

  IosNotification(options?: CustomOptions) {
    return Type.Object({
      signedPayload: Type.String(),
    }, options)
  },

  AndroidNotification(options?: CustomOptions) {
    return Type.Object({
      message: Type.Object({
        data: TypeBasic.String(),
        messageId: TypeBasic.String(),
        publishTime: TypeBasic.DateTime(),
      }),
      subscription: TypeBasic.String(),
    }, options)
  },

  IapValidationIos() {
    return Type.Object({
      platform: TypeBasic.Literal('iosAppstore'),
      request: Type.Object({
        appStoreReceipt: TypeBasic.String(),
      }),
    })
  },

  IapValidationAndroid() {
    return Type.Object({
      platform: TypeBasic.Literal('androidPlaystore'),
      request: Type.Object({
        productId: TypeData.IapSubscriptionProductId(),
        purchaseToken: TypeBasic.String(),
      }),
    })
  },

  IapValidation() {
    return Type.Union([
      TypeBody.IapValidationIos(),
      TypeBody.IapValidationAndroid(),
    ])
  },
}

export const TypeResponse = {

  Ok() {
    return Type.Object({
      ok: TypeBasic.Boolean(),
    }, {
      $id: 'Response_Ok',
      description: `A response indicating success.`,
      additionalProperties: false,
    })
  },

  Message() {
    return Type.Object({
      message: TypeBasic.String(),
    }, {
      description: `A response with a message string.`,
      additionalProperties: false,
    })
  },

  Html() {
    return TypeBasic.String({
      description: `A response with HTML content.`,
      additionalProperties: false,
    })
  },

  Error() {
    return Type.Object({
      error: Type.Object({
        message: TypeBasic.String({
          description: `The error message.`,
        }),
        type: TypeBasic.String({
          description: `The error type.`,
        }),
        stack: Type.Optional(TypeBasic.String({
          description: `The error stack.`,
        })),
      }),
    }, {
      description: `A response with an error.`,
      additionalProperties: false,
    })
  },

  IapValidation() {
    return Type.Union([
      TypeResponse.Ok(),
      TypeResponse.IapValidationBadTransaction(),
      TypeResponse.IapValidationExpired(),
    ])
  },

  IapValidationBadTransaction() {
    return Type.Object({
      ok: TypeBasic.Boolean({
        description: `False if the transaction is invalid.`,
      }),
      data: Type.Object({
        code: TypeBasic.Literal(6778001, {
          description: `The error code.`,
        }),
      }, {
        description: `The data for the bad transaction.`,
        additionalProperties: false,
      }),
      error: Type.Object({
        message: TypeBasic.Literal('Bad transaction', {
          description: `The error message.`,
        }),
      }, {
        description: `The error data.`,
        additionalProperties: false,
      }),
    }, {
      description: `A response indicating a bad transaction.`,
      additionalProperties: false,
    })
  },

  IapValidationExpired() {
    return Type.Object({
      ok: TypeBasic.Boolean({
        description: `False if the transaction is expired.`,
      }),
      data: Type.Object({
        code: TypeBasic.Literal(6778003, {
          description: `The error code.`,
        }),
      }),
      error: Type.Object({
        message: TypeBasic.Literal('Expired', {
          description: `The error message.`,
        }),
      }, {
        description: `The error data.`,
        additionalProperties: false,
      }),
    }, {
      description: `A response indicating an expired transaction.`,
      additionalProperties: false,
    })
  },

  StripeSubscription() {
    return TypeBasic.Nullable(TypeData.StripeSubscription())
  },

  StripeSourceCardInfo() {
    return TypeBasic.Nullable(TypeData.StripeSourceCardInfo())
  },

  StripeWebhook() {
    return Type.Object({
      message: TypeBasic.String({
        description: `The webhook message.`,
      }),
    }, { additionalProperties: false })
  },

  Location() {
    return Type.Intersect([
      Type.Omit(TypeData.Location(), [
        'models',
      ]),
      Type.Object({
        models: Type.Optional(TypeBasic.Array(TypeResponse.Model())),
      }),
    ], { additionalProperties: false })
  },

  Model() {
    return Type.Intersect([
      TypeData.Model(),
      Type.Object({
        currentStages: TypeBasic.Array(TypeData.ModelStage()),
      }),
    ], { additionalProperties: false })
  },

}

export const TypeSchemas = _
  .chain({
    ...TypeData,
    ...TypeParam,
    ...TypeQuery,
    ...TypeBody,
    ...TypeResponse,
  })
  .map(typeCreator => Type.Strict(typeCreator()))
  .filter(type => type.$id)
  .keyBy(type => type.$id)
  .value()

export const TypeRef = _
  .chain(TypeSchemas)
  .values()
  .map(type => Type.Ref(type))
  .keyBy(type => type.$id)
  .value()

//
// Create raw types for the client to use.
//

// TypeData
const user = TypeData.User()
export type UserJson = Static<typeof user>

const userSettings = TypeData.UserSettings()
export type UserSettingsJson = Static<typeof userSettings>

const location = TypeData.Location()
export type LocationJson = Static<typeof location>
export type LocationJsonWithModels = LocationJson & { models: ModelJson[] }

const locationSettings = TypeData.LocationSettings()
export type LocationSettingsJson = Static<typeof locationSettings>

const model = TypeData.Model()
export type ModelJson = Static<typeof model>

const modelSettings = TypeData.ModelSettings()
export type ModelSettingsJson = Static<typeof modelSettings>

const modelDay = TypeData.ModelDay()
export type ModelDayJson = Static<typeof modelDay>

const modelStage = TypeData.ModelStage()
export type ModelStageJson = Static<typeof modelStage>

const weatherDay = TypeData.WeatherDay()
export type WeatherDayJson = Static<typeof weatherDay>

const weatherHour = TypeData.WeatherHour()
export type WeatherHour = Static<typeof weatherHour>
export type WeatherHourJson = WeatherHour

const iapSubscription = TypeData.IapSubscription()
export type IapSubscriptionJson = Static<typeof iapSubscription>

const stripeSubscription = TypeData.StripeSubscription()
export type StripeSubscriptionJson = Static<typeof stripeSubscription>

const stripeDiscount = TypeData.StripeDiscount()
export type StripeDiscountJson = Static<typeof stripeDiscount>

const stripeSourceCardInfo = TypeData.StripeSourceCardInfo()
export type StripeSourceCardInfoJson = Static<typeof stripeSourceCardInfo>

const modelDayDateRange = TypeData.ModelDayDateRange()
export type ModelDayDateRangeJson = Static<typeof modelDayDateRange>

const modelInfo = TypeData.ModelInfo()
export type ModelInfoJson = Static<typeof modelInfo>

const modelInfoMinimal = TypeData.ModelInfoMinimal()
export type ModelInfoMinimalJson = Static<typeof modelInfoMinimal>

// TypeQuery
const locationIncludeQuery = TypeQuery.LocationInclude()
export type LocationIncludeQueryOptions = Required<Static<typeof locationIncludeQuery>>['include'][number]

const modelIncludeQuery = TypeQuery.ModelInclude()
export type ModelIncludeQueryOptions = Required<Static<typeof modelIncludeQuery>>['include'][number]

// TypeBody
const userCreateBody = TypeBody.UserCreate()
export type UserCreateBody = Static<typeof userCreateBody>

const userSettingsBody = TypeBody.UserSettings()
export type UserSettingsBody = Static<typeof userSettingsBody>

const locationCreateBody = TypeBody.LocationCreate()
export type LocationCreateBody = Static<typeof locationCreateBody>

const locationSettingsBody = TypeBody.LocationSettings()
export type LocationSettingsBody = Static<typeof locationSettingsBody>

const modelCreateBody = TypeBody.ModelCreate()
export type ModelCreateBody = Static<typeof modelCreateBody>

const modelSettingsBody = TypeBody.ModelSettings()
export type ModelSettingsBody = Static<typeof modelSettingsBody>

const weatherDayCreate = TypeBody.WeatherDayCreate()
export type WeatherDayCreate = Static<typeof weatherDayCreate>
export type WeatherDayCreateBody = WeatherDayCreate

const stripeSourceTokenIdCreateBody = TypeBody.StripeSourceTokenId()
export type StripeSourceTokenIdCreateBody = Static<typeof stripeSourceTokenIdCreateBody>

const iapValidationIosBody = TypeBody.IapValidationIos()
export type IapValidationIosBody = Static<typeof iapValidationIosBody>

const iapValidationAndroidBody = TypeBody.IapValidationAndroid()
export type IapValidationAndroidBody = Static<typeof iapValidationAndroidBody>

const iapValidationBody = TypeBody.IapValidation()
export type IapValidationBody = Static<typeof iapValidationBody>

// TypeResponse
const locationResponse = TypeResponse.Location()
export type LocationResponse = Static<typeof locationResponse>

const modelResponse = TypeResponse.Model()
export type ModelResponse = Static<typeof modelResponse>

const iapValidationBadTransactionResponse = TypeResponse.IapValidationBadTransaction()
export const iapValidationBadTransaction: Static<typeof iapValidationBadTransactionResponse> = {
  ok: false,
  data: {
    code: 6778001,
  },
  error: {
    message: 'Bad transaction',
  },
}

const iapValidationExpiredResponse = TypeResponse.IapValidationExpired()
export const iapValidationExpired: Static<typeof iapValidationExpiredResponse> = {
  ok: false,
  data: {
    code: 6778003,
  },
  error: {
    message: 'Expired',
  },
}

const stripeWebhookResponse = TypeResponse.StripeWebhook()
export type StripeWebhookResponse = Static<typeof stripeWebhookResponse>
