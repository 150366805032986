import { GddModelDefine } from '../gdd'

export const gddTomatoLeafminer: GddModelDefine = {
  lowerThreshold: 46,
  upperThreshold: 95,
  cutoffMethod: 'vertical',
  group: 'gdd',
  type: 'gddTomatoLeafminer',
  name: 'Tomato Leafminer',
  fullName: 'Tomato Leafminer (Tuta absoluta) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [
    {
      name: 'Adults Have Not Yet Emerged',
      range: {
        min: 0,
        max: 45,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have not yet emerged from overwintered pupae or begun egg-laying.',
        },
      ],
    },
    {
      name: 'First Spring Egg-laying',
      range: {
        min: 46,
        max: 105,
      },
      infos: [
        {
          name: 'Adults',
          value: 'First adults have emerged and have begun egg-laying.',
        },
      ],
    },
    {
      name: 'Peak Spring Egg-laying',
      range: {
        min: 106,
        max: 900,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adults have emerged and have begun peak egg-laying.',
        },
      ],
    },
    {
      name: 'First Generation Adult Emergence',
      range: {
        min: 901,
        max: 964,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged and have begun egg-laying.',
        },
      ],
    },
    {
      name: 'First Generation Peak Egg-laying',
      range: {
        min: 965,
        max: 1824,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged and have begun peak egg-laying.',
        },
      ],
    },
    {
      name: 'Second Generation Peak Egg-laying',
      range: {
        min: 1825,
        max: 2683,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged and have begun peak egg-laying.',
        },
      ],
    },
    {
      name: 'Third Generation Peak Egg-laying',
      range: {
        min: 2684,
        max: 3543,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged and have begun peak egg-laying.',
        },
      ],
    },
    {
      name: 'Fourth Generation Peak Egg-laying',
      range: {
        min: 3544,
        max: 4402,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged and have begun peak egg-laying.',
        },
      ],
    },
    {
      name: 'Fifth Generation Peak Egg-laying',
      range: {
        min: 4403,
        max: 5262,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged and have begun peak egg-laying.',
        },
      ],
    },
    {
      name: 'Sixth Generation Peak Egg-laying',
      range: {
        min: 5263,
        max: 6121,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged and have begun peak egg-laying.',
        },
      ],
    },
    {
      name: 'Seventh Generation Peak Egg-laying',
      range: {
        min: 6122,
        max: 6981,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged and have begun peak egg-laying.',
        },
      ],
    },
    {
      name: 'Eighth Generation Peak Egg-laying',
      range: {
        min: 6982,
        max: 7200,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Next generation of adults have emerged and have begun peak egg-laying.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Tomato Leafminer Degree Day Model is used to predict development of life-cycle stages of Tomato Leafminer (Tuta absoluta), in order to improve pest management decisions',
    'For best results, a biofix date should be set to January 1st for the Northern Hemisphere. The growing degree day model can then be used to predict emergence of adults from overwintered pupae as well as following generations.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-tomato-leafminer-growing-degree-day-model/',
  citations: [
    'Barker, B. Coop, L. 2020. Tuta absoluta (Lepidoptera: Gelechiidae) Phenology/Degree-Day and Climate Suitability Model Analysis for USPEST.ORG. Oregon State University.',
  ],
}
