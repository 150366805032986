import { GddModelDefine } from '../gdd'

export const gddEuropeanRedMite: GddModelDefine = {
  lowerThreshold: 51,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddEuropeanRedMite',
  name: 'European Red Mite',
  fullName: 'European Red Mite (Panonychus ulmi) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'First Generation Eggs',
    range: {
      min: 0,
      max: 124,
    },
    infos: [{
      name: 'Adults',
      value: `Adults have begun to lay eggs.`,
    }],
  }, {
    name: 'First Generation Larvae',
    range: {
      min: 125,
      max: 170,
    },
    infos: [{
      name: 'Larvae',
      value: `Eggs have begun to hatch and larvae are emerging.`,
    }],
  }, {
    name: 'First Generation Protonymphs',
    range: {
      min: 171,
      max: 211,
    },
    infos: [{
      name: 'Protonymphs',
      value: `Larvae have developed into protonymphs.`,
    }],
  }, {
    name: 'First Generation Deutonymphs',
    range: {
      min: 212,
      max: 261,
    },
    infos: [{
      name: 'Deutonymphs',
      value: `Protonymphs have developed into deutonymphs.`,
    }],
  }, {
    name: 'Second Generation Adults',
    range: {
      min: 262,
      max: 290,
    },
    infos: [{
      name: 'Adults',
      value: `Adults active, but have not yet begun to lay eggs.`,
    }],
  }, {
    name: 'Second Generation Eggs',
    range: {
      min: 291,
      max: 414,
    },
    infos: [{
      name: 'Adults',
      value: `Adults have begun to lay eggs.`,
    }],
  }, {
    name: 'Second Generation Larvae',
    range: {
      min: 415,
      max: 462,
    },
    infos: [{
      name: 'Larvae',
      value: `Eggs have begun to hatch and larvae are emerging.`,
    }],
  }, {
    name: 'Second Generation Protonymphs',
    range: {
      min: 463,
      max: 502,
    },
    infos: [{
      name: 'Protonymphs',
      value: `Larvae have developed into protonymphs.`,
    }],
  }, {
    name: 'Second Generation Deutonymphs',
    range: {
      min: 503,
      max: 552,
    },
    infos: [{
      name: 'Deutonymphs',
      value: `Protonymphs have developed into deutonymphs.`,
    }],
  }, {
    name: 'Third Generation Deutonymphs',
    range: {
      min: 553,
      max: 583,
    },
    infos: [{
      name: 'Adults',
      value: `Adults active, but have not yet begun to lay eggs.`,
    }],
  }, {
    name: 'Third Generation Eggs',
    range: {
      min: 584,
      max: 676,
    },
    infos: [{
      name: 'Adults',
      value: `Adults have begun to lay eggs.`,
    }],
  }, {
    name: 'Third Generation Larvae',
    range: {
      min: 677,
      max: 722,
    },
    infos: [{
      name: 'Larvae',
      value: `Eggs have begun to hatch and larvae are emerging.`,
    }],
  }, {
    name: 'Third Generation Protonymphs',
    range: {
      min: 723,
      max: 763,
    },
    infos: [{
      name: 'Protonymphs',
      value: `Larvae have developed into protonymphs.`,
    }],
  }, {
    name: 'Third Generation Deutonymphs',
    range: {
      min: 764,
      max: 813,
    },
    infos: [{
      name: 'Deutonymphs',
      value: `Protonymphs have developed into deutonymphs.`,
    }],
  }, {
    name: 'Multiple Generations Overlapping',
    range: {
      min: 814,
      max: 1000,
    },
    infos: [{
      name: 'Multiple Generations',
      value: `Multiple generations have likely begun to overlap, making phenological stage estimates inaccurate.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The European Red Mite Growing Degree Day model can be used to predict phenological stages of European Red Mites (Panonychus ulmi). Different types of miticides will affect mite life stages differently.`,
  ],
  citations: [
    `Herbert, H. J. 1981. Biology, life tables and intrinsic rate of increase of the European red mite, Panonychus ulmi (Acarina: Tetranychidae). Can. Ent. 113: 65-71.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-european-red-mite-growing-degree-day-model',
}
