import { gddGrape } from './gdd-grape'

export const gddGrapeThompsonSeedless = gddGrape.variety('Thompson Seedless', {
  lowerThreshold: 50,
  upperThreshold: 120,
  cutoffMethod: 'horizontal',

  type: 'gddGrapeThompsonSeedless',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Dormancy to 50% Budbreak',
    range: {
      min: 0,
      max: 155,
    },
    infos: [{
      name: 'Biofix',
      value: `Recommended to set biofix to Feb. 20 for Northern Hemisphere.`,
    }, {
      name: 'Budbreak',
      value: `Vines are starting to become active leading to peak budbreak.`,
    }],
  }, {
    name: '50% Budbreak to 50% Bloom',
    range: {
      min: 156,
      max: 741,
    },
    infos: [{
      name: 'Bloom',
      value: `Budbreak to peak bloom.`,
    }],
  }, {
    name: '50% Bloom to Fruit Maturity',
    range: {
      min: 742,
      max: 2879,
    },
    infos: [{
      name: 'Bloom',
      value: `Peak bloom to fruit maturation.`,
    }],
  }, {
    name: 'Fruit Maturity',
    range: {
      min: 2880,
      max: 3240,
    },
    infos: [{
      name: 'Fruit Maturation',
      value: `Fruit maturation phase.`,
    }],
  }],
  notes: [
    `Growing Degree Day phenology models represent an estimate for an average crop. Predicted dates for budbreak, bloom, and veraison should be interpreted as the mean of a range of possible dates.`,
  ],
  description: [
    `The Grape Growing Degree Day Crop Model is used to predict phenological stages of the grape crop as it develops through the season. For the best results, the biofix should be adjusted at observed bud break and bloom time to better predict later crop stages.`,
  ],
  citations: [
    `Zapata et al. "Predicting Key Phenological Stages for 17 Grapevine Cultivars (Vitis vinifera L.)". American Journal of Enology and Viticulture. Vol. 68: 60-72. January 2017`,
    `Williams, D.W., H.L. Andris, R.H. Beede, D.A. Luvisi, M.V.K. Norton, and L.E. Williams. 1985. Validation of a model for the growth and development of the Thompson Seedless grapevine. II. Phenology. Am. J. Enol. Vitic. 36:283-289.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-grape-growing-degree-day-crop-model',
})
