import { GddModelDefine } from '../gdd'

export const gddSmoothCrabgrass: GddModelDefine = {
  lowerThreshold: 12,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',
  group: 'gdd',
  type: 'gddSmoothCrabgrass',
  name: 'Smooth Crabgrass',
  fullName: 'Smooth Crabgrass (Digitaria ischaemum) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'No Emergence',
      range: {
        min: 0,
        max: 42,
      },
      infos: [
        {
          name: 'Rhizome',
          value: 'Rhizome sprout has not yet emerged from soil.',
        },
      ],
    },
    {
      name: 'First Emergence',
      range: {
        min: 43,
        max: 140,
      },
      infos: [
        {
          name: 'Rhizome',
          value: 'Rhizome sprout has just started to emerge from soil.',
        },
      ],
    },
    {
      name: 'Initial Major Emergence',
      range: {
        min: 141,
        max: 310,
      },
      infos: [
        {
          name: 'Rhizome',
          value: 'Rhizome sprout has first major emergence from soil.',
        },
      ],
    },
    {
      name: '25% Emergence',
      range: {
        min: 311,
        max: 445,
      },
      infos: [
        {
          name: 'Rhizome',
          value: '25% Rhizome sprout has emergence from soil.',
        },
      ],
    },
    {
      name: '50% Emergence',
      range: {
        min: 446,
        max: 615,
      },
      infos: [
        {
          name: 'Rhizome',
          value: '50% Rhizome sprout has emergence from soil.',
        },
      ],
    },
    {
      name: '75% Emergence',
      range: {
        min: 616,
        max: 945,
      },
      infos: [
        {
          name: 'Rhizome',
          value: '75% Rhizome sprout has emergence from soil.',
        },
      ],
    },
    {
      name: '95% Emergence',
      range: {
        min: 946,
        max: 1100,
      },
      infos: [
        {
          name: 'Rhizome',
          value: '95% Rhizome sprout has emergence from soil.',
        },
      ],
    },
  ],
  notes: [
    'Crop development stages based Growing Degree Day models are estimations and are intended to be used with scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Smooth Crabgrass Degree Day Model is used to predict rhizome sprout emergence of Smooth Crabgrass (Digitaria ischaemum) based on the weather in a given season.',
    'For best results, a biofix date should be set to April 1st for the Northern Hemisphere. The growing degree day model can then be used to predict rhizome sprout emergence.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-smooth-crabgrass-growing-degree-day-model/',
  citations: [
    `Fidanza, M.A., P.H. Dernoeden, and M. Zhang. 1996. Degree-days for predicting smooth crabgrass emergence in cool-season turfgrass. Crop Science 36: 990-996.`,
  ],
}
