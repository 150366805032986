import { GddModelDefine } from '../gdd'

export const gddCranberryFruitworm: GddModelDefine = {
  lowerThreshold: 50,
  upperThreshold: 86,
  cutoffMethod: 'intermediate',

  group: 'gdd',
  type: 'gddCranberryFruitworm',
  name: 'Cranberry Fruitworm',
  fullName: 'Cranberry Fruitworm (Acrobasis vaccinii) Growing Degree Day Model',
  measurementUnits: 'imperial',
  stages: [{
    name: 'Overwintered Larvae Emerging',
    range: {
      min: 0,
      max: 259,
    },
    infos: [{
      name: 'Info',
      value: `Larvae that have overwintered begin to emerge and feed.`,
    }],
  }, {
    name: 'Adult Moth Flight',
    range: {
      min: 260,
      max: 434,
    },
    infos: [{
      name: 'Info',
      value: `Larvae have developed into adults that have begun to fly and mate.`,
    }],
  }, {
    name: 'Egg Laying & Larvae Emergence',
    range: {
      min: 435,
      max: 835,
    },
    infos: [{
      name: 'Info',
      value: `Adults have begun to lay eggs.`,
    }],
  }],
  notes: [
    `Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.`,
  ],
  description: [
    `The Cranberry Fruitworm Growing Degree Day model can be used to predict egg laying and larvae emergence. Using this model in conjunction with regular scouting and pheromone traps will increase its accuracy.`,
    `After adult moths have been successfully trapped for two consecutive weeks, the biofix should then be adjusted, using the "Adult Moth Flight" stage, to first date of the sustained catch.`,
  ],
  citations: [
    `This model was developed and tested by Michigan State University Extension.`,
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-cranberry-fruitworm-growing-degree-day-model',
}
