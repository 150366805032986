import { GddModelDefine } from '../gdd'

export const gddArborvitaeLeafminer: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddArborvitaeLeafminer',
  name: 'Arborvitae Leafminer',
  fullName: 'Arborvitae Leafminer (Argyresthia thuiella) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Overwintered Adults Emerge',
      range: {
        min: 0,
        max: 245,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Overwintered adult moths may be present.',
        },
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
        {
          name: 'Larvae',
          value: 'Leaf mining larvae are not yet present.',
        },
      ],
    },
    {
      name: 'First Generation Larvae',
      range: {
        min: 246,
        max: 360,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and feeding in leaf mines.',
        },
      ],
    },
    {
      name: 'First Generation Adults',
      range: {
        min: 361,
        max: 533,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Adult moths may be present.',
        },
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
        {
          name: 'Larvae',
          value: 'Second generation Leaf mining larvae are not yet present.',
        },
      ],
    },
    {
      name: 'Second Generation Larvae',
      range: {
        min: 534,
        max: 700,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and feeding in leaf mines.',
        },
      ],
    },
    {
      name: 'Second Generation Adults',
      range: {
        min: 701,
        max: 1700,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Second generation adult moths may be present.',
        },
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
        {
          name: 'Larvae',
          value: 'Third generatin leaf mining larvae are not yet present.',
        },
      ],
    },
    {
      name: 'Third Generation Larvae',
      range: {
        min: 1701,
        max: 2100,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and feeding in leaf mines.',
        },
      ],
    },
    {
      name: 'Third Generation Adults',
      range: {
        min: 2100,
        max: 3000,
      },
      infos: [
        {
          name: 'Adults',
          value: 'Third generation adult moths may be present.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Arborvitae Leafminer Degree Day Model is used to predict life-stage developments of the Arborvitae Leafminer (Argyresthia thuiella), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict egg hatch and emergence of larvae which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-arborvitae-leafminer-growing-degree-day-model/',
  citations: [
    'Herms,D.A. Using degree days and plant phenology to predict pest activity. Chapter 11. Tactics and Tools for IPM.',
  ],
}
