import { GddModelDefine } from '../gdd'

export const gddForestTentCaterpillar: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddForestTentCaterpillar',
  name: 'Forest Tent Caterpillar',
  fullName: 'Forest Tent Caterpillar (Malacosoma disstria) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Eggs Overwintering',
      range: {
        min: 0,
        max: 125,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Eggs have not begun to hatch.',
        },
      ],
    },
    {
      name: 'Egg Hatch',
      range: {
        min: 126,
        max: 250,
      },
      infos: [
        {
          name: 'Eggs',
          value: 'Egg-laying has begun.',
        },
        {
          name: 'larvae',
          value: 'Caterpillars are beginning to emerge.',
        },
      ],
    },
    {
      name: 'Larvae Present',
      range: {
        min: 251,
        max: 450,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae are present and feeding.',
        },
      ],
    },
    {
      name: 'Pupation',
      range: {
        min: 451,
        max: 550,
      },
      infos: [
        {
          name: 'Larvae',
          value: 'Larvae have begun pupation',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Forest Tent Caterpillar Degree Day Model is used to predict life-stage developments of the Forest Tent Caterpillar (Malacosoma disstria), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of larvae which can be targeted by traps.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-forest-tent-caterpillar-borer-growing-degree-day-model/',
  citations: [
    'Observations made by Michigan State University Pest Management extension, (https://www.canr.msu.edu/ipm/agriculture/christmas_trees/gdd_of_landscape_insects)',
  ],
}
