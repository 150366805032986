import { GddModelDefine } from '../gdd'

export const gddCottonyMapleScale: GddModelDefine = {
  lowerThreshold: 10,
  upperThreshold: 40,
  cutoffMethod: 'horizontal',

  group: 'gdd',
  type: 'gddCottonyMapleScale',
  name: 'Cottony Maple Scale',
  fullName: 'Cottony Maple Scale (Pulvinaria innumerabilis) Growing Degree Day Model',
  measurementUnits: 'metric',
  stages: [
    {
      name: 'Crawlers have not emerged',
      range: {
        min: 0,
        max: 801,
      },
      infos: [
        {
          name: 'Crawlers',
          value: 'Crawlers have not yet emerged.',
        },
      ],
    },
    {
      name: 'Adults and Crawlers Present',
      range: {
        min: 802,
        max: 1200,
      },
      infos: [
        {
          name: 'Crawlers',
          value: 'Yellow crawlers are present on leaves',
        },
        {
          name: 'Adults',
          value: 'Adults may also be present.',
        },
      ],
    },
  ],
  notes: [
    'Insect life stages based Growing Degree Day models are estimations and are intended to be used with insect scouting. Accuracy of models may vary for different locations and crops, and should always be tested and verified.',
  ],
  description: [
    'The Cottony Maple Scale Degree Day Model is used to predict life-stage developments of the Cottony Maple Scale (Pulvinaria innumerabilis), in order to improve pest management decisions',
    'For best results, a biofix date of January 1 should be used in the Northern Hemisphere. The growing degree day model can then be used to predict emergence of crawlers which can be targeted by insecticide sprays.',
  ],
  moreInfoUrl: 'http://blog.pestprophet.com/how-to-use-the-cottony-maple-scale-growing-degree-day-model/',
  citations: [
    'Herms,D.A. Using degree days and plant phenology to predict pest activity. Chapter 11. Tactics and Tools for IPM.',
  ],
}
